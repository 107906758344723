import { useTranslation } from 'react-i18next';

export const useInvestmentFrequencyLabels = () => {
  const { t } = useTranslation();

  return {
    once: t('benefits.once'),
    monthly: t('common.monthly'),
  };
};
