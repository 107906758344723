import { useTranslation } from 'react-i18next';
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from '../../../../../components/dialogs/ConfirmationDialog/ConfirmationDialog';

interface Props extends Omit<ConfirmationDialogProps, 'content' | 'title'> {}

const UnsavedChangesDialog = (props: Props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      {...props}
      title={t('common.unsavedChangesWarningTitle')}
      content={t('common.unsavedChangesWarningText')}
    />
  );
};

export default UnsavedChangesDialog;
