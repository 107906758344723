import { Colors } from 'melp-design/style';
import { StyleSheet, Font } from '@react-pdf/renderer';
import fontRegular from './fonts/inter-v11-latin-ext_latin_cyrillic-regular.ttf';
import fontMedium from './fonts/inter-v11-latin-ext_latin_cyrillic-500.ttf';
import fontBold from './fonts/inter-v11-latin-ext_latin_cyrillic-700.ttf';

Font.register({
  family: 'Inter',
  fonts: [
    {
      fontWeight: 400,
      src: fontRegular,
    },
    {
      fontWeight: 500,
      src: fontMedium,
    },
    {
      fontWeight: 700,
      src: fontBold,
    },
  ],
});

// To prevent hyphenation
Font.registerHyphenationCallback((word) => [word]);

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@latest/assets/72x72/',
});

const sidesMargin = '1.8cm';
const sectionsMargin = '0.4cm';

export const styles = StyleSheet.create({
  page: {
    padding: `2.1cm ${sidesMargin} 3cm`,
    // default font setup
    fontFamily: 'Inter',
    fontSize: 8,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '140%',
    color: Colors.black,
  },
  smallText: {
    fontSize: 6,
    fontWeight: 'normal',
  },
  regularText: {
    fontSize: 8,
    fontWeight: 'normal',
  },
  mediumText: {
    fontSize: 10,
    fontWeight: 500,
  },
  primaryText: {
    color: Colors.primary,
  },
  secondaryText: {
    color: Colors.grey,
  },
  topSection: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.1,
    marginBottom: '0.5cm',
  },
  imagePanel: {
    border: `1pt solid ${Colors.elementsGrey}`,
    borderRadius: 10,
    width: '40%',
    alignItems: 'flex-start',
    marginLeft: sectionsMargin,
    marginBottom: sectionsMargin,
  },
  image: {
    height: 123,
    objectFit: 'cover',
    width: '100%',
    borderRadius: 9,
  },
  panel: {
    border: `1pt solid ${Colors.elementsGrey}`,
    borderRadius: 10,
    marginBottom: sectionsMargin,
    padding: '0.5cm',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: `1cm ${sidesMargin} 0`,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: `0 ${sidesMargin} 1cm`,
  },
  termDetails: {
    flexDirection: 'row',
  },
  timeline: {
    alignItems: 'center',
    width: 16,
    marginRight: 7,
  },
  timelinePoint: {
    height: 16,
    width: 16,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryLight,
    color: Colors.primary,
  },
  timelineLine: {
    height: 5,
    width: 1,
    backgroundColor: Colors.primaryLight,
  },
  termRange: {
    justifyContent: 'center',
    flexGrow: 1,
  },
  termRangeLimit: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  termRangeLimitLabel: {
    paddingLeft: 1,
    marginRight: 5,
  },
  termRangeLimitValue: {
    alignItems: 'flex-start',
  },
  divider: {
    width: '100%',
    height: 0.5,
    backgroundColor: Colors.elementsGrey,
    marginTop: 5,
    marginBottom: 5,
  },
  tag: {
    padding: '1.5pt 4pt',
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.primaryLight,
    color: Colors.primary,
    marginLeft: 3,
  },
  panelHeader: {
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelColumn: {
    width: '80%',
  },
  valueColumn: {
    width: '20%',
  },
  valueAndInvestmentDivider: {
    marginTop: 10,
    marginBottom: 10,
  },
  splitInvestment: {
    marginTop: 10,
  },
  employerInvestment: {
    marginBottom: 5,
  },
  linkName: {
    marginBottom: 2,
  },
  linkUrl: {
    marginLeft: 2,
    textDecoration: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tncLabel: {
    marginBottom: 2,
  },
  otherAttachmentsLabel: {
    marginTop: 10,
    marginBottom: 2,
  },
  attachmentsHint: {
    marginTop: 10,
  },
  faqQuestion: {
    fontWeight: 700,
    marginBottom: 2,
  },
  optionsHeaderRow: {
    marginBottom: 10,
  },
  optionsRow: {
    paddingLeft: 1,
    paddingRight: 1,
  },
  optionsLabelCol: {
    width: '78%',
  },
  optionsAmountCol: {
    width: '15%',
  },
  optionsPercentCol: {
    width: '7%',
  },
  marketplaceSection: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  itemsInfo: {
    width: 110,
  },
  qrInfo: {
    alignContent: 'center',
  },
  appQRCode: {
    width: 73,
    height: 73,
    marginTop: -5,
    marginBottom: 5,
  },
  qrHint: {
    width: 73,
    textAlign: 'center',
  },
  step: {
    alignItems: 'center',
    width: 50,
    textAlign: 'center',
  },
  stepIconWrapper: {
    padding: 7,
    borderRadius: '50%',
    backgroundColor: Colors.primaryLight,
    marginBottom: 5,
  },
  melpFooterAppInfo: {
    width: 109,
    alignSelf: 'flex-end',
  },
  moreInfo: {
    flexWrap: 'wrap',
  },
  melpLogo: {
    marginTop: 3,
    marginBottom: 2,
    marginRight: 2,
  },
  googlePlayLogo: {
    width: 'auto',
    height: 15,
  },
});
