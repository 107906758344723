import { Button, DataLossPrompt, Footer } from 'melp-design/components';
import { Stack } from '@mui/material';
import { MouseEvent, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useBenefit } from '../../../../state/Benefits';
import { Benefit, BenefitResponse } from '../../../../types/Benefits';
import { useDialog } from '../../../../utils/Dialog';
import { useIdParam } from '../../../../utils/useIdParam';
import { useTabsContext } from '../BenefitDetailsPage';
import BenefitWizardCompletionSuccessDialog from './BenefitWizardCompletionSuccessDialog';
import UnsavedChangesTracker from './unsaved-changes/UnsavedChangesTracker';

type BenefitData = Benefit | BenefitResponse;

interface Props {
  hasChanges?: boolean;
  onBack?: (
    e: MouseEvent<HTMLButtonElement>,
    previousStep: (latestBenefitData: BenefitData) => void,
  ) => void;
  onSave?: MouseEventHandler<HTMLButtonElement>;
  onSaveAndNext?: (
    e: MouseEvent<HTMLButtonElement>,
    nextStep: (latestBenefitData: BenefitData) => void,
  ) => void;
  saving?: boolean;
}

const BenefitDetailsFooter = ({ hasChanges = false, ...props }: Props) => {
  const { t } = useTranslation();
  const sideTabs = useTabsContext();

  const id = useIdParam();
  const benefit = useBenefit(id);
  const guided = benefit.data?.isDraft ?? false;
  const completionSuccessDialog = useDialog();

  const getNewSteps = (latestBenefitData: BenefitData) => {
    return sideTabs.tabs
      .map((tab) => tab.key)
      .filter((tabKey) => {
        if (tabKey === 'marketplace') {
          return latestBenefitData.isBalanceTrackingEnabled;
        }
        if (
          tabKey === 'employeesAssignment' &&
          'groupCount' in latestBenefitData &&
          latestBenefitData.groupCount > 0
        ) {
          return false;
        }
        return true;
      });
  };

  const goToNextStep = (latestBenefitData: BenefitData) => {
    const steps = getNewSteps(latestBenefitData);
    const currentStepIndex = steps.findIndex(
      (step) => step === sideTabs.activeTab,
    );

    const isLastStep = currentStepIndex === steps.length - 1;

    if (isLastStep || sideTabs.isLast) {
      completionSuccessDialog.openDialog();
      return;
    }

    // A user selectively navigated to a tab that is not part of the wizard steps
    if (currentStepIndex === -1) {
      sideTabs.next();
      return;
    }

    sideTabs.goTo(steps[currentStepIndex + 1]);
  };

  const handleSaveAndNext: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!benefit.data) {
      throw new Error('Benefit data is missing');
    }
    if (props.onSaveAndNext) {
      props.onSaveAndNext(e, goToNextStep);
    } else {
      goToNextStep(benefit.data);
    }
  };

  const goToPreviousStep = (latestBenefitData: BenefitData) => {
    const steps = getNewSteps(latestBenefitData);
    const currentStepIndex = steps.findIndex(
      (step) => step === sideTabs.activeTab,
    );

    // A user selectively navigated to a tab that is not part of the wizard steps
    if (currentStepIndex === -1) {
      sideTabs.back();
      return;
    }

    sideTabs.goTo(steps[currentStepIndex - 1]);
  };

  const handleBack: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!benefit.data) {
      throw new Error('Benefit data is missing');
    }
    if (props.onBack) {
      props.onBack(e, goToPreviousStep);
    } else {
      goToPreviousStep(benefit.data);
    }
  };

  return (
    <>
      <Footer visible={guided || hasChanges}>
        <Stack direction="row" justifyContent="end" gap="20px">
          {guided && !sideTabs.isFirst && (
            <Button
              label={t('common.back')}
              variant="neutral-outline"
              onClick={handleBack}
            />
          )}
          {!guided && props.onSave && (
            <Button
              label={t('common.save')}
              variant="primary"
              onClick={props.onSave}
              disabled={props.saving}
            />
          )}
          {guided && (
            <Button
              // required to identify the element that was extracted
              // from a click event
              name="saveAndNext"
              label={t('common.saveAndNext')}
              variant="primary"
              onClick={handleSaveAndNext}
              disabled={props.saving}
            />
          )}
        </Stack>
      </Footer>
      <DataLossPrompt when={hasChanges} />
      <UnsavedChangesTracker hasChanges={hasChanges} />
      <BenefitWizardCompletionSuccessDialog
        open={completionSuccessDialog.open}
        onClose={completionSuccessDialog.closeDialog}
      />
    </>
  );
};

export default BenefitDetailsFooter;
