import { useMutation, useQueryClient } from '@tanstack/react-query';
import { adminClientDepositsKeys } from 'store/admin-client-deposits';
import { adminClientsKeys } from './admin-clients.queries';
import { loaders } from './admin-clients.loaders';

export const useAdminCreateClient = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createClient, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminClientsKeys.all);
    },
  });
};

export const useAdminUpdateClient = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateClient, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(adminClientsKeys.all),
        queryClient.invalidateQueries(adminClientDepositsKeys.all),
      ]);
    },
  });
};

export const useAdminDeleteClient = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteClient, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminClientsKeys.lists);
    },
  });
};
