import { useHistory } from 'react-router-dom';
import { Button } from 'melp-design/components';
import { ROUTES } from 'config';
import AddBenefitDialog from '../../../components/dialogs/AddBenefitDialog/AddBenefitDialog';
import { useMe } from '../../../state/Administrators';
import { useBenefitsMutations } from '../../../state/Benefits';
import { BenefitCreationRequest } from '../../../types/Benefits';
import { useDialog } from '../../../utils/Dialog';
import { usePredefinedToasts } from '../../../utils/Toast';

interface DropdownButtonProps {
  label: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
}

export default function AddBenefits({
  label,
  variant = 'primary',
}: DropdownButtonProps) {
  const history = useHistory();
  const predefinedToasts = usePredefinedToasts();

  const { canEditBenefits } = useMe();
  const creationDialog = useDialog();
  const benefitsMutations = useBenefitsMutations();

  const handleSubmit = async (data: BenefitCreationRequest) => {
    const response = await benefitsMutations.create.execute(data);
    predefinedToasts.success.added();
    creationDialog.closeDialog();
    history.push(
      `${ROUTES.benefits.details.replace(':id', response.id)}?creation=true`,
    );
  };

  return (
    <>
      <Button
        label={label}
        variant={variant}
        onClick={creationDialog.openDialog}
        disabled={!canEditBenefits}
      />
      <AddBenefitDialog
        onCancelClick={creationDialog.closeDialog}
        onActionClick={handleSubmit}
        open={creationDialog.open}
        loading={benefitsMutations.create.loading}
      />
    </>
  );
}
