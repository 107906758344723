import { Endpoints } from '../api/constants';
import {
  BenefitTemplate,
  BenefitTemplatesFilters,
} from '../types/BenefitTemplates';
import { useGet } from '../utils/Api';
import { convertToFilterQueryParams } from '../utils/Filters';

export const useBenefitTemplates = (filters: BenefitTemplatesFilters) => {
  const query = convertToFilterQueryParams(filters);
  return useGet<BenefitTemplate[]>(
    `${Endpoints.clientAdmin.benefitPlanTemplates.root}${query}`,
  );
};
