import {
  FormDialog,
  FormDialogProps,
  FormField,
  OptionsItem,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { useCompanyLanguages } from 'state/Administrators';
import { ContentLanguage } from 'types/general';

interface PdfDownloadFormData {
  language: Uppercase<ContentLanguage>;
}

interface Props
  extends Pick<
    FormDialogProps<PdfDownloadFormData>,
    'open' | 'onCancel' | 'onSubmit' | 'loading'
  > {
  /**
   * Available languages for selection
   */
  languages: Uppercase<ContentLanguage>[];
}

const BenefitPDFDownloadDialog = (props: Props) => {
  const { t } = useTranslation();
  const { defaultLanguage: mainLanguage } = useCompanyLanguages();

  const withoutMainLanguage = props.languages.filter(
    (lang) => lang !== mainLanguage,
  );
  const reOrderedLanguages = mainLanguage
    ? [mainLanguage, ...withoutMainLanguage]
    : withoutMainLanguage;

  return (
    <FormDialog {...props} submitLabel={t('common.downloadPDF')}>
      <FormField
        name="language"
        render="select"
        rules={{ required: true }}
        defaultValue={reOrderedLanguages[0]}
      >
        {reOrderedLanguages.map((language) => (
          <OptionsItem key={language} value={language}>
            {t(`language.${language}`)}
          </OptionsItem>
        ))}
      </FormField>
    </FormDialog>
  );
};

export default BenefitPDFDownloadDialog;
