import { Document, pdf } from '@react-pdf/renderer';
import fileDownloader from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { IFile } from 'types/income';
import { useCompanyLanguages } from 'state/Administrators';
import { resolveTranslation } from 'utils/general';
import { makeRequest } from '../../../../api/api';
import { Endpoints } from '../../../../api/constants';
import { Benefit, BenefitContent } from '../../../../types/Benefits';
import { BenefitMarketplaceItemsResponse } from '../../../../types/MarketplaceItems';
import { useLoading } from '../../../../utils/Loading';
import { useCurrency } from '../../../../utils/useCurrency';
import SummaryPage from '../pages/summary/SummaryPage';
import BenefitPDF from '../pages/benefit/BenefitPDF';

export const useMultipleBenefitsPDFDownload = (options?: {
  fileName?: string;
}) => {
  const { currency } = useCurrency();
  const { t } = useTranslation();
  const { defaultLanguage } = useCompanyLanguages();

  const generationState = useLoading();

  const fetchMarketplaceData = async (benefitId: string, language: string) => {
    const assignedItemsResponse =
      await makeRequest<BenefitMarketplaceItemsResponse>(
        'get',
        Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
          .root,
      );
    return {
      assignedItemsCount: assignedItemsResponse.data.count,
      firstItems: assignedItemsResponse.data.data
        .slice(0, 3)
        .map(
          (item) =>
            resolveTranslation(item.translations, language, defaultLanguage)
              ?.title ?? '',
        ),
    };
  };

  const fetchBenefitData = async (benefitId: string, language: string) => {
    const benefitResponse = await makeRequest<Benefit>(
      'get',
      Endpoints.clientAdmin.benefitPlans.byId(benefitId).root,
    );
    const benefitContentResponse = await makeRequest<BenefitContent>(
      'get',
      Endpoints.clientAdmin.benefitPlans.byId(benefitId).content,
    );
    const benefitContent = benefitContentResponse.data;
    let image: IFile | undefined = undefined;
    if (benefitContent.image?.id) {
      const imageResponse = await makeRequest<IFile>(
        'get',
        Endpoints.files.byId.replace(':fileId', benefitContent.image.id),
      );
      image = imageResponse.data;
    }
    const marketplaceData = await fetchMarketplaceData(benefitId, language);
    return {
      benefitData: {
        ...benefitResponse.data,
        ...benefitContent,
        image,
      },
      marketplaceData,
    };
  };

  const downloadPdf = async (benefitIds: string[], language: string) => {
    generationState.startLoading();
    try {
      const selectedBenefitsData = await Promise.all(
        benefitIds.map((id) => fetchBenefitData(id, language)),
      );

      const pageReferences = new Map<string, number>();
      const pageRenderHandler = (benefitId: string) => (pageNumber: number) => {
        if (!pageReferences.has(benefitId)) {
          pageReferences.set(benefitId, pageNumber);
        }
      };

      const renderPdf = () => {
        const commonProps = {
          language,
          currency,
        };

        return pdf(
          <Document>
            <SummaryPage
              benefits={selectedBenefitsData.map((data) => data.benefitData)}
              pageReferences={pageReferences}
              {...commonProps}
            />
            {selectedBenefitsData.map(({ benefitData, marketplaceData }) => (
              <BenefitPDF
                key={benefitData.id}
                data={benefitData}
                marketplaceData={marketplaceData}
                onRenderPage={pageRenderHandler(benefitData.id)}
                {...commonProps}
              />
            ))}
          </Document>,
        ).toBlob();
      };

      // Initial render to gather page references for summary page
      await renderPdf();
      // Final render with all data in place
      const blob = await renderPdf();

      const fileName =
        options?.fileName ?? t('benefits.selectedBenefitsPDFFileName');
      fileDownloader(blob, `${fileName}.pdf`);
    } finally {
      generationState.stopLoading();
    }
  };

  return {
    downloadPdf,
    loading: generationState.loading,
  };
};
