import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './administrators.loaders';
import { administratorsKeys } from './administrators.queries';

export const useUpdateAdministrator = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateAdministrator, {
    onSuccess: () => {
      return queryClient.invalidateQueries(administratorsKeys.all);
    },
  });
};

export const useSendInvite = () => {
  return useMutation(loaders.sendInvite);
};

export const useDeleteAdministrator = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteAdministrator, {
    onSuccess: () => {
      return queryClient.invalidateQueries(administratorsKeys.lists);
    },
  });
};
