import { parse } from 'querystring';
import { z } from 'zod';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSearchParams } from 'utils/navigation';
import { HeaderVariant, useTableStore } from './../state/Table';
import { UseTabs } from './../types/Common';
import { guidedSchema } from './useGuidedModeParam';

/**
 * @deprecated this hook should be used only in legacy code. Use useTabsState
 * instead.
 */
export default function useTabs(initialTab: string): UseTabs {
  const history = useHistory();
  const location = useLocation();
  const setHeaderVariant = useTableStore((state) => state.setVariant);

  const params = useMemo(
    () => parse(location.search.replace('?', '')),
    [location.search],
  );

  const paramTab = useMemo(() => params?.tab as string, [params?.tab]);

  const [currentTab, setCurrentTab] = useState<string>(initialTab);

  useEffect(() => {
    if ('tab' in params) {
      if (paramTab !== currentTab) {
        setHeaderVariant(paramTab as HeaderVariant);
        setCurrentTab(paramTab);
      }
    }
  }, [currentTab, paramTab, params, setHeaderVariant]);

  const clickableBackTabs = useMemo(
    () => ['companySettingsCompanyGroups', 'companySettingsCompanies'],
    [],
  );

  const changeTab = useCallback(
    async (_event: any, tab: string) => {
      const isTabAsBack = clickableBackTabs.includes(tab);
      if (params?.tab !== tab || isTabAsBack) {
        await history.push({
          pathname: location.pathname,
          search: `tab=${tab}`,
        });
        if (paramTab === tab) {
          setHeaderVariant(tab as HeaderVariant);
          setCurrentTab(tab);
        }
      }
    },
    [
      clickableBackTabs,
      history,
      location.pathname,
      paramTab,
      params?.tab,
      setHeaderVariant,
    ],
  );

  return {
    currentTab,
    changeTab,
  };
}

export const useTabsState = <
  const Tab extends string,
  Schema extends z.ZodType<
    { tab: Tab; guided?: z.infer<typeof guidedSchema>['guided'] },
    any,
    any
  >,
>(
  tabs: Array<{ key: Tab; label: string; isActive?: boolean }>,
  schema: Schema,
) => {
  const {
    searchParams: { guided },
  } = useSearchParams(guidedSchema);
  const { searchParams, navigate } = useSearchParams(
    schema.catch({ tab: tabs[0].key, guided }),
  );

  const activeTab = searchParams.tab;
  const activeTabIndex = tabs.findIndex((tab) => tab.key === activeTab);
  const previousTab =
    activeTabIndex > 0 ? tabs[activeTabIndex - 1].key : undefined;
  const nextTab =
    activeTabIndex < tabs.length - 1 ? tabs[activeTabIndex + 1].key : undefined;

  const goTo = (tab: Tab) => {
    navigate({ params: { tab, guided: searchParams.guided }, replace: true });
  };

  const next = () => {
    if (nextTab) {
      goTo(nextTab);
    }
  };

  const back = () => {
    if (previousTab) {
      goTo(previousTab);
    }
  };

  return {
    tabs,
    activeTab,
    goTo,
    next,
    back,
    isFirst: !previousTab,
    isLast: !nextTab,
  };
};
