import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { Stack } from '@mui/material';
import {
  DateInput,
  DateTimeInput,
  Modal,
  SwitchInput,
} from 'melp-design/components';
import { ModalProps } from 'store/modal';

interface Values {
  date: string;
  isNow: boolean;
}

interface Props extends ModalProps {
  withTime?: boolean;
  closeModal: (
    param?:
      | { action: 'CLOSE' }
      | { action: 'CONFIRM'; date: string; isNow: boolean },
  ) => void;
}

export const AssignmentDateSelectModal: FC<Props> = ({
  withTime = false,
  closeModal,
}) => {
  const { t } = useTranslation();

  const { handleSubmit, control, watch } = useForm<Values>({
    defaultValues: {
      date: moment().toISOString(true),
      isNow: false,
    },
  });

  const onSubmit: SubmitHandler<Values> = (values) => {
    closeModal({
      action: 'CONFIRM',
      date: withTime ? values.date : values.date.split('T')[0],
      isNow: values.isNow,
    });
  };

  const [isNow] = watch(['isNow']);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('actions.on_specific_date')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
          },
        ]}
      >
        <Stack gap={2}>
          <Controller
            name="date"
            control={control}
            render={({ field: { name, value, onChange, ref } }) =>
              withTime ? (
                <DateTimeInput
                  label={t('common.date')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  disabled={isNow}
                  ref={ref}
                />
              ) : (
                <DateInput
                  label={t('common.date')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  required
                  disabled={isNow}
                  ref={ref}
                />
              )
            }
          />

          <Controller
            name="isNow"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <SwitchInput
                label={t('common.now')}
                name={name}
                value={value}
                onChange={onChange}
                ref={ref}
              />
            )}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
