import { FC } from 'react';
import {
  AutocompleteInput,
  FileInput,
  ImageList,
  Modal,
  NumberInput,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Stack } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { usePredefinedToasts } from 'utils/Toast';
import {
  AdminBrandVariant,
  adminBrandVariants,
  useAdminCreateBrand,
} from 'store/admin-brands';
import { shopItemCategories, ShopItemCategory } from 'types/MarketplaceItems';
import { useUploadFile } from 'store/files';
import { Delete } from '@mui/icons-material';
import { routerHistory } from 'utils/router-history';
import { ROUTES } from 'config';

interface Values {
  name: string;
  slug: string;
  type: AdminBrandVariant;
  primaryCategory: ShopItemCategory;
  secondaryCategory: ShopItemCategory | '';
  priorityIndex: number;
  searchTags: string;
  imageId: string;
}

interface Props extends ModalProps {}

export const CreateBrandModal: FC<Props> = ({ closeModal }) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm<Values>({
    defaultValues: {
      name: '',
      slug: '',
      type: 'gift_card',
      primaryCategory: 'entertainment',
      secondaryCategory: '',
      priorityIndex: 1,
      searchTags: '',
      imageId: '',
    },
  });

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile();
  const { mutate: createBrand, isLoading: isCreating } = useAdminCreateBrand();

  const onSubmit: SubmitHandler<Values> = (values) => {
    createBrand(
      {
        name: values.name,
        slug: values.slug,
        type: values.type,
        primaryCategory: values.primaryCategory,
        secondaryCategory: values.secondaryCategory || null,
        priorityIndex: values.priorityIndex,
        searchTags: values.searchTags,
        logoId: values.imageId,
      },
      {
        onSuccess: ({ data }) => {
          predefinedToasts.success.added();
          closeModal({ action: 'CLOSE' });
          routerHistory.push(
            ROUTES.admin.brands.settings.replace(':id', data.brandId),
          );
        },
      },
    );
  };

  const [imageId] = watch(['imageId']);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('brands.actions.create')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.close'),
          },
          {
            variant: 'primary',
            label: t('common.save'),
            type: 'submit',
            disabled: !isDirty || isCreating,
          },
        ]}
      >
        <Stack gap={2}>
          <Controller
            name="name"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <TextField
                label={t('common.name')}
                name={name}
                value={value}
                onChange={onChange}
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="slug"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <TextField
                label={t('common.slug')}
                name={name}
                value={value}
                onChange={onChange}
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="type"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <SelectInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  label={t('integrations.type')}
                  options={adminBrandVariants.map((variant) => ({
                    label: t(`brands.variants.${variant}`),
                    value: variant,
                  }))}
                  required
                  ref={ref}
                />
              );
            }}
          />

          <Controller
            name="primaryCategory"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <AutocompleteInput
                label={t('brands.primary_category.label')}
                name={name}
                value={value}
                onChange={onChange}
                options={shopItemCategories.map((category) => ({
                  label: t(`marketplaceItems.itemCategory-${category}`),
                  value: category,
                }))}
                multiple={false}
                required
                ref={ref}
              />
            )}
          />

          <Controller
            name="secondaryCategory"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <AutocompleteInput
                label={t('brands.secondary_category.label')}
                name={name}
                value={value}
                onChange={onChange}
                options={shopItemCategories.map((category) => ({
                  label: t(`marketplaceItems.itemCategory-${category}`),
                  value: category,
                }))}
                multiple={false}
                ref={ref}
              />
            )}
          />

          <Controller
            name="priorityIndex"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <NumberInput
                label={t('common.priority.label')}
                name={name}
                value={value}
                onChange={onChange}
                required
                type="integer"
                key={value}
                ref={ref}
              />
            )}
          />

          <Controller
            name="searchTags"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <TextField
                label={t('shop.search_tags.label')}
                name={name}
                value={value}
                onChange={onChange}
                rows={5}
                ref={ref}
              />
            )}
          />

          <Controller
            name="imageId"
            control={control}
            render={({ field: { name, onChange, ref } }) => {
              return imageId ? (
                <ImageList
                  items={[
                    {
                      imageId,
                      actions: [
                        {
                          icon: Delete,
                          variant: 'neutral-outline',
                          onClick: () => onChange(''),
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                <FileInput
                  name={name}
                  onChange={([file]) => {
                    if (file) {
                      uploadFile(
                        { file },
                        {
                          onSuccess: ({ data }) => {
                            onChange(data.id);
                          },
                        },
                      );
                    } else {
                      onChange('');
                    }
                  }}
                  disabled={isUploading}
                  maxFiles={1}
                  previewSelectedFiles={false}
                  accept={['jpg', 'jpeg', 'png', 'gif', 'webp']}
                  required
                  ref={ref}
                />
              );
            }}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
