import { FC } from 'react';
import { DateInput, DateTimeInput, Modal } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { ModalProps } from 'store/modal';
import { usePredefinedToasts } from 'utils/Toast';
import {
  AssignmentEntityRelation,
  useUpdateAssignment,
} from 'store/assignments';
import moment from 'moment';

interface Values {
  from: string;
  to: string | null;
}

interface Props extends ModalProps {
  assignmentId: string;
  relation: AssignmentEntityRelation;
  initialValues: {
    from: string;
    to: string | null;
  };
  withTime?: boolean;
}

export const UpdateAssignmentModal: FC<Props> = ({
  assignmentId,
  relation,
  initialValues,
  withTime = false,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<Values>({
    defaultValues: initialValues,
  });

  const { mutate: updateAssignment, isLoading: isUpdating } =
    useUpdateAssignment();

  const isInPast = {
    from: initialValues.from ? moment(initialValues.from).isBefore() : false,
    to: initialValues.to ? moment(initialValues.to).isBefore() : false,
  };

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateAssignment(
      {
        assignmentId,
        relation,
        data: {
          from: isInPast.from
            ? undefined
            : withTime
            ? values.from
            : values.from.split('T')[0],
          to: isInPast.to
            ? undefined
            : withTime
            ? values.to || undefined
            : values.to?.split('T')[0] || undefined,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
          closeModal();
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('alternatives.dateRange')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal(),
            label: t('common.close'),
          },
          {
            variant: 'primary',
            label: t('common.save'),
            type: 'submit',
            disabled: !isDirty || isUpdating,
          },
        ]}
      >
        <Stack gap={4}>
          <Stack gap={2}>
            <Controller
              name="from"
              control={control}
              render={({ field: { name, value, onChange, ref } }) =>
                withTime ? (
                  <DateTimeInput
                    label={t('common.from')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    disabled={isInPast.from}
                    datePickerProps={{
                      minDateTime: moment(),
                    }}
                    ref={ref}
                  />
                ) : (
                  <DateInput
                    label={t('common.from')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    disabled={isInPast.from}
                    datePickerProps={{
                      minDate: moment(),
                    }}
                    ref={ref}
                  />
                )
              }
            />
            <Controller
              name="to"
              control={control}
              render={({ field: { name, value, onChange, ref } }) =>
                withTime ? (
                  <DateTimeInput
                    label={t('common.to')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={isInPast.to}
                    datePickerProps={{
                      minDate: moment(),
                    }}
                    ref={ref}
                  />
                ) : (
                  <DateInput
                    label={t('common.to')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={isInPast.to}
                    datePickerProps={{
                      minDate: moment(),
                    }}
                    ref={ref}
                  />
                )
              }
            />
          </Stack>
        </Stack>
      </Modal.Content>
    </form>
  );
};
