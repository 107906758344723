import { useTranslation } from 'react-i18next';
import { ListCard } from 'melp-design/components';
import getPriceLabel from 'components/marketplace/ItemCard/common/getPriceLabel';
import { richTextAsPlainText } from 'utils/richTextAsPlainText';
import { ShopItemDetailsModal } from 'components/marketplace/shop-item-details-modal';
import { useBenefitMarketplaceItemActions } from 'state/MarketplaceItems.clientAdmin';
import { MarketplaceItem } from 'types/MarketplaceItems';
import { useModalContext } from 'store/modal';
import { useCompanyLanguages } from 'state/Administrators';
import { resolveTranslation } from 'utils/general';

interface Props<T> {
  item: T;
  isSelected?: boolean;
  benefitId: string;
  onSelectionCompleted?: (itemId: string) => void;
  onRemovalCompleted?: (itemId: string) => void;
  removeDisabled?: boolean;
  /**
   * Disables card actions entirely
   */
  disabled?: boolean;
}

const ItemCard = <T extends MarketplaceItem>({
  item,
  isSelected = false,
  benefitId,
  onSelectionCompleted = () => {},
  onRemovalCompleted = () => {},
  removeDisabled,
  disabled,
}: Props<T>) => {
  const { t, i18n } = useTranslation();
  const { defaultLanguage } = useCompanyLanguages();
  const { openModal } = useModalContext();

  const { id } = item;
  const translation = resolveTranslation(
    item.translations,
    i18n.language,
    defaultLanguage,
  );

  const { selectItem, removeItem } = useBenefitMarketplaceItemActions(
    benefitId,
    id,
  );

  const handleItemSelection = async () => {
    await selectItem.execute();
    onSelectionCompleted(id);
  };

  const handleItemRemoval = async () => {
    await removeItem.execute();
    onRemovalCompleted(id);
  };

  return (
    <ListCard
      title={translation?.title}
      description={richTextAsPlainText(translation?.description ?? '')}
      label={getPriceLabel(item)}
      image={item.image?.signedUrl}
      onClick={() => {
        openModal({
          component: ShopItemDetailsModal,
          size: 'lg',
          props: {
            item: {
              title: translation?.title ?? '',
              description: translation?.description ?? '',
              benefitCategories: item.benefitPlanTypes ?? [],
              categories: item.categories,
              countries: item.countries,
              cities: item.cities,
              currency: item.currency,
              prices: item.prices,
              supplier: {
                name: item.supplier.name,
                termsAndConditionsLink: item.supplier.termsAndConditionsLink,
              },
              image: item.image?.signedUrl,
              logo: item.supplier.logo?.signedUrl,
            },
            actions: [
              isSelected
                ? {
                    variant: 'danger-fill',
                    label: t('marketplace.removeItem'),
                    onClick: handleItemRemoval,
                    disabled: removeDisabled || disabled,
                    tooltip: removeDisabled
                      ? t('marketplace.autoAssignedNotRemovable')
                      : undefined,
                  }
                : {
                    variant: 'primary',
                    onClick: handleItemSelection,
                    label: t('marketplace.selectItem'),
                    disabled,
                  },
            ],
          },
        });
      }}
      highlighted={isSelected}
      action={
        isSelected
          ? {
              label: t('marketplace.removeItem'),
              variant: 'danger-outline',
              onClick: handleItemRemoval,
              disabled:
                removeItem.loading ||
                selectItem.loading ||
                removeDisabled ||
                disabled,
              tooltip: removeDisabled
                ? t('marketplace.autoAssignedNotRemovable')
                : undefined,
            }
          : {
              label: t('marketplace.selectItem'),
              variant: 'secondary',
              onClick: handleItemSelection,
              disabled:
                removeItem.loading ||
                selectItem.loading ||
                removeDisabled ||
                disabled,
            }
      }
    />
  );
};

export default ItemCard;
