import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './benefits.loaders';
import { converters } from './benefits.converters';
import {
  BenefitsFilters,
  EmployeeBenefitsFilters,
  LiteBenefitsFilters,
} from './benefits.types';

export const benefitsKeys = queryKeyFactory('benefits');

export const useBenefits = (
  filters: Partial<BenefitsFilters> = {},
  options: { enabled?: boolean } = {},
) => {
  return useQuery({
    queryKey: benefitsKeys.list(filters),
    queryFn: () => loaders.getBenefits({ filters }),
    select: converters.getBenefits,
    keepPreviousData: true,
    enabled: options.enabled,
  });
};

export const useLiteBenefits = (filters: Partial<LiteBenefitsFilters> = {}) => {
  return useQuery({
    queryKey: benefitsKeys.list('lite', filters),
    queryFn: () => loaders.getLiteBenefits({ filters }),
    select: converters.getLiteBenefits,
  });
};

export const useEmployeeBenefits = (
  employeeId: string,
  filters: Partial<EmployeeBenefitsFilters> = {},
) => {
  return useQuery({
    queryKey: benefitsKeys.list('employee', employeeId, filters),
    queryFn: () => loaders.getEmployeeBenefits({ employeeId, filters }),
    select: converters.getEmployeeBenefits,
    enabled: !!employeeId,
  });
};

export const useBenefitCategoriesContent = () => {
  return useQuery({
    queryKey: benefitsKeys.list('categories'),
    queryFn: () => loaders.getCategoriesContent(),
    select: converters.getCategoriesContent,
    staleTime: 1000 * 60 * 60,
  });
};
