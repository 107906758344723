import {
  ButtonOld,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Loader,
  NoData,
  Typography,
} from 'melp-design/components';
import { Benefits, Categories, Location } from 'melp-design/icons';
import { Colors, Effects } from 'melp-design/style';
import { Alert, Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { contentLanguages } from 'types/general';
import { formatCountry, formatLanguage } from 'utils/format';
import { benefitCategories } from 'store/benefits';
import { FilterButton, Filters, SelectFilter } from 'components/filters';
import { APP } from 'config';
import CountryFlag from '../../../components/common/CountryFlag/CountryFlag';
import { Colors as OldColors } from '../../../constants/Style';
import { useBenefitTemplates } from '../../../state/BenefitTemplates.clientAdmin';
import {
  BenefitTemplate,
  BenefitTemplatesFilters,
} from '../../../types/BenefitTemplates';
import RichText from '../../common/RichText/RichText';

const LargeDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
  },
});

const LargeDialogActions = styled(DialogActions)({
  padding: '15px 30px',

  '&.MuiDialogActions-spacing': {
    gap: '20px',
  },
});

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: '30px 50px 30px 30px',
  },
  dialogContent: {
    display: 'flex',
    padding: 0,
    overflow: 'auto',
    height: '480px',
  },
  listContainer: {
    flex: 8,
    padding: '30px',
    background: Colors.lightBackgroundGrey,
    overflow: 'auto',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 180px)',
    gap: '30px',
  },
  sidebar: {
    flex: 4,
    borderLeft: `1px solid ${OldColors.Divider}`,
    boxShadow: Effects.blockShadow,
    background: theme.palette.common.white,
    padding: '30px',
    overflow: 'auto',
    position: 'relative',
  },
  sidebarDivider: {
    margin: '20px 0',
  },
  dialogAction: {
    minWidth: 190,
  },
  infoItem: {
    marginBottom: 40,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  infoItemTitle: {
    marginBottom: 10,
    display: 'flex',
    columnGap: 5,
    '& svg': {
      color: Colors.grey,
      height: 20,
    },
  },
  countries: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 15,
    rowGap: 15,
  },
  country: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  templateImage: {
    backgroundColor: Colors.elementsGrey,
  },
  cardContent: {
    paddingBottom: 15,
  },
}));

export interface TranslatedBenefitTemplate extends BenefitTemplate {
  title: string;
  description: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onCreate: (template: TranslatedBenefitTemplate) => void;
}

const TemplateDialog = (props: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState<BenefitTemplate>();

  const [filters, setFilters] = useState<BenefitTemplatesFilters>({});
  useEffect(() => {
    setSelectedTemplate(undefined);
  }, [filters]);

  const { data: templates, error } = useBenefitTemplates(filters);

  const renderInfoItem = (
    icon: ReactNode,
    title: string,
    content: ReactNode,
  ) => {
    return (
      <div className={classes.infoItem}>
        <div className={classes.infoItemTitle}>
          {icon}
          <Typography variant="h3">{title}</Typography>
        </div>
        {content}
      </div>
    );
  };

  const resolveTranslation = (template: BenefitTemplate) => {
    const { translations } = template;
    if (!translations.length) {
      return { title: template.name, description: '' };
    }

    const findTranslationByLang = (lang: string) => {
      const translationFound = template.translations.find(
        ({ language }) => language === lang,
      );

      if (!translationFound) {
        return undefined;
      }

      return {
        title: translationFound.title,
        description: translationFound.description,
      };
    };

    const currentLanguage = i18n.language.toUpperCase();
    const userLangTranslation = findTranslationByLang(currentLanguage);
    if (userLangTranslation) {
      return userLangTranslation;
    }
    const defaultTranslation = findTranslationByLang(template.defaultLanguage);
    return defaultTranslation ?? template.translations[0];
  };

  const selectedTemplateTranslation = selectedTemplate
    ? resolveTranslation(selectedTemplate)
    : undefined;

  const handleCancel = () => {
    props.onClose();
    setSelectedTemplate(undefined);
  };

  return (
    <LargeDialog open={props.open} onClose={handleCancel} width={1050}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h1">
          {t('benefits.createUsingTemplateDialogTitle')}
        </Typography>
        <Box mt="20px">
          <Filters value={filters} onChange={setFilters}>
            {({ value, setFilter }) => (
              <>
                <FilterButton
                  label={t('benefits.country')}
                  value={value.supportedCountries}
                  onChange={(newValue) =>
                    setFilter('supportedCountries', newValue)
                  }
                  disablePortal
                >
                  {({ value, applyFilter, clearFilter, close }) => (
                    <SelectFilter
                      multiple
                      value={value}
                      onApplyFilter={applyFilter}
                      onClearFilter={clearFilter}
                      onCancel={close}
                      options={APP.countries.alpha3.map((alpha3) => ({
                        key: alpha3,
                        label: formatCountry(i18n.language, alpha3),
                      }))}
                    />
                  )}
                </FilterButton>
                <FilterButton
                  label={t('benefitTemplates.benefitCategory')}
                  value={filters.type}
                  onChange={(newValue) => setFilter('type', newValue)}
                  disablePortal
                >
                  {({ value, applyFilter, clearFilter, close }) => (
                    <SelectFilter
                      multiple
                      value={value}
                      onApplyFilter={applyFilter}
                      onClearFilter={clearFilter}
                      onCancel={close}
                      options={benefitCategories.map((category) => ({
                        key: category,
                        label: t(`menu.${category}`),
                      }))}
                    />
                  )}
                </FilterButton>
                <FilterButton
                  label={t('marketplaceItems.translationLanguage')}
                  value={filters.supportedLanguages}
                  onChange={(newValue) =>
                    setFilter('supportedLanguages', newValue)
                  }
                  disablePortal
                >
                  {({ value, applyFilter, clearFilter, close }) => (
                    <SelectFilter
                      multiple
                      value={value}
                      onApplyFilter={applyFilter}
                      onClearFilter={clearFilter}
                      onCancel={close}
                      options={contentLanguages.map((language) => ({
                        key: language.toUpperCase(),
                        label: formatLanguage(i18n.language, language),
                      }))}
                    />
                  )}
                </FilterButton>
              </>
            )}
          </Filters>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <div className={classes.listContainer}>
          {!templates && !error && <Loader />}
          {!templates && error && (
            <Alert severity="error">{t('errors.something_went_wrong')}</Alert>
          )}
          {templates && !templates.length && (
            <NoData title={t('common.noDataForFilters')} />
          )}
          {templates && !!templates.length && (
            <div className={classes.list}>
              {templates.map((template) => (
                <Card
                  key={template.id}
                  highlighted={selectedTemplate?.id === template.id}
                >
                  <CardActionArea onClick={() => setSelectedTemplate(template)}>
                    <CardMedia
                      image={template.thumbnail.signedUrl}
                      className={classes.templateImage}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h3" align="center">
                        {resolveTranslation(template).title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </div>
          )}
        </div>
        <div className={classes.sidebar}>
          {!selectedTemplate && (
            <>
              <Typography variant="h2">
                {t('benefits.aboutTemplatesTitle')}
              </Typography>
              <Divider className={classes.sidebarDivider} />
              <Typography variant="p1" color="textSecondary">
                {t('benefits.templatesDescription')}
              </Typography>
            </>
          )}
          {!!selectedTemplate && selectedTemplateTranslation && (
            <>
              <Typography variant="h2">
                {selectedTemplateTranslation.title}
              </Typography>
              <Divider className={classes.sidebarDivider} />
              {selectedTemplateTranslation.description && (
                <Typography
                  variant="p1"
                  color="textSecondary"
                  className={classes.infoItem}
                >
                  <RichText value={selectedTemplateTranslation.description} />
                </Typography>
              )}
              {renderInfoItem(
                <Benefits />,
                t('benefits.benefitCategory'),
                <Typography variant="p1" color="textSecondary">
                  {t(`menu.${selectedTemplate.type}`)}
                </Typography>,
              )}
              {renderInfoItem(
                <Location />,
                t('benefits.country'),
                <div className={classes.countries}>
                  {selectedTemplate.supportedCountries.map(
                    (supportedCountry) => {
                      return (
                        <div className={classes.country} key={supportedCountry}>
                          <CountryFlag countryCode={supportedCountry} />
                          <Typography variant="p1" color="textSecondary">
                            {formatCountry(i18n.language, supportedCountry)}
                          </Typography>
                        </div>
                      );
                    },
                  )}
                </div>,
              )}
              {renderInfoItem(
                <Categories />,
                t('benefits.languages'),
                <Typography variant="p1" color="textSecondary">
                  {selectedTemplate.supportedLanguages
                    .map((language) => t(`language.${language}`))
                    .join(', ')}
                </Typography>,
              )}
            </>
          )}
        </div>
      </DialogContent>
      <LargeDialogActions>
        <ButtonOld
          variant="outlined"
          color="primary"
          size="large"
          className={classes.dialogAction}
          onClick={handleCancel}
        >
          {t('common.cancel')}
        </ButtonOld>
        <ButtonOld
          variant="contained"
          color="primary"
          size="large"
          className={classes.dialogAction}
          onClick={() => {
            if (selectedTemplate && selectedTemplateTranslation) {
              props.onCreate({
                ...selectedTemplate,
                ...selectedTemplateTranslation,
              });
              setSelectedTemplate(undefined);
            }
          }}
          disabled={!selectedTemplate}
        >
          {t('benefits.useTemplate')}
        </ButtonOld>
      </LargeDialogActions>
    </LargeDialog>
  );
};

export default TemplateDialog;
