import { Svg, Path, Ellipse } from '@react-pdf/renderer';

interface Props {
  color: string;
  fillColor: string;
}

const CoinsPDFIcon = ({ color, fillColor }: Props) => {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12">
      <Path
        fillRule="evenodd"
        clip-rule="evenodd"
        fill={fillColor}
        d="M4 1C5.65685 1 7 1.89543 7 3V7C7 8.10457 5.65685 9 4 9C2.34315 9 1 8.10457 1 7V3C1 1.89543 2.34315 1 4 1Z"
      />
      <Path
        d="M7.25 3C7.25 2.33812 6.84758 1.7695 6.26 1.37777C5.67066 0.984883 4.8709 0.75 4 0.75V1.25C4.78595 1.25 5.4862 1.46283 5.98265 1.7938C6.48084 2.12593 6.75 2.55731 6.75 3H7.25ZM7.25 7V3H6.75V7H7.25ZM6.75 7C6.75 7.44269 6.48084 7.87407 5.98265 8.2062C5.4862 8.53717 4.78595 8.75 4 8.75V9.25C4.8709 9.25 5.67066 9.01512 6.26 8.62223C6.84758 8.2305 7.25 7.66188 7.25 7H6.75ZM4 8.75C3.21405 8.75 2.5138 8.53717 2.01735 8.2062C1.51916 7.87407 1.25 7.44269 1.25 7H0.75C0.75 7.66188 1.15242 8.2305 1.74 8.62223C2.32934 9.01512 3.1291 9.25 4 9.25V8.75ZM0.75 3V7H1.25V3H0.75ZM4 0.75C3.1291 0.75 2.32934 0.984883 1.74 1.37777C1.15242 1.7695 0.75 2.33812 0.75 3H1.25C1.25 2.55731 1.51916 2.12593 2.01735 1.7938C2.5138 1.46283 3.21405 1.25 4 1.25V0.75Z"
        fill={color}
      />
      <Ellipse
        cx="4"
        cy="3"
        rx="3"
        ry="2"
        stroke={color}
        fill={fillColor}
        strokeWidth={0.5}
      />
      <Path
        d="M7 5C7 6.10457 5.65685 7 4 7C2.34315 7 1 6.10457 1 5"
        stroke={color}
        strokeWidth={0.5}
      />
      <Path
        fillRule="evenodd"
        clip-rule="evenodd"
        fill={fillColor}
        d="M8 5C9.65685 5 11 5.89543 11 7V9C11 10.1046 9.65685 11 8 11C6.34315 11 5 10.1046 5 9V7C5 5.89543 6.34315 5 8 5Z"
      />
      <Path
        d="M11.25 7C11.25 6.33812 10.8476 5.7695 10.26 5.37777C9.67066 4.98488 8.8709 4.75 8 4.75V5.25C8.78595 5.25 9.4862 5.46283 9.98265 5.7938C10.4808 6.12593 10.75 6.55731 10.75 7H11.25ZM11.25 9V7H10.75V9H11.25ZM10.75 9C10.75 9.44269 10.4808 9.87407 9.98265 10.2062C9.4862 10.5372 8.78595 10.75 8 10.75V11.25C8.8709 11.25 9.67066 11.0151 10.26 10.6222C10.8476 10.2305 11.25 9.66188 11.25 9H10.75ZM8 10.75C7.21405 10.75 6.5138 10.5372 6.01735 10.2062C5.51916 9.87407 5.25 9.44269 5.25 9H4.75C4.75 9.66188 5.15242 10.2305 5.74 10.6222C6.32934 11.0151 7.1291 11.25 8 11.25V10.75ZM4.75 7V9H5.25V7H4.75ZM8 4.75C7.1291 4.75 6.32934 4.98488 5.74 5.37777C5.15242 5.7695 4.75 6.33812 4.75 7H5.25C5.25 6.55731 5.51916 6.12593 6.01735 5.7938C6.5138 5.46283 7.21405 5.25 8 5.25V4.75Z"
        fill={color}
      />
      <Ellipse
        cx="8"
        cy="7"
        rx="3"
        ry="2"
        stroke={color}
        fill={fillColor}
        strokeWidth={0.5}
      />
    </Svg>
  );
};

export default CoinsPDFIcon;
