import { FC, ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'config';
import { Loader } from 'melp-design/components';
import { AdminPermission } from 'store/admin-admins';
import { containsAll } from 'utils/general';
import { AdministratorRole } from 'store/administrators';
import { useAuthContext } from '../../auth/index';
import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionLevels,
} from '../../constants/Administrators';
import { useMe } from '../../state/Administrators';

interface Props {
  /**
   * Route path
   */
  path: string;
  /**
   * Route content
   */
  children: ReactElement;
  /**
   * Roles by which a route is accessible
   */
  roles?: AdministratorRole[];
  /**
   * Modules, which a user has to have access to, in order to access a route
   */
  module?:
    | LocalPermissionModules
    | GlobalPermissionModules
    | Array<LocalPermissionModules | GlobalPermissionModules>;
  adminModules?: AdminPermission[];
  /**
   * Permission level, which a user must have, to access a route.
   */
  permissionLevel?: PermissionLevels;
}

export const ProtectedRoute: FC<Props> = ({
  roles,
  permissionLevel,
  module,
  adminModules = [],
  path,
  children,
}) => {
  const { loggedIn } = useAuthContext();

  const { me, loading, getPermissionsByModule, adminPermissions, isRoot } =
    useMe();

  if (!loggedIn) {
    return (
      <Redirect to={{ pathname: ROUTES.auth.login, state: { from: path } }} />
    );
  }

  if (loading) return <Loader />;

  const hasRequiredRole = !!me?.role && !!roles && roles.includes(me.role);

  const hasRequiredPermissions = () => {
    if (me?.role === 'melpAdmin') {
      return containsAll(adminPermissions, adminModules);
    }

    if (isRoot) return true;
    if (!permissionLevel) return true;

    const requiredPermissions = module ? getPermissionsByModule(module) : [];

    return requiredPermissions.some(
      (permission) =>
        permission.permission === 'edit' || permission.permission === 'view',
    );
  };

  if (!hasRequiredRole || !hasRequiredPermissions()) {
    return <Redirect to={ROUTES.root.notFound} />;
  }

  return children;
};
