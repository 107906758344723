import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './assignments.loaders';
import { converters } from './assignments.converters';
import {
  BenefitByEmployeeGroupFilters,
  BenefitsByEmployeeFilters,
  BenefitsByEmployeePreviewFilters,
  EmployeeGroupsByBenefitFilters,
  EmployeeGroupsByEmployeeFilters,
  EmployeesByBenefitFilters,
  EmployeesByEmployeeGroupFilters,
} from './assignments.types';

export const assignmentsKeys = queryKeyFactory('assignments');

export const useEmployeesByBenefitAssignments = (
  benefitId: string,
  filters: Partial<EmployeesByBenefitFilters> = {},
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('employees', benefitId, filters),
    queryFn: () => loaders.getEmployeesByBenefit({ benefitId, filters }),
    select: converters.getEmployeesByBenefit,
    enabled: !!benefitId,
  });
};

export const useEmployeeGroupsByBenefitAssignments = (
  benefitId: string,
  filters: Partial<EmployeeGroupsByBenefitFilters> = {},
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('employees-groups', benefitId, filters),
    queryFn: () => loaders.getEmployeeGroupsByBenefit({ benefitId, filters }),
    select: converters.getEmployeeGroupsByBenefit,
    enabled: !!benefitId,
  });
};

export const useBenefitsByEmployeeAssignments = (
  employeeId: string,
  filters: Partial<BenefitsByEmployeeFilters> = {},
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('benefits', employeeId, filters),
    queryFn: () => loaders.getBenefitsByEmployee({ employeeId, filters }),
    select: converters.getBenefitsByEmployee,
    enabled: !!employeeId,
  });
};

export const useBenefitsByEmployeeAssignmentsPreview = (
  employeeId: string,
  filters: Partial<BenefitsByEmployeePreviewFilters> = {},
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('benefits-preview', employeeId, filters),
    queryFn: () => loaders.getBenefitsByEmployeePreview({ employeeId }),
    select: (data) => converters.getBenefitsByEmployeePreview(data, filters),
    enabled: !!employeeId,
  });
};

export const useBenefitEmployeeAssignmentTimeline = (
  benefitId: string,
  employeeId: string,
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('timeline', employeeId, benefitId),
    queryFn: () =>
      loaders.getBenefitsEmployeeAssignmentTimeline({ benefitId, employeeId }),
    select: (data) => converters.getBenefitsEmployeeAssignmentTimeline(data),
    enabled: !!benefitId && !!employeeId,
  });
};

export const useEmployeeGroupsByEmployeeAssignments = (
  employeeId: string,
  filters: Partial<EmployeeGroupsByEmployeeFilters> = {},
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('employee-groups', employeeId, filters),
    queryFn: () => loaders.getEmployeeGroupsByEmployee({ employeeId, filters }),
    select: converters.getEmployeeGroupsByEmployee,
    enabled: !!employeeId,
  });
};

export const useBenefitsByEmployeeGroupAssignments = (
  employeeGroupId: string,
  filters: Partial<BenefitByEmployeeGroupFilters> = {},
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('benefits', employeeGroupId, filters),
    queryFn: () =>
      loaders.getBenefitsByEmployeeGroup({ employeeGroupId, filters }),
    select: converters.getBenefitsByEmployeeGroup,
    enabled: !!employeeGroupId,
  });
};

export const useEmployeesByEmployeeGroupAssignments = (
  employeeGroupId: string,
  filters: Partial<EmployeesByEmployeeGroupFilters> = {},
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('employees', employeeGroupId, filters),
    queryFn: () =>
      loaders.getEmployeesByEmployeeGroup({ employeeGroupId, filters }),
    select: converters.getEmployeesByEmployeeGroup,
    enabled: !!employeeGroupId,
  });
};

export const useEmployeeBenefitAssignmentHistory = (
  employeeId: string,
  benefitId: string,
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('history', employeeId, benefitId),
    queryFn: () =>
      loaders.getEmployeeBenefitAssignmentHistory({ employeeId, benefitId }),
    select: converters.getEmployeeBenefitAssignmentHistory,
    enabled: !!employeeId && !!benefitId,
  });
};

export const useBenefitEmployeeGroupAssignmentHistory = (
  benefitId: string,
  employeeGroupId: string,
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('history', benefitId, employeeGroupId),
    queryFn: () =>
      loaders.getBenefitEmployeeGroupAssignmentHistory({
        benefitId,
        employeeGroupId,
      }),
    select: converters.getBenefitEmployeeGroupAssignmentHistory,
    enabled: !!employeeGroupId && !!benefitId,
  });
};

export const useEmployeeEmployeeGroupAssignmentHistory = (
  employeeId: string,
  employeeGroupId: string,
) => {
  return useQuery({
    queryKey: assignmentsKeys.list('history', employeeId, employeeGroupId),
    queryFn: () =>
      loaders.getEmployeeEmployeeGroupAssignmentHistory({
        employeeId,
        employeeGroupId,
      }),
    select: converters.getEmployeeEmployeeGroupAssignmentHistory,
    enabled: !!employeeId && !!employeeGroupId,
  });
};
