import { FC } from 'react';
import { BenefitCategoriesList } from 'containers/benefits';
import { BenefitsLayout } from 'pages/atoms';

const BenefitCategoriesPage: FC = () => {
  return (
    <BenefitsLayout>
      <BenefitCategoriesList />
    </BenefitsLayout>
  );
};

export default BenefitCategoriesPage;
