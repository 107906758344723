import {
  DeleteIconButton,
  FieldsList,
  MultilingualFormField,
  OrderChangeButton,
  Panel,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MultilingualFAQ } from './types';

const FAQs = () => {
  const { t } = useTranslation();

  return (
    <Panel title={t('benefits.faqSection')}>
      <FieldsList<MultilingualFAQ> name="faqs">
        {(itemProps) => (
          <Stack gap="20px">
            <Stack
              direction="row"
              alignItems="baseline"
              justifyContent="space-between"
            >
              <Typography variant="h3">
                {t('benefits.faqIndex', { index: itemProps.index + 1 })}
              </Typography>
              <Stack direction="row" gap="10px">
                <OrderChangeButton
                  currentIndex={itemProps.index}
                  totalCount={itemProps.itemsCount}
                  move={itemProps.move}
                />
                <DeleteIconButton onClick={itemProps.remove} />
              </Stack>
            </Stack>
            <MultilingualFormField
              name={`${itemProps.prefix}.question`}
              label={t('form.question')}
              render="text"
              rules={{ required: true }}
              defaultValue={itemProps.field.question}
            />
            <MultilingualFormField
              name={`${itemProps.prefix}.answer`}
              label={t('form.answer')}
              render={{
                type: 'text',
                props: { multiline: true, minRows: 3, maxRows: 5 },
              }}
              rules={{ required: true }}
              defaultValue={itemProps.field.answer}
            />
          </Stack>
        )}
      </FieldsList>
    </Panel>
  );
};

export default FAQs;
