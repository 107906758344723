import { DependencyList, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  data: any;
  dependencies: DependencyList;
}

const FormReset = (props: Props) => {
  const { reset } = useFormContext();
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      reset(props.data);
    }
    // Dependencies are provided via properties and cannot be statically analyzed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, props.dependencies);
  return null;
};

export default FormReset;
