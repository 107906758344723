import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteInput,
  Button,
  DataLossPrompt,
  Footer,
  LabeledValue,
  Loader,
  NothingFoundAlert,
  Panel,
  PhoneInput,
  SelectInput,
  TextField,
} from 'melp-design/components';
import {
  AdministratorStatus,
  administratorStatuses,
  useAdministrator,
  useUpdateAdministrator,
} from 'store/administrators';
import { useRouteParams } from 'utils/useIdParam';
import { ROUTES } from 'config';
import { usePredefinedToasts } from 'utils/Toast';
import { useEmployees } from 'store/employees';
import { useDebouncedCallback } from 'use-debounce';

interface Values {
  status: AdministratorStatus;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  employee: string;
}

export const Information: FC = () => {
  const { t } = useTranslation();
  const { id: administratorId } = useRouteParams(ROUTES.administrators.details);
  const predefinedToasts = usePredefinedToasts();
  const [employeesSearchText, setEmployeesSearchText] = useState('');

  const { data: administrator, isLoading } = useAdministrator(administratorId);

  const handleEmployeesSearch = useDebouncedCallback(
    setEmployeesSearchText,
    300,
  );

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<Values>({
    defaultValues: {
      status: 'active',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      employee: '',
    },
  });

  const [email, phone, employee] = watch(['email', 'phone', 'employee']);

  const { data: employeeOptions } = useEmployees({
    employees: [employee].filter(Boolean),
    email,
    phone,
    name: employeesSearchText,
  });

  useEffect(() => {
    if (administrator) {
      reset({
        status: administrator.status,
        firstName: administrator.firstName,
        lastName: administrator.lastName,
        email: administrator.email,
        phone: administrator.phone,
        employee: administrator.employeeId,
      });
    }
  }, [administrator, reset]);

  const { mutate: updateAdministrator, isLoading: isUpdating } =
    useUpdateAdministrator();

  if (isLoading) return <Loader />;
  if (!administrator) return <NothingFoundAlert />;

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateAdministrator(
      {
        administratorId,
        data: {
          status: values.status,
          firstName: values.firstName,
          ...(administrator.role === 'hrProgrammatic'
            ? {}
            : {
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                employeeId: values.employee || null,
              }),
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <Panel title={t('common.contact_details')}>
            <Stack gap={2}>
              <Controller
                name="status"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      label={t('common.status')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={administratorStatuses.map((status) => ({
                        label: t(`status.${status}`),
                        value: status,
                      }))}
                      required
                      ref={ref}
                    />
                  );
                }}
              />

              <Controller
                name="firstName"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('form.first_name')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              {administrator.role === 'hrProgrammatic' ? (
                <LabeledValue
                  label={t('administrators.api_key')}
                  value={administrator.apiKeyAbbreviated}
                />
              ) : (
                <>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <TextField
                        label={t('form.last_name')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        ref={ref}
                      />
                    )}
                  />

                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <PhoneInput
                        label={t('form.mobile_number')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        ref={ref}
                      />
                    )}
                  />

                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <TextField
                        label={t('common.email')}
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        disabled
                        type="email"
                        ref={ref}
                      />
                    )}
                  />

                  <Controller
                    name="employee"
                    control={control}
                    render={({ field: { name, value, onChange, ref } }) => {
                      return (
                        <AutocompleteInput
                          label={t('administrators.employee.label')}
                          name={name}
                          value={value}
                          multiple={false}
                          onChange={onChange}
                          options={
                            employeeOptions?.items.map((employee) => ({
                              label: employee.fullName,
                              value: employee.id,
                            })) ?? []
                          }
                          onInputChange={handleEmployeesSearch}
                          infoMessage={t('administrators.employee.info')}
                          ref={ref}
                        />
                      );
                    }}
                  />
                </>
              )}
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              variant="primary"
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty} />
    </>
  );
};
