import {
  Form,
  FormConnector,
  Loader,
  NothingFoundAlert,
  Panel,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompanyLanguages } from 'state/Administrators';
import ContentLanguageFormField from '../../../../components/form/ContentLanguageFormField/ContentLanguageFormField';
import { useMe } from '../../../../state/Administrators';
import {
  useBenefit,
  useBenefitContent,
  useBenefitContentMutations,
} from '../../../../state/Benefits';
import { usePredefinedToasts } from '../../../../utils/Toast';
import { useIdParam } from '../../../../utils/useIdParam';
import BenefitDetailsFooter from '../common/BenefitDetailsFooter';
import FormReset from '../common/FormReset';
import { resolveBenefitPublishingStatus } from '../common/resolveBenefitPublishingStatus';
import { resolveBenefitValidityStatus } from '../common/resolveBenefitValidityStatus';
import Attachments from './Attachments';
import FAQs from './FAQs';
import GeneralInfo from './GeneralInfo';
import Links from './Links';
import { toFormData, toServerData } from './converters';
import { ContentFormData, ValidatedContentFormData } from './types';

const Content = () => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();
  const id = useIdParam();
  const { defaultLanguage } = useCompanyLanguages();
  const benefit = useBenefit(id);
  const benefitContent = useBenefitContent(id);
  const benefitContentMutations = useBenefitContentMutations(id);

  const { canEditBenefits } = useMe();

  if (benefitContent.loading || benefit.loading) return <Loader />;
  if (!benefitContent.data || !benefit.data) return <NothingFoundAlert />;

  const initialValues: ContentFormData = toFormData(
    benefitContent.data,
    defaultLanguage,
    benefit.data.name,
  );

  const publishingStatus = resolveBenefitPublishingStatus(benefit.data);
  const validityStatus = resolveBenefitValidityStatus(benefit.data);

  return (
    <Form defaultValues={initialValues} readOnly={!canEditBenefits}>
      <FormReset
        data={initialValues}
        dependencies={[publishingStatus, validityStatus]}
      />
      <Stack gap="20px">
        <Panel title={t('benefits.languagesSection')}>
          <ContentLanguageFormField />
        </Panel>
        <GeneralInfo />
        <Attachments />
        <Links />
        <FAQs />
      </Stack>
      <FormConnector>
        {({ handleSubmit, reset, formState: { isDirty } }) => {
          const handleValidationError = () => {
            predefinedToasts.error.validationGlobal();
          };

          const submitContent = async (data: ValidatedContentFormData) => {
            const requestData = toServerData(data);
            const response = await benefitContentMutations.update.execute(
              requestData,
            );
            predefinedToasts.success.updated();
            reset(toFormData(response, defaultLanguage));
          };

          const handleContentUpdate = handleSubmit(
            submitContent,
            handleValidationError,
          );

          const handleContentUpdateAndNext: ComponentProps<
            typeof BenefitDetailsFooter
          >['onSaveAndNext'] = (_, nextStep) => {
            handleSubmit(async (data: ValidatedContentFormData) => {
              await submitContent(data);
              if (benefit.data) {
                nextStep(benefit.data);
              }
            }, handleValidationError)();
          };

          return (
            <BenefitDetailsFooter
              hasChanges={isDirty}
              onSave={handleContentUpdate}
              onSaveAndNext={handleContentUpdateAndNext}
              saving={benefitContentMutations.update.loading}
            />
          );
        }}
      </FormConnector>
    </Form>
  );
};

export default Content;
