import { useTranslation } from 'react-i18next';
import { Benefit } from '../../../types/Benefits';

export const useInvestmentPeriodLabels = () => {
  const { t } = useTranslation();

  const resolveLabel = (benefit: Benefit) => {
    switch (benefit.investmentPeriod) {
      case 'once':
        return t('benefits.once');
      case 'monthly':
        return t('common.monthly');
      default:
        return benefit.investmentPeriod;
    }
  };

  return { resolveLabel };
};
