import { Box, MenuItem, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { formatLanguage } from 'utils/format';
import { AppLanguage, appLanguages } from 'types/general';
import { useUpdateAdministrator } from 'store/administrators';
import { usePredefinedToasts } from 'utils/Toast';
import { upper } from 'utils/general';
import { ReactComponent as Checkmark } from '../../../assets/icons/checkmark.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/chevron-left.svg';
import { useMe } from '../../../state/Administrators';
import { useClientAdministrator } from '../../../state/Clients';
import { StorageKeys } from '../../../types/LocalStorage';
import useStyles from './LanguageSwitcher.styles';

interface IProps {
  handleBack: () => void;
}

export default function LanguageSwitcher(props: IProps) {
  const { me, isMelpAdmin, isHrAdmin, updateMe } = useMe();
  const { updateAdministrator: updateClientAdministrator } =
    useClientAdministrator(me?.parentCompany.id, me?.id, true);
  const { handleBack } = props;
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { mutate: updateAdministrator, isLoading: isUpdating } =
    useUpdateAdministrator();

  const changeLanguage = async (language: AppLanguage) => {
    await i18n.changeLanguage(language);
    localStorage.setItem(StorageKeys.lang, language);
    const languageParam = upper(language);
    if (isHrAdmin && me) {
      updateAdministrator(
        {
          administratorId: me.id,
          data: { language: languageParam },
        },
        {
          onSuccess: () => {
            predefinedToasts.success.updated();
          },
        },
      );
      updateMe({ language: languageParam });
    }
    if (isMelpAdmin) {
      updateClientAdministrator({ language: languageParam }, false);
    }
    handleBack();
  };

  const languageMap = [...appLanguages].sort().map((language) => (
    <MenuItem
      classes={{ root: clsx(classes.vAlignCenter, classes.item) }}
      onClick={() => changeLanguage(language)}
      disabled={isUpdating}
      data-test={language}
      key={language}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        className={clsx({
          [classes.bold]: i18n.language === language,
        })}
      >
        {formatLanguage(language, language)}
      </Typography>
      {i18n.language === language && <Checkmark className={classes.icon} />}
    </MenuItem>
  ));

  return (
    <>
      <MenuItem
        onClick={handleBack}
        className={clsx(classes.backItem, classes.item)}
      >
        <Box marginRight="11px">
          <ChevronLeft />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {t('common.back')}
        </Typography>
      </MenuItem>
      {languageMap}
    </>
  );
}
