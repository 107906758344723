import { Button, Box, Tooltip } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { SortOrder } from './Types';

interface Props {
  value?: SortOrder;
  onChange?: (order: SortOrder) => void;
  type?: 'text' | 'numeric';
}

const SortButton = ({ value, onChange, type = 'text' }: Props) => {
  const { t } = useTranslation();
  const isActive = value;
  const orderToShow = value ?? 'asc';
  const arrowElement = <ArrowForward style={{ height: 14, width: 14 }} />;

  const resolveLabel = () => {
    if (type === 'text') {
      if (orderToShow === 'asc') {
        return <>A{arrowElement}Z</>;
      }
      if (orderToShow) {
        return <>Z{arrowElement}A</>;
      }
    }
    if (type === 'numeric') {
      if (orderToShow === 'asc') {
        return <>123</>;
      }
      if (orderToShow === 'desc') {
        return <>321</>;
      }
    }
  };

  const handleClick = () => {
    let orderToSet = orderToShow;
    if (value === 'asc') {
      orderToSet = 'desc';
    } else if (value === 'desc') {
      orderToSet = 'asc';
    }
    onChange?.(orderToSet);
  };

  return (
    <Box flexGrow={1} ml={-1}>
      <Tooltip title={t('common.sort')}>
        <Button
          size="small"
          color={isActive ? 'primary' : 'info'}
          onClick={handleClick}
          style={{ minWidth: 40, padding: 10 }}
        >
          {resolveLabel()}
        </Button>
      </Tooltip>
    </Box>
  );
};

export default SortButton;
