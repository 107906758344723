import { Svg, Path, Circle } from '@react-pdf/renderer';

interface Props {
  color: string;
}

const LinkPDFIcon = ({ color }: Props) => (
  <Svg width="9" height="9" viewBox="0 0 9 9">
    <Circle
      cx="4.50001"
      cy="4.49922"
      r="3.8"
      stroke={color}
      strokeWidth={0.5}
    />
    <Path
      d="M8.30001 4.49766C8.30001 4.91114 7.95832 5.35278 7.25199 5.70594C6.56062 6.05163 5.58799 6.27266 4.50001 6.27266C3.41203 6.27266 2.43941 6.05163 1.74803 5.70594C1.0417 5.35278 0.700012 4.91114 0.700012 4.49766C0.700012 4.08418 1.0417 3.64254 1.74803 3.28937C2.43941 2.94368 3.41203 2.72266 4.50001 2.72266C5.58799 2.72266 6.56062 2.94368 7.25199 3.28937C7.95832 3.64254 8.30001 4.08418 8.30001 4.49766Z"
      stroke={color}
      strokeWidth={0.5}
    />
    <Path
      d="M4.50004 0.700781C4.91352 0.700781 5.35516 1.04247 5.70832 1.7488C6.05401 2.44018 6.27504 3.4128 6.27504 4.50078C6.27504 5.58876 6.05401 6.56138 5.70832 7.25276C5.35516 7.95909 4.91352 8.30078 4.50004 8.30078C4.08656 8.30078 3.64492 7.95909 3.29175 7.25276C2.94606 6.56138 2.72504 5.58876 2.72504 4.50078C2.72504 3.4128 2.94606 2.44018 3.29175 1.7488C3.64492 1.04247 4.08656 0.700781 4.50004 0.700781Z"
      stroke={color}
      strokeWidth={0.5}
    />
  </Svg>
);

export default LinkPDFIcon;
