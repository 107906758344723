import {
  DeleteIconButton,
  FieldsList,
  MultilingualFormField,
  OrderChangeButton,
  Panel,
  Typography,
} from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Matchers } from '../../../../constants/form/form';
import { MultilingualLink } from './types';
import { ContentFormData } from './types';

const Links = () => {
  const { t } = useTranslation();

  const { watch } = useFormContext<ContentFormData>();
  const supportedLanguages = watch('supportedLanguages') ?? [];
  const multilingual = supportedLanguages.length > 1;

  return (
    <Panel title={t('benefits.linksSections')}>
      <FieldsList<MultilingualLink> name="links">
        {(itemProps) => (
          <Stack gap="20px">
            <Box
              display="grid"
              gridTemplateColumns="1fr auto"
              alignItems="start"
              gap="20px"
            >
              {multilingual && (
                <Typography variant="h3" alignSelf="center">
                  {t('benefits.linkIndex', { index: itemProps.index + 1 })}
                </Typography>
              )}
              <Stack direction="row" gap="10px" pt="5px">
                <OrderChangeButton
                  currentIndex={itemProps.index}
                  totalCount={itemProps.itemsCount}
                  move={itemProps.move}
                />
                <DeleteIconButton onClick={itemProps.remove} />
              </Stack>
              <Stack
                direction="row"
                gap="20px"
                gridColumn={multilingual ? '1/3' : '1/2'}
                gridRow={multilingual ? '2/3' : '1/2'}
              >
                <MultilingualFormField
                  name={`${itemProps.prefix}.name`}
                  label={t('form.link_title')}
                  render={{
                    type: 'text',
                    props: { placeholder: t('common.linkDescription') },
                  }}
                  rules={{ required: true }}
                  defaultValue={itemProps.field.name}
                />
                <MultilingualFormField
                  name={`${itemProps.prefix}.url`}
                  label={t('form.link_url')}
                  render={{
                    type: 'text',
                    props: { placeholder: t('common.linkPlaceholder') },
                  }}
                  rules={{
                    pattern: Matchers.URL,
                    required: true,
                  }}
                  defaultValue={itemProps.field.url}
                  hideLanguageLabels
                />
              </Stack>
            </Box>
          </Stack>
        )}
      </FieldsList>
    </Panel>
  );
};

export default Links;
