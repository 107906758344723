import { ReactNode } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'melp-design/components';
import { MarketplaceItem } from '../../../../types/MarketplaceItems';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/no-data.svg';

const useStyles = makeStyles((theme) => ({
  itemsList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 205px)',
    gap: theme.spacing(3),
  },
  itemsLoaderContainer: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
  },
  noDataMessageContainer: {
    minHeight: 200,
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    padding: theme.spacing(2),
    justifyItems: 'center',
  },
  noDataIcon: {
    width: 40,
    alignSelf: 'end',
  },
  noDataMessage: {
    alignSelf: 'start',
  },
}));

interface Props<T> {
  data?: T[];
  initialLoading?: boolean;
  count?: number;
  onNext: () => void;
  noDataMessage: string;
  renderItem: (item: T) => ReactNode;
}

const ItemsList = <T extends MarketplaceItem>({
  initialLoading = false,
  data,
  count,
  onNext,
  noDataMessage,
  renderItem,
}: Props<T>) => {
  const classes = useStyles();

  if (initialLoading) {
    return <Loader />;
  }

  if (!data?.length || !count) {
    return (
      <div className={classes.noDataMessageContainer}>
        <SearchIcon className={classes.noDataIcon} />
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.noDataMessage}
        >
          {noDataMessage}
        </Typography>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={onNext}
      hasMore={data.length < count}
      loader={
        <Box className={classes.itemsLoaderContainer}>
          <CircularProgress />
        </Box>
      }
      className={classes.itemsList}
      style={{ overflow: 'visible' }}
    >
      {data.map(renderItem)}
    </InfiniteScroll>
  );
};

export default ItemsList;
