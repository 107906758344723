import { useTranslation } from 'react-i18next';
import { benefitCategories } from 'store/benefits';
import {
  FilterButton,
  ShopItemsFilter,
  SuppliersFilter,
  SelectFilter,
} from 'components/filters';
import { APP } from 'config';
import { formatCountry } from 'utils/format';
import {
  MarketplaceItemsSort,
  shopItemCategories,
} from '../../../../types/MarketplaceItems';
import CurrencyRangeFilter from '../../../../components/filters/CurrencyRangeFilter';
import { SortOrder } from '../../../../components/filters/Types';
import { MarketplaceItemsFilters } from './Types';

const extractSortValue = (
  name: string,
  sort?: MarketplaceItemsSort,
): SortOrder | undefined => {
  if (!sort) {
    return undefined;
  }
  const startsWithMinus = sort.startsWith('-');
  const extractedName = startsWithMinus ? sort.slice(1) : sort;
  if (extractedName !== name) {
    return undefined;
  }
  return startsWithMinus ? 'desc' : 'asc';
};

interface Props {
  filters: MarketplaceItemsFilters;
  initialValues: MarketplaceItemsFilters;
  onFilterChange: <K extends keyof MarketplaceItemsFilters>(
    key: K,
    newValue: MarketplaceItemsFilters[K],
  ) => void;
  sort?: MarketplaceItemsSort;
  onSort?: (sort: MarketplaceItemsSort) => void;
  hideBenefitCategories?: boolean;
}

const ItemsFilters = ({
  filters,
  initialValues,
  onFilterChange,
  ...props
}: Props) => {
  const { t, i18n } = useTranslation();

  const countriesOptions = APP.countries.alpha3.map((alpha3) => ({
    key: alpha3,
    label: formatCountry(i18n.language, alpha3),
  }));

  return (
    <>
      <FilterButton
        label={t('marketplaceItems.supplier')}
        value={filters.supplierIds}
        initialValue={initialValues.supplierIds}
        onChange={(v) => onFilterChange('supplierIds', v)}
      >
        {({ value, applyFilter, clearFilter, close }) => (
          <SuppliersFilter
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            onCancel={close}
          />
        )}
      </FilterButton>
      <FilterButton
        label={t('benefits.country')}
        value={filters.countries}
        initialValue={initialValues.countries}
        onChange={(newValue) => onFilterChange('countries', newValue)}
      >
        {({ value, applyFilter, clearFilter, close }) => (
          <SelectFilter
            multiple
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            onCancel={close}
            options={countriesOptions}
          />
        )}
      </FilterButton>
      <FilterButton
        label={t('common.city.label')}
        value={filters.cities}
        initialValue={initialValues.cities}
        onChange={(newValue) => onFilterChange('cities', newValue)}
      >
        {({ value, applyFilter, clearFilter, close }) => (
          <SelectFilter
            multiple
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            onCancel={close}
            options={APP.cities.map((city) => ({
              key: city,
              label: city,
            }))}
          />
        )}
      </FilterButton>
      {!props.hideBenefitCategories && (
        <FilterButton
          label={t('marketplace.filtersBenefitCategory')}
          value={filters.benefitPlanTypes}
          initialValue={initialValues.benefitPlanTypes}
          onChange={(newValue) => {
            onFilterChange('benefitPlanTypes', newValue);
          }}
        >
          {({ value, applyFilter, clearFilter, close }) => (
            <SelectFilter
              multiple
              value={value}
              onApplyFilter={applyFilter}
              onClearFilter={clearFilter}
              onCancel={close}
              options={benefitCategories.map((category) => ({
                key: category,
                label: t(`menu.${category}`),
              }))}
            />
          )}
        </FilterButton>
      )}
      <FilterButton
        label={t('marketplaceOrders.itemName')}
        value={filters.itemIds}
        initialValue={initialValues.itemIds}
        onChange={(v) => onFilterChange('itemIds', v)}
      >
        {({ value, applyFilter, clearFilter, close }) => (
          <ShopItemsFilter
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            onCancel={close}
          />
        )}
      </FilterButton>
      <FilterButton
        label={t('marketplace.filtersItemCategory')}
        value={filters.categories}
        initialValue={initialValues.categories}
        onChange={(newValue) => {
          onFilterChange('categories', newValue);
        }}
      >
        {({ value, applyFilter, clearFilter, close }) => (
          <SelectFilter
            multiple
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            onCancel={close}
            options={shopItemCategories.map((category) => ({
              key: category,
              label: t(`marketplaceItems.itemCategory-${category}`),
            }))}
          />
        )}
      </FilterButton>
      <FilterButton
        label={t('marketplace.filtersPriceRange')}
        value={filters.price}
        initialValue={initialValues.price}
        onChange={(newValue) => onFilterChange('price', newValue)}
      >
        {({ value, applyFilter, clearFilter, close }) => (
          <CurrencyRangeFilter
            value={value}
            onApplyFilter={applyFilter}
            onClearFilter={clearFilter}
            onCancel={close}
            sortValue={extractSortValue('price', props.sort)}
            onSort={(order) =>
              props.onSort?.(order === 'asc' ? 'price' : '-price')
            }
          />
        )}
      </FilterButton>
    </>
  );
};

export default ItemsFilters;
