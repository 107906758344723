import {
  IBaseEmployee,
  IEmployee,
  IEmployeeExpense,
  IEmployeeWalletBenefit,
  IEmployeeWalletRecognitions,
} from 'types/income';
import { converters as tableConverters } from 'store/table';
import {
  Expense,
  convertExpenseSource,
  convertExpenseType,
} from 'store/expenses';
import { convertEmbeddedCustomCategories } from 'store/custom-categories';
import { loaders } from './employees.loaders';
import {
  BaseEmployee,
  Employee,
  EmployeeJobTitle,
  EmployeeWalletBenefit,
  EmployeeWalletRecognitions,
} from './employees.types';

const convertBaseEmployee = (employee: IBaseEmployee): BaseEmployee => {
  return {
    id: employee.id,
    firstName: employee.firstName,
    lastName: employee.lastName,
    fullName: [employee.firstName, employee.lastName].filter(Boolean).join(' '),
    email: employee.email ?? '',
    phone: employee.phone ?? '',
    employmentStatus: employee.employmentStatus,
    birthday: employee.birthDate || null,
    hiredAt: employee.employmentDate,
    isAppUser: employee.appUser,
    customCategories: convertEmbeddedCustomCategories(
      employee.customCategories ?? [],
    ),
  };
};

const convertEmployee = (employee: IEmployee): Employee => {
  return {
    id: employee.id,
    firstName: employee.firstName,
    lastName: employee.lastName,
    fullName: [employee.firstName, employee.lastName].filter(Boolean).join(' '),
    email: employee.email ?? '',
    phone: employee.phone ?? '',
    dateOfBirth: employee.dateOfBirth || null,
    parentCompanyId: employee.parentCompanyId,
    capacity: employee.capacity ?? 'fullTime',
    companyId: employee.companyId,
    departmentId: employee.departmentId ?? '',
    employeeId: employee.employeeId ?? '',
    employeeToCategories: employee.employeeToCategories,
    employmentDate: employee.employmentDate,
    employmentDateTZ: employee.employmentDateTZ,
    employmentDuration: employee.employmentDuration,
    employmentTime: employee.employmentTime,
    endDate: employee.endDate,
    endDateTZ: employee.endDateTZ,
    startDate: employee.startDate,
    startDateTZ: employee.startDateTZ,
    endTime: employee.endTime,
    startTime: employee.startTime,
    hireDate: employee.hireDate,
    internalComment: employee.internalComment ?? '',
    jobTitle: employee.jobTitle ?? '',
    language: employee.language,
    permission: employee.permission,
    personalCode: employee.personalCode ?? '',
    status: employee.status,
    temporaryEmployment: employee.temporaryEmployment,
    userId: employee.userId,
    user: employee.user
      ? {
          lastActionDate: employee.user.lastActionDate,
        }
      : null,
  };
};

const convertEmployees = (employees: IBaseEmployee[]): BaseEmployee[] => {
  return employees.map(convertBaseEmployee);
};

const convertExpense = (expense: IEmployeeExpense): Expense => {
  return {
    id: expense.id,
    date: expense.date,
    description: expense.description,
    employee: null,
    categories: expense.itemCategories,
    amount: expense.expense,
    source: convertExpenseSource(
      // @ts-expect-error empty ids are filtered out
      expense.sourceBenefits?.filter((benefit) => !!benefit.id) ?? [],
      !!expense.recognitionReward,
    ),
    status: expense.status,
    type: convertExpenseType(expense.type),
  };
};

const convertExpenses = (expenses: IEmployeeExpense[]): Expense[] => {
  return expenses.map(convertExpense);
};

const convertEmployeeWalletBenefit = (
  benefit: IEmployeeWalletBenefit,
): EmployeeWalletBenefit => {
  return {
    id: benefit.id,
    name: benefit.name,
    status: benefit.status,
    assignmentStatus: benefit.assignmentStatus,
    investment: benefit.investmentAmount ?? 0,
    expenses: benefit.expenses,
    remaining: benefit.remaining,
  };
};

const convertEmployeeWalletBenefits = (
  benefits: IEmployeeWalletBenefit[],
): EmployeeWalletBenefit[] => {
  return benefits.map(convertEmployeeWalletBenefit);
};

const convertWalletRecognitions = (
  recognitions: IEmployeeWalletRecognitions,
): EmployeeWalletRecognitions => {
  return {
    availableCurrency: recognitions.availableCurrency,
    availablePoints: recognitions.availablePoints,
    spentCurrency: recognitions.spentCurrency,
    spentPoints: recognitions.spentPoints,
    totalCurrency: recognitions.totalCurrency,
    totalPoints: recognitions.totalPoints,
    expiredPoints: recognitions.expiredPoints,
    expiredCurrency: recognitions.expiredCurrency,
  };
};

const convertEmployeeJobTitle = (jobTitle: string): EmployeeJobTitle => {
  return {
    name: jobTitle,
  };
};

const convertEmployeeJobTitles = (jobTitles: string[]): EmployeeJobTitle[] => {
  return jobTitles.map(convertEmployeeJobTitle);
};

export const converters = {
  getEmployees: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployees>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployees(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getEmployee: ({ data }: Awaited<ReturnType<typeof loaders.getEmployee>>) => {
    return convertEmployee(data);
  },
  getExpenses: ({ data }: Awaited<ReturnType<typeof loaders.getExpenses>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertExpenses(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getWalletBenefits: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getWalletBenefits>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeeWalletBenefits(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getWalletRecognitions: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getWalletRecognitions>>) => {
    return convertWalletRecognitions(data);
  },
  getJobTitles: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getJobTitles>>) => {
    return convertEmployeeJobTitles(data);
  },
};
