import { FC, useMemo } from 'react';
import {
  Button,
  Link,
  ListTotal,
  TableToolbar,
  TableWrapper,
  Table,
  Typography,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { useIdParam } from 'utils/useIdParam';
import { APP, ROUTES } from 'config';
import { useModalContext } from 'store/modal';
import { Endpoints } from 'api/constants';
import {
  CompaniesFilter,
  CustomCategoryValuesFilter,
  DepartmentsFilter,
  EmployeesFilter,
  EmployeesGroupsFilter,
  FilterButton,
  FiltersInModal,
  SelectFilter,
} from 'components/filters';
import BenefitDetailsFooter from 'containers/BenefitsOld/BenefitDetails/common/BenefitDetailsFooter';
import { useSearchParams } from 'utils/navigation';
import {
  COLOR_BY_STATUS,
  assignmentStatuses,
  employeesByBenefitFiltersSchema,
  useAssignEmployeesToBenefit,
  useEmployeesByBenefitAssignments,
} from 'store/assignments';
import {
  AssignmentDateSelectModal,
  EmployeeBenefitAssignmentHistory,
} from 'containers/assignments';
import { CustomCategorySelectModal } from './atoms';

export const BenefitEmployeesAssignments: FC = () => {
  const { t } = useTranslation();
  const benefitId = useIdParam();
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    employeesByBenefitFiltersSchema,
  );
  const { selectedRowIds, setSelectedRowIds } = Table.useSelectedRows();

  const { data: assignments, isLoading } = useEmployeesByBenefitAssignments(
    benefitId,
    filters,
  );

  const { mutate: assignEmployeesToBenefit, isLoading: isAssigning } =
    useAssignEmployeesToBenefit();

  const selectedCategory = useMemo(() => {
    return (
      assignments?.customCategories.find(
        (c) => c.id === filters.customCategory,
      ) ?? null
    );
  }, [assignments, filters.customCategory]);

  return (
    <>
      <TableWrapper>
        <TableToolbar sticky>
          <Stack gap="10px">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap="15px"
            >
              <Button
                label={t('employees.choose_custom_column')}
                onClick={async () => {
                  const response = await openModal({
                    component: CustomCategorySelectModal,
                    props: {
                      options: assignments?.customCategories ?? [],
                      selected: filters.customCategory,
                    },
                  });

                  if (response.action === 'CONFIRM') {
                    navigate({
                      params: {
                        customCategory: response.category,
                        customCategoryValue: [],
                      },
                    });
                  }
                }}
              />
            </Stack>

            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
            >
              <ListTotal total={assignments?.items.length} />

              <Stack direction="row" alignItems="center" gap="10px">
                <FiltersInModal
                  value={filters}
                  initialValues={employeesByBenefitFiltersSchema.parse({})}
                  onChange={(v) => navigate({ params: v })}
                  onChangeCallback={() => setSelectedRowIds({})}
                >
                  {({ value, initialValues, setFilter }) => (
                    <>
                      <FilterButton
                        label={t('entity_type.employee')}
                        value={value.employees}
                        initialValue={initialValues.employees}
                        onChange={(v) => setFilter('employees', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <EmployeesFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('entity_type.employeeGroup')}
                        value={value.employeeGroups}
                        initialValue={initialValues.employeeGroups}
                        onChange={(v) => setFilter('employeeGroups', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <EmployeesGroupsFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('employees.company')}
                        value={value.companies}
                        initialValue={initialValues.companies}
                        onChange={(v) => setFilter('companies', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <CompaniesFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            multiple
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('employees.department')}
                        value={value.departments}
                        initialValue={initialValues.departments}
                        onChange={(v) => setFilter('departments', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <DepartmentsFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            multiple
                          />
                        )}
                      </FilterButton>

                      {filters.customCategory && selectedCategory ? (
                        <FilterButton
                          label={selectedCategory.name}
                          value={value.customCategoryValue}
                          initialValue={initialValues.customCategoryValue}
                          onChange={(v) => setFilter('customCategoryValue', v)}
                        >
                          {({
                            value: filterValue,
                            applyFilter,
                            clearFilter,
                            close,
                          }) => (
                            <CustomCategoryValuesFilter
                              value={filterValue}
                              onApplyFilter={applyFilter}
                              onClearFilter={clearFilter}
                              onCancel={close}
                              customCategoryId={value.customCategory}
                            />
                          )}
                        </FilterButton>
                      ) : null}

                      <FilterButton
                        label={t('common.current_status')}
                        value={value.assignmentStatus}
                        initialValue={initialValues.assignmentStatus}
                        onChange={(v) => setFilter('assignmentStatus', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <SelectFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            options={assignmentStatuses.map((status) => ({
                              key: status,
                              label: t(`status.${status}`),
                            }))}
                            searchable={false}
                            multiple={true}
                          />
                        )}
                      </FilterButton>
                    </>
                  )}
                </FiltersInModal>
              </Stack>
            </Stack>
          </Stack>
        </TableToolbar>

        <Table
          data={assignments?.items ?? []}
          isLoading={isLoading}
          columns={[
            {
              id: 'name',
              header: t('common.full_name'),
              cell: ({ row: { original: assignment } }) => {
                return (
                  <Link
                    label={assignment.fullName}
                    to={ROUTES.employees.details.replace(':id', assignment.id)}
                  />
                );
              },
              meta: {
                sort: true,
              },
            },
            {
              id: 'customCategory',
              header: selectedCategory?.name,
              cell: ({ row: { original: assignment } }) => {
                return (
                  assignment.customCategories.find(
                    (c) => c.id === selectedCategory?.id,
                  )?.value.name ?? ''
                );
              },
            },
            {
              id: 'assignmentStatus',
              header: t('alternatives.assignmentStatus'),
              meta: {
                align: 'center',
              },
              cell: ({ row: { original: assignment } }) => {
                return (
                  <Stack
                    flexDirection="row"
                    gap={1.5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color={COLOR_BY_STATUS[assignment.assignmentStatus]}
                    >
                      {t(`status.${assignment.assignmentStatus}`)}
                    </Typography>

                    {assignment.assignmentCount ? (
                      <Button
                        variant="neutral-outline"
                        size="sm"
                        icon={AccessTime}
                        onClick={() => {
                          openModal({
                            component: EmployeeBenefitAssignmentHistory,
                            props: {
                              benefitId,
                              employeeId: assignment.id,
                            },
                            size: 'lg',
                          });
                        }}
                        tooltip={t('permissions.historyModule')}
                      />
                    ) : null}
                  </Stack>
                );
              },
            },
          ]}
          pagination={
            assignments && {
              count: assignments.total,
              page: assignments.page,
              pageSize: assignments.pageSize,
              pageSizeOptions: APP.pagination.defaults.pageSize,
              onChange: (page, pageSize) => {
                navigate({ params: { page, pageSize } });
              },
            }
          }
          rows={{
            inactive: ({ original: assignment }) =>
              assignment.assignmentStatus === 'off',
          }}
          selection={{
            value: selectedRowIds,
            onChange: setSelectedRowIds,
            actions: assignmentStatuses.map((status) => ({
              label: t(`status.${status}`),
              onClick: async () => {
                const response = await openModal({
                  component: AssignmentDateSelectModal,
                });

                if (response.action === 'CONFIRM') {
                  assignEmployeesToBenefit(
                    {
                      benefitId,
                      ...(status === 'on'
                        ? {
                            action: 'create',
                            data: {
                              employeeIds: Object.keys(selectedRowIds),
                              ...(response.isNow
                                ? { fromNow: true }
                                : { from: response.date }),
                            },
                          }
                        : {
                            action: 'update',
                            data: {
                              employeeIds: Object.keys(selectedRowIds),
                              ...(response.isNow
                                ? { toNow: true }
                                : { to: response.date }),
                            },
                          }),
                    },
                    {
                      onSuccess: () => {
                        // TODO: replace this revalidation when benefit details is refactored with react-query
                        mutate(
                          Endpoints.clientAdmin.benefitPlans.byId(benefitId)
                            .root,
                        );
                        setSelectedRowIds({});
                      },
                    },
                  );
                }
              },
            })),
            isLoading: isAssigning,
          }}
        />
      </TableWrapper>

      <BenefitDetailsFooter saving={isAssigning} />
    </>
  );
};
