import { FC } from 'react';
import {
  Button,
  Link,
  ListPageHeader,
  ListTotal,
  Table,
  Typography,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { APP, ROUTES } from 'config';
import { useSearchParams } from 'utils/navigation';
import {
  adminBrandProviders,
  adminBrandsFiltersSchema,
  adminBrandStatuses,
  adminBrandVariants,
  COLOR_BY_STATUS,
  useAdminBrands,
  useExportToXls,
} from 'store/admin-brands';
import { useModalContext } from 'store/modal';
import { CreateBrandModal } from 'containers/admin';
import { formatCountry, formatList } from 'utils/format';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import {
  AdminBrandsFilter,
  FilterButton,
  Filters,
  SelectFilter,
} from 'components/filters';
import { shopItemCategories } from 'types/MarketplaceItems';
import TextFilter from 'components/filters/TextFilter';
import { LanguageSelectModal } from 'components/modals';

export const AdminBrandsList: FC = () => {
  const { t, i18n } = useTranslation();
  const { openModal } = useModalContext();

  const { searchParams: filters, navigate } = useSearchParams(
    adminBrandsFiltersSchema,
  );

  const { data: brands, isLoading } = useAdminBrands(filters);

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();

  return (
    <>
      <ListPageHeader
        title={t('brands.title')}
        sideContent={
          <Button
            label={t('brands.actions.create')}
            onClick={() => {
              openModal({
                component: CreateBrandModal,
              });
            }}
          />
        }
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={brands?.total} />

            <Stack direction="row" alignItems="center" gap="10px">
              <Filters
                value={filters}
                initialValues={adminBrandsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('common.status')}
                      value={value.status}
                      initialValue={initialValues.status}
                      onChange={(v) => setFilter('status', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="status"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={adminBrandStatuses.map((status) => ({
                            key: status,
                            label: t(`status.${status}`),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('brands.title')}
                      value={value.brands}
                      initialValue={initialValues.brands}
                      onChange={(v) => setFilter('brands', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <AdminBrandsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('integrations.type')}
                      value={value.variant}
                      initialValue={initialValues.variant}
                      onChange={(v) => setFilter('variant', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="variant"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={adminBrandVariants.map((variant) => ({
                            key: variant,
                            label: t(`brands.variants.${variant}`),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('brands.primary_category.label')}
                      value={value.primaryCategories}
                      initialValue={initialValues.primaryCategories}
                      onChange={(v) => setFilter('primaryCategories', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="primaryCategories"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={shopItemCategories.map((category) => ({
                            key: category,
                            label: t(
                              `marketplaceItems.itemCategory-${category}`,
                            ),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('providers.title')}
                      value={value.providers}
                      initialValue={initialValues.providers}
                      onChange={(v) => setFilter('providers', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="providers"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple={true}
                          searchable={false}
                          options={adminBrandProviders.map((provider) => ({
                            key: provider,
                            label: provider,
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('benefits.country')}
                      value={value.countries}
                      initialValue={initialValues.countries}
                      onChange={(v) => setFilter('countries', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="countries"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={APP.countries.alpha3.map((alpha3) => ({
                            key: alpha3,
                            label: formatCountry(i18n.language, alpha3),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <TextFilter
                      value={value.search}
                      onApplyFilter={(v) => setFilter('search', v)}
                      placeholder={t('common.search')}
                    />
                  </>
                )}
              </Filters>

              <Divider orientation="vertical" variant="middle" flexItem />

              <DownloadExcelButton
                listLength={brands?.total ?? 0}
                exportToExcel={async () => {
                  const response = await openModal({
                    component: LanguageSelectModal,
                  });

                  if (response.action === 'CONFIRM') {
                    exportToXls({
                      filters: {
                        ...filters,
                        languages: response.languages,
                      },
                    });
                  }
                }}
                loading={isExportingToXls}
              />
            </Stack>
          </Stack>
        }
      />

      <Table
        data={brands?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'sequence',
            header: t('integrations.hiBobServiceUserId'),
            cell: ({ row: { original: brand } }) => (
              <Link
                label={brand.sequence}
                to={ROUTES.admin.brands.settings.replace(':id', brand.id)}
              />
            ),
            meta: { sort: true },
          },
          {
            id: 'name',
            header: t('common.name'),
            cell: ({ row: { original: brand } }) => (
              <Link
                label={brand.name}
                to={ROUTES.admin.brands.settings.replace(':id', brand.id)}
              />
            ),
            meta: { sort: true },
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: brand } }) => (
              <Typography color={COLOR_BY_STATUS[brand.status]}>
                {t(`status.${brand.status}`)}
              </Typography>
            ),
            meta: { sort: true },
          },
          {
            id: 'variant',
            header: t('integrations.type'),
            cell: ({ row: { original: brand } }) =>
              t(`brands.variants.${brand.variant}`),
          },
          {
            id: 'primaryCategory',
            header: t('brands.primary_category.label'),
            cell: ({ row: { original: brand } }) =>
              brand.primaryCategory
                ? t(`marketplaceItems.itemCategory-${brand.primaryCategory}`)
                : null,
          },
          {
            id: 'priority_index',
            header: t('common.priority.label'),
            cell: ({ row: { original: brand } }) => brand.priorityIndex,
            meta: { align: 'right', sort: true },
          },
          {
            id: 'enabledCards',
            header: t('menu.items'),
            cell: ({ row: { original: brand } }) => brand.enabledCards,
            meta: { align: 'right' },
          },
          {
            id: 'providers',
            header: t('providers.title'),
            cell: ({ row: { original: brand } }) =>
              formatList(i18n.language, brand.providers),
          },
          {
            id: 'countries',
            header: t('marketplaceItems.countries'),
            cell: ({ row: { original: brand } }) =>
              formatList(
                i18n.language,
                brand.countries.map((country) =>
                  formatCountry(i18n.language, country),
                ),
              ),
          },
          {
            id: 'discount_count',
            header: t('discounts.title'),
            cell: ({ row: { original: brand } }) => brand.discountsCount,
            meta: { align: 'right', sort: true },
          },
          {
            id: 'order_count',
            header: t('menu.orders'),
            cell: ({ row: { original: brand } }) => brand.ordersCount,
            meta: { align: 'right', sort: true },
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          brands && {
            count: brands.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </>
  );
};
