import { FC } from 'react';
import { AdminBrandCards } from 'containers/admin';
import { AdminBrandLayout } from 'pages/atoms';

const AdminBrandSettingsPage: FC = () => {
  return (
    <AdminBrandLayout>
      <AdminBrandCards />
    </AdminBrandLayout>
  );
};

export default AdminBrandSettingsPage;
