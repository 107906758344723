import { FormDialog, FormDialogProps, FormField } from 'melp-design/components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useBenefit, useBenefitMutations } from '../../../../state/Benefits';
import { BenefitDeactivationRequest } from '../../../../types/Benefits';
import { usePredefinedToasts } from '../../../../utils/Toast';
import { useIdParam } from '../../../../utils/useIdParam';

interface Props extends Pick<FormDialogProps, 'open' | 'onCancel'> {}

const UnpublishDialog = (props: Props) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const id = useIdParam();
  const benefit = useBenefit(id);
  const benefitMutations = useBenefitMutations(id);

  const handleSubmit = async (data: BenefitDeactivationRequest) => {
    await benefitMutations.deactivate.execute(data);
    props.onCancel();
    predefinedToasts.success.updated();
  };

  const activationDate = moment(benefit.data?.activatedAt);
  const publishDate = moment(benefit.data?.publishedAt);

  const getStartDate = () => {
    if (activationDate.isValid() && publishDate.isValid()) {
      return moment.max(activationDate, publishDate);
    }

    if (activationDate.isValid()) {
      return activationDate;
    }

    if (publishDate.isValid()) {
      return publishDate;
    }

    return undefined;
  };

  return (
    <FormDialog
      onSubmit={handleSubmit}
      loading={benefitMutations.deactivate.loading}
      submitLabel={t('benefits.unpublish')}
      {...props}
    >
      <FormField
        name="deactivatedAt"
        render={{
          type: 'dateTime',
          props: {
            minDateTime: getStartDate(),
            showTimeOffset: true,
          },
        }}
        label={t('benefits.unpublishFrom')}
        rules={{
          required: true,
          validate: (value: string | null) => {
            if (!value) {
              return;
            }

            if (
              activationDate.isValid() &&
              moment(value).isBefore(activationDate)
            ) {
              return t('benefits.deactivationDateBeforeActivationDate');
            }

            if (publishDate.isValid() && moment(value).isBefore(publishDate)) {
              return t('errors.deactivation_date_before_publish_date');
            }
          },
        }}
        defaultValue={moment().toISOString(true)}
      />
    </FormDialog>
  );
};

export default UnpublishDialog;
