import {
  ButtonOld,
  Dialog,
  DialogContent,
  SuccessDialog,
  Typography,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config';
import AddBenefits from '../../../../components/common/AddBenefits/AddBenefits';
import { useDialog } from '../../../../utils/Dialog';
import PublishDialog from './PublishDialog';

interface Props extends ComponentProps<typeof SuccessDialog> {}

const BenefitWizardCompletionSuccessDialog = ({ ...props }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const publishDialog = useDialog();

  return (
    <>
      <Dialog {...props}>
        <DialogContent>
          <Typography variant="h2" align="center" pt="25px">
            {t('benefits.benefitCreatedSuccessfully')}
          </Typography>
          <Typography variant="p1" align="center" mt="20px" mb="15px">
            {t('benefits.publishPrompt')}
          </Typography>
          <ButtonOld
            fullWidth
            size="large"
            variant="contained"
            color="success"
            textColor="white"
            onClick={publishDialog.openDialog}
          >
            {t('buttons.publish')}
          </ButtonOld>
          <Divider sx={{ mt: '20px', mb: '10px' }}>
            <Typography color="textSecondary">{t('common.or')}</Typography>
          </Divider>
          <Stack width="100%" gap="10px">
            <AddBenefits
              variant="secondary"
              label={t('benefits.createAnotherBenefit')}
            />
            <ButtonOld onClick={() => history.push(ROUTES.benefits.list)}>
              {t('benefits.goToBenefitsList')}
            </ButtonOld>
          </Stack>
        </DialogContent>
      </Dialog>
      <PublishDialog
        open={publishDialog.open}
        onCancel={publishDialog.closeDialog}
        onComplete={props.onClose}
      />
    </>
  );
};

export default BenefitWizardCompletionSuccessDialog;
