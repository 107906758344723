import useSWR from 'swr';
import { makeRequest } from '../api/api';
import { Endpoints } from '../api/constants';
import {
  AutoAssignmentFilterResponse,
  BenefitMarketplaceItemsResponse,
  MarketplaceItemsFilterParams,
  MarketplaceItemsParams,
  MarketplaceItemsResponse,
  MarketplaceItemsWithSelectionResponse,
} from '../types/MarketplaceItems';
import { useCustomSwrMutation } from '../utils/Api';
import {
  convertToFilterQueryParams,
  convertToQueryParams,
} from '../utils/Filters';
import { useLoading } from '../utils/Loading';

export const useLazyBenefitMarketplaceItems = (benefitId?: string) => {
  const fetchItems = async (params?: MarketplaceItemsParams) => {
    const query = convertToQueryParams(params);
    const { root, benefitItems } = Endpoints.clientAdmin.v2.marketplaceItems;
    const url = benefitId ? benefitItems(benefitId) : root;
    const response = await makeRequest<BenefitMarketplaceItemsResponse>(
      'get',
      `${url}${query}`,
    );
    return response.data;
  };
  return { fetchItems };
};

export const useLazyBenefitSelectedMarketplaceItems = (benefitId?: string) => {
  const fetchItems = async (params?: MarketplaceItemsParams) => {
    const query = convertToQueryParams(params);
    if (!benefitId) {
      throw new Error('Benefit id is missing');
    }
    const response = await makeRequest<MarketplaceItemsResponse>(
      'get',
      `${
        Endpoints.clientAdmin.v2.benefitPlans.byId(benefitId).marketplaceItems
          .root
      }${query}`,
    );
    return response.data;
  };
  return { fetchItems };
};

export const useBenefitMarketplaceItemActions = (
  benefitId: string,
  itemId: string,
) => {
  const url = Endpoints.clientAdmin.benefitPlans
    .byId(benefitId)
    .marketplaceItems.byId(itemId).root;
  const selectItem = useCustomSwrMutation(url, 'post');
  const removeItem = useCustomSwrMutation(url, 'delete');

  return {
    selectItem,
    removeItem,
  };
};

export const useBenefitBulkLink = (benefitId: string) => {
  const url =
    Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
      .bulkLink;
  const execute = async (params: MarketplaceItemsFilterParams) => {
    const query = convertToFilterQueryParams(params);
    const response = await makeRequest('post', `${url}${query}`);
    return response.data;
  };
  return { execute };
};

export const useBenefitBulkUnlink = (benefitId: string) => {
  const url =
    Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
      .bulkUnlink;
  const execute = async (params: MarketplaceItemsFilterParams) => {
    const query = convertToFilterQueryParams(params);
    const response = await makeRequest<boolean>('post', `${url}${query}`);
    return response.data;
  };
  return { execute };
};

export const useLazyMarketplaceItems = () => {
  const requestState = useLoading();
  const fetchItems = async (params?: MarketplaceItemsParams) => {
    requestState.startLoading();
    const query = convertToQueryParams(params);
    try {
      const response = await makeRequest<MarketplaceItemsWithSelectionResponse>(
        'get',
        `${Endpoints.clientAdmin.v2.marketplaceItems.root}${query}`,
      );
      return response.data;
    } finally {
      requestState.stopLoading();
    }
  };
  return { fetchItems, loading: requestState.loading };
};

export const useLazySelectedMarketplaceItems = () => {
  const fetchItems = async (params?: MarketplaceItemsParams) => {
    const query = convertToQueryParams(params);
    const response = await makeRequest<MarketplaceItemsResponse>(
      'get',
      `${Endpoints.clientAdmin.v2.marketplaceItems.selection.root}${query}`,
    );
    return response.data;
  };
  return { fetchItems };
};

export const useMarketplaceItemSelectionActions = (itemId: string) => {
  const url =
    Endpoints.clientAdmin.marketplaceItems.selection.marketplaceItem(itemId);
  const selectItem = useCustomSwrMutation(url, 'post');
  const removeItem = useCustomSwrMutation(url, 'delete');

  return {
    selectItem,
    removeItem,
  };
};

export const useSelectionsActions = () => {
  const bulkSelection = async (
    params: MarketplaceItemsFilterParams,
    method: 'post' | 'delete',
  ) => {
    const url = Endpoints.clientAdmin.marketplaceItems.selection.bulk;
    const query = convertToFilterQueryParams(params);
    const response = await makeRequest(method, `${url}${query}`);
    return response.data;
  };

  const bulkSelectionAdd = async (params: MarketplaceItemsFilterParams) => {
    return bulkSelection(params, 'post');
  };

  const bulkSelectionRemove = async (params: MarketplaceItemsFilterParams) => {
    return bulkSelection(params, 'delete');
  };

  const linkSelections = async (benefitId: string) => {
    const response = await makeRequest(
      'post',
      Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
        .bulkLinkSelection,
    );
    return response.data;
  };
  return { bulkSelectionAdd, bulkSelectionRemove, linkSelections };
};

export const useAssignmentFilter = (benefitId: string) => {
  const url =
    Endpoints.clientAdmin.benefitPlans.byId(benefitId).marketplaceItems
      .assignmentFilter.root;
  const assignmentFilter = useSWR<AutoAssignmentFilterResponse | null>(url, {
    onErrorRetry: (error, _key, config, revalidate, { retryCount = 0 }) => {
      // Ignore the error when assignment filter was not found
      if (error.response?.status === 404) return;

      // Retry otherwise (if configured)
      if (!config.errorRetryCount) return;
      if (retryCount >= config.errorRetryCount) return;
      setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
    },
  });
  const getOptions = (data?: AutoAssignmentFilterResponse | null) => ({
    onSuccess: () => assignmentFilter.mutate(data),
  });
  const create = useCustomSwrMutation(url, 'post', getOptions());
  const update = useCustomSwrMutation(url, 'put', getOptions());
  const remove = useCustomSwrMutation(url, 'delete', getOptions(null));
  return {
    data: assignmentFilter.data,
    loading: !assignmentFilter.data && !assignmentFilter.error,
    create,
    update,
    remove,
    actionInProgress: create.loading || update.loading || remove.loading,
  };
};
