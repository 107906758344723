import { Svg, Path, Line } from '@react-pdf/renderer';

interface Props {
  color: string;
}

const DownloadPDFIcon = ({ color }: Props) => (
  <Svg width="12" height="12" viewBox="0 0 12 12">
    <Line x1="0" y1="11.5" x2="12" y2="11.5" stroke={color} />
    <Path d="M6 0L6 7.71429" stroke={color} />
    <Path d="M2.0769 4.28516L5.99319 7.92171L9.90947 4.28516" stroke={color} />
  </Svg>
);

export default DownloadPDFIcon;
