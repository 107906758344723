import moment from 'moment';
import { Benefit } from '../../../../types/Benefits';

type BenefitValidityStatus = 'draft' | 'inactive' | 'active' | 'expired';

/**
 * Resolves the validity status of a benefit.
 * @param benefit - The benefit object to check validity status for.
 * @returns The validity status of the
 *  benefit. If the validity status cannot be determined, undefined is returned.
 */
export const resolveBenefitValidityStatus = (
  benefit: Benefit,
): BenefitValidityStatus => {
  if (benefit.isDraft) {
    return 'draft';
  }

  const currentTime = moment();

  if (currentTime.isBefore(benefit.activatedAt)) {
    return 'inactive';
  }

  if (currentTime.isSameOrAfter(benefit.deactivatedAt)) {
    return 'expired';
  }

  return 'active';
};
