import { FC } from 'react';
import {
  Button,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useBenefitCategoriesContent,
  useUpdateCategoryContent,
} from 'store/benefits';
import { CategoryContentModal } from 'containers/benefits';
import { useModalContext } from 'store/modal';
import { usePredefinedToasts } from 'utils/Toast';
import { formatLanguage } from 'utils/format';

export const BenefitCategoriesList: FC = () => {
  const { t, i18n } = useTranslation();
  const { openModal } = useModalContext();
  const predefinedToasts = usePredefinedToasts();

  const { data: categories, isLoading } = useBenefitCategoriesContent();

  const { mutate: updateCategoryContent, isLoading: isUpdating } =
    useUpdateCategoryContent();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap={1}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <ListTotal total={categories?.total} />
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={categories?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('benefits.categories.default_label'),
            cell: ({ row: { original: category } }) =>
              t(`menu.${category.category}`),
          },
          {
            id: 'labels',
            header: t('benefits.categories.label'),
            cell: ({ row: { original: category } }) =>
              category.translations.map((translation) => (
                <Typography key={translation.language}>
                  {[
                    formatLanguage(i18n.language, translation.language),
                    translation.label,
                  ].join(': ')}
                </Typography>
              )),
          },
          {
            id: 'actions',
            meta: { align: 'right' },
            cell: ({ row: { original: category } }) => {
              return (
                <Button
                  label={t('actions.rename')}
                  variant="neutral-outline"
                  size="sm"
                  onClick={async () => {
                    const response = await openModal({
                      component: CategoryContentModal,
                      props: {
                        category: category.category,
                        initialTranslations: category.translations,
                      },
                    });

                    if (response.action === 'CONFIRM') {
                      updateCategoryContent(
                        {
                          category: category.category,
                          data: {
                            translations: response.translations,
                          },
                        },
                        {
                          onSuccess: () => {
                            predefinedToasts.success.updated();
                          },
                        },
                      );
                    }
                  }}
                  disabled={isUpdating}
                />
              );
            },
          },
        ]}
      />
    </TableWrapper>
  );
};
