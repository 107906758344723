import { Stack } from '@mui/material';
import { ShopDisabledFeatureAlert } from 'containers/shop';
import { Loader, NothingFoundAlert } from '../../../../melp-design/components';
import { useBenefit } from '../../../../state/Benefits';
import { useIdParam } from '../../../../utils/useIdParam';
import { BenefitPlanContext } from '../../common/BenefitPlanContext';
import BenefitDetailsFooter from '../common/BenefitDetailsFooter';
import MarketplaceWithinBenefit from './MarketplaceWithinBenefit';

const Marketplace = () => {
  const id = useIdParam();
  const benefit = useBenefit(id);

  if (benefit.loading) return <Loader />;
  if (!benefit.data) return <NothingFoundAlert />;

  return (
    <>
      <BenefitPlanContext.Provider value={benefit.data}>
        <Stack gap={2}>
          <ShopDisabledFeatureAlert />
          <MarketplaceWithinBenefit />
        </Stack>
      </BenefitPlanContext.Provider>
      <BenefitDetailsFooter />
    </>
  );
};

export default Marketplace;
