import {
  Form,
  FormConnector,
  FormField,
  Loader,
  NothingFoundAlert,
  OptionsItem,
  Panel,
} from 'melp-design/components';
import { Box, Stack, Tooltip } from '@mui/material';
import moment from 'moment';
import { ComponentProps, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { APP } from 'config';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog/ConfirmationDialog';
import { useMe } from 'state/Administrators';
import { useBenefit, useBenefitMutations } from 'state/Benefits';
import { useDialog } from 'utils/Dialog';
import { usePredefinedToasts } from 'utils/Toast';
import { useIdParam } from 'utils/useIdParam';
import { benefitCategories } from 'store/benefits';
import BenefitDetailsFooter from '../common/BenefitDetailsFooter';
import FormReset from '../common/FormReset';
import { resolveBenefitPublishingStatus } from '../common/resolveBenefitPublishingStatus';
import { resolveBenefitValidityStatus } from '../common/resolveBenefitValidityStatus';
import BenefitTermFields from './BenefitTermFields';
import BenefitValueFields from './BenefitValueFields';
import EmployeeContributionField from './EmployeeContributionField';
import InvestmentFrequencyField from './InvestmentFrequencyField';
import {
  BenefitSettingsFormData,
  BenefitSettingsFormDataAfterValidation,
} from './types';

const Settings = () => {
  const { t } = useTranslation();
  const id = useIdParam();
  const { search } = useLocation();
  const predefinedToasts = usePredefinedToasts();

  const queryParams = new URLSearchParams(search);
  const creation = Boolean(queryParams.get('creation'));

  const { canEditBenefits, isFeatureEnabled } = useMe();

  const latePublishConfirmation = useDialog();
  const submitRef = useRef<() => void>();
  const handleConfirmation = (close: () => void) => {
    submitRef.current?.();
    close();
  };

  const benefit = useBenefit(id);
  const benefitMutations = useBenefitMutations(id);

  if (benefit.loading) return <Loader />;
  if (!benefit.data) return <NothingFoundAlert />;

  const publishingStatus = resolveBenefitPublishingStatus(benefit.data);
  const validityStatus = resolveBenefitValidityStatus(benefit.data);

  return (
    <Form<BenefitSettingsFormData>
      defaultValues={benefit.data}
      readOnly={!canEditBenefits}
    >
      <FormReset
        data={benefit.data}
        dependencies={[publishingStatus, validityStatus]}
      />
      <Stack gap="20px">
        <Panel title={t('benefits.benefitGeneralSettingsSection')}>
          <Stack gap="20px">
            <FormField
              name="type"
              label={t('benefits.benefitCategory')}
              render="select"
              rules={{ required: true }}
            >
              {benefitCategories.map((category) => (
                <OptionsItem key={category} value={category}>
                  {t(`menu.${category}`)}
                </OptionsItem>
              ))}
            </FormField>
            <FormField
              name="name"
              label={t('common.systemName')}
              render="text"
              rules={{ required: true }}
            />
            <FormField
              name="isPreview"
              render="switch"
              label={t('benefits.isPreview.label')}
            />
          </Stack>
        </Panel>
        <Panel
          title={t('benefits.benefitFinanceSection')}
          subtitle={t('benefits.benefitFinanceSectionHint')}
          collapsible
          isInitialExpanded={!creation}
        >
          <Stack gap="20px">
            <Stack direction="row" gap="20px" alignItems="center">
              <FormField
                name="investmentAmount"
                render="currency"
                label={t('common.investment')}
              />
              <InvestmentFrequencyField />
              <Box width="100%">
                <FormField
                  name="isVisibleForEmployees"
                  render="checkbox"
                  label={t('benefits.show_on_app')}
                  defaultValue={false}
                />
              </Box>
            </Stack>
            <EmployeeContributionField />
            <BenefitValueFields />
            <FormField
              name="isBalanceTrackingEnabled"
              render="switch"
              label={t('benefits.trackBalance')}
            />
            <Tooltip
              title={
                isFeatureEnabled('claimReimbursement')
                  ? ''
                  : t('benefits.allowClaimReimbursement.info', {
                      email: APP.email.info,
                    })
              }
            >
              <div style={{ width: 'max-content' }}>
                <FormField
                  name="allowClaimReimbursement"
                  render="switch"
                  label={t('benefits.allowClaimReimbursement.label')}
                  disabled={!isFeatureEnabled('claimReimbursement')}
                />
              </div>
            </Tooltip>
          </Stack>
        </Panel>
        <Panel title={t('benefits.benefitDatesSection')}>
          <BenefitTermFields />
        </Panel>
      </Stack>
      <FormConnector>
        {({ handleSubmit, reset, watch, formState: { isDirty, touched } }) => {
          const handleValidationError = () => {
            predefinedToasts.error.validationGlobal();
          };

          const submitSettings = async ({
            isPreview,
            ...data
          }: BenefitSettingsFormDataAfterValidation) => {
            const response = await benefitMutations.update.execute({
              isDraft: benefit.data?.isDraft ?? true,
              internalComment: benefit.data?.internalComment ?? '',
              previewDate:
                touched['isPreview'] && isPreview
                  ? moment().toISOString(true)
                  : isPreview
                  ? benefit.data?.previewDate
                  : null,
              ...data,
            });
            reset(response);
            predefinedToasts.success.updated();
            return response;
          };

          const activationDate = watch('activatedAt');
          const publishDate = watch('publishedAt');

          const withConfirmation = (submit: () => void) => () => {
            if (
              activationDate &&
              moment(activationDate).isBefore(publishDate)
            ) {
              submitRef.current = submit;
              latePublishConfirmation.openDialog();
            } else {
              submit();
            }
          };

          const handleSettingsUpdate = withConfirmation(
            handleSubmit(submitSettings, handleValidationError),
          );

          const handleSettingsUpdateAndNext: ComponentProps<
            typeof BenefitDetailsFooter
          >['onSaveAndNext'] = (_, nextStep) => {
            withConfirmation(
              handleSubmit(
                async (data: BenefitSettingsFormDataAfterValidation) => {
                  const response = await submitSettings(data);
                  nextStep(response);
                },
                handleValidationError,
              ),
            )();
          };

          return (
            <BenefitDetailsFooter
              hasChanges={isDirty}
              onSave={handleSettingsUpdate}
              onSaveAndNext={handleSettingsUpdateAndNext}
              saving={benefitMutations.update.loading}
            />
          );
        }}
      </FormConnector>
      <ConfirmationDialog
        id="latePublishConfirmation"
        content={t('benefits.publishedLaterThanActiveWarning')}
        onConfirm={handleConfirmation}
        onCancel={latePublishConfirmation.closeDialog}
        open={latePublishConfirmation.open}
      />
    </Form>
  );
};

export default Settings;
