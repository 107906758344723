import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import {
  IAssignmentHistoryEntry,
  IBenefitByEmployee,
  IBenefitByEmployeeGroup,
  IBenefitByEmployeePreview,
  IBenefitEmployeeAssignmentTimeline,
  IEmployeeByBenefit,
  IEmployeeByEmployeeGroup,
  IEmployeeGroupByBenefit,
  IEmployeeGroupByEmployee,
} from 'types/income';
import { TableDataResponse } from 'types/Table';
import { CustomCategory } from 'store/custom-categories';
import {
  BenefitByEmployeeGroupFilters,
  BenefitsByEmployeeFilters,
  EmployeesByEmployeeGroupFilters,
  EmployeeGroupsByEmployeeFilters,
  EmployeesByBenefitFilters,
  AssignmentEntityRelation,
} from './assignments.types';

type AssignmentDateRange = {
  from?: string;
  to?: string;
  fromNow?: true;
  toNow?: true;
};

interface GetEmployeesByBenefit {
  benefitId: string;
  filters: Partial<EmployeesByBenefitFilters>;
}

interface GetEmployeeGroupsByBenefit {
  benefitId: string;
  filters: Partial<EmployeesByBenefitFilters>;
}

interface GetBenefitsByEmployee {
  employeeId: string;
  filters: Partial<BenefitsByEmployeeFilters>;
}

interface GetBenefitsByEmployeePreview {
  employeeId: string;
}

interface GetBenefitsEmployeeAssignmentTimeline {
  employeeId: string;
  benefitId: string;
}

interface GetEmployeeGroupsByEmployee {
  employeeId: string;
  filters: Partial<EmployeeGroupsByEmployeeFilters>;
}

interface GetBenefitsByEmployeeGroup {
  employeeGroupId: string;
  filters: Partial<BenefitByEmployeeGroupFilters>;
}

interface GetEmployeesByEmployeeGroup {
  employeeGroupId: string;
  filters: Partial<EmployeesByEmployeeGroupFilters>;
}

interface GetEmployeeBenefitAssignmentHistory {
  employeeId: string;
  benefitId: string;
}

interface GetBenefitEmployeeGroupAssignmentHistory {
  benefitId: string;
  employeeGroupId: string;
}

interface GetEmployeeEmployeeGroupAssignmentHistory {
  employeeId: string;
  employeeGroupId: string;
}

interface AssignGroupsToEmployee {
  action: 'create' | 'update';
  employeeId: string;
  data: {
    employeeGroupIds: string[];
  } & AssignmentDateRange;
}
interface AssignBenefitsToEmployee {
  employeeId: string;
  action: 'create' | 'update';
  data: {
    benefitIds: string[];
  } & AssignmentDateRange;
}
interface AssignGroupsToBenefit {
  benefitId: string;
  action: 'create' | 'update';
  data: {
    employeeGroupIds: string[];
  } & AssignmentDateRange;
}
interface AssignEmployeesToBenefit {
  benefitId: string;
  action: 'create' | 'update';
  data: {
    employeeIds: string[];
  } & AssignmentDateRange;
}
interface AssignBenefitsToGroup {
  employeeGroupId: string;
  action: 'create' | 'update';
  data: {
    benefitIds: string[];
  } & AssignmentDateRange;
}
interface AssignEmployeesToGroup {
  employeeGroupId: string;
  action: 'create' | 'update';
  data: {
    employeeIds: string[];
  } & AssignmentDateRange;
}

interface DeleteEmployeeBenefitAssignmentHistoryEntry {
  assignmentId: string;
}

interface DeleteBenefitEmployeeGroupAssignmentHistoryEntry {
  assignmentId: string;
}

interface DeleteEmployeeEmployeeGroupAssignmentHistoryEntry {
  assignmentId: string;
}

interface UpdateAssignment {
  assignmentId: string;
  relation: AssignmentEntityRelation;
  data: AssignmentDateRange;
}

export const loaders = {
  getEmployeesByBenefit: ({ benefitId, filters }: GetEmployeesByBenefit) => {
    return api<
      TableDataResponse<IEmployeeByBenefit> & {
        customCategories?: Pick<CustomCategory, 'id' | 'name'>[];
      }
    >({
      url: Endpoints.clientAdmin.assignments.benefits.employees.root.replace(
        ':benefitId',
        benefitId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          employeeIds: filters.employees,
          employeeGroupIds: filters.employeeGroups,
          companyIds: filters.companies,
          departmentIds: filters.departments,
          assignmentStatus: filters.assignmentStatus,
          customCategoryId: filters.customCategory || undefined,
          customCategoryValueIds: filters.customCategoryValue,
        },
      },
    });
  },
  getEmployeeGroupsByBenefit: ({
    benefitId,
    filters,
  }: GetEmployeeGroupsByBenefit) => {
    return api<TableDataResponse<IEmployeeGroupByBenefit>>({
      url: Endpoints.clientAdmin.assignments.benefits.employeeGroups.root.replace(
        ':benefitId',
        benefitId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          employeeGroupIds: filters.employeeGroups,
          assignmentStatus: filters.assignmentStatus,
        },
      },
    });
  },
  getBenefitsByEmployee: ({ employeeId, filters }: GetBenefitsByEmployee) => {
    return api<TableDataResponse<IBenefitByEmployee>>({
      url: Endpoints.clientAdmin.assignments.employees.benefits.root.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        search: filters.search || '',
        filter: {
          benefitIds: filters.benefits,
          categories: filters.categories,
          assignmentStatus: filters.assignmentStatus,
        },
      },
    });
  },
  getBenefitsByEmployeePreview: ({
    employeeId,
  }: GetBenefitsByEmployeePreview) => {
    return api<{ data: IBenefitByEmployeePreview[] }>({
      url: Endpoints.clientAdmin.assignments.employees.benefits.preview.root.replace(
        ':employeeId',
        employeeId,
      ),
    });
  },
  getBenefitsEmployeeAssignmentTimeline: ({
    benefitId,
    employeeId,
  }: GetBenefitsEmployeeAssignmentTimeline) => {
    return api<IBenefitEmployeeAssignmentTimeline>({
      url: Endpoints.clientAdmin.assignments.employees.benefits.timeline.root
        .replace(':employeeId', employeeId)
        .replace(':benefitId', benefitId),
    });
  },
  getEmployeeGroupsByEmployee: ({
    employeeId,
    filters,
  }: GetEmployeeGroupsByEmployee) => {
    return api<TableDataResponse<IEmployeeGroupByEmployee>>({
      url: Endpoints.clientAdmin.assignments.employees.employeeGroups.root.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          employeeGroupIds: filters.employeeGroups,
          assignmentStatus: filters.assignmentStatus,
        },
      },
    });
  },
  getBenefitsByEmployeeGroup: ({
    employeeGroupId,
    filters,
  }: GetBenefitsByEmployeeGroup) => {
    return api<TableDataResponse<IBenefitByEmployeeGroup>>({
      url: Endpoints.clientAdmin.assignments.employeeGroups.benefits.root.replace(
        ':employeeGroupId',
        employeeGroupId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          employeeGroupIds: filters.employeeGroups,
          benefitIds: filters.benefits,
          categories: filters.categories,
          assignmentStatus: filters.assignmentStatus,
        },
      },
    });
  },
  getEmployeesByEmployeeGroup: ({
    employeeGroupId,
    filters,
  }: GetEmployeesByEmployeeGroup) => {
    return api<
      TableDataResponse<IEmployeeByEmployeeGroup> & {
        customCategories?: Pick<CustomCategory, 'id' | 'name'>[];
      }
    >({
      url: Endpoints.clientAdmin.assignments.employeeGroups.employees.root.replace(
        ':employeeGroupId',
        employeeGroupId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          companyIds: filters.companies,
          departmentIds: filters.departments,
          employeeIds: filters.employees,
          assignmentStatus: filters.assignmentStatus,
          jobTitles: filters.jobTitle,
          customCategoryId: filters.customCategory || undefined,
          customCategoryValueIds: filters.customCategoryValue,
        },
      },
    });
  },

  getEmployeeBenefitAssignmentHistory: ({
    employeeId,
    benefitId,
  }: GetEmployeeBenefitAssignmentHistory) => {
    return api<{ data: IAssignmentHistoryEntry[] }>({
      url: Endpoints.clientAdmin.assignments.history.employee_benefit
        .replace(':employeeId', employeeId)
        .replace(':benefitId', benefitId),
    });
  },
  getBenefitEmployeeGroupAssignmentHistory: ({
    benefitId,
    employeeGroupId,
  }: GetBenefitEmployeeGroupAssignmentHistory) => {
    return api<{ data: IAssignmentHistoryEntry[] }>({
      url: Endpoints.clientAdmin.assignments.history.benefit_employeeGroup
        .replace(':employeeGroupId', employeeGroupId)
        .replace(':benefitId', benefitId),
    });
  },
  getEmployeeEmployeeGroupAssignmentHistory: ({
    employeeId,
    employeeGroupId,
  }: GetEmployeeEmployeeGroupAssignmentHistory) => {
    return api<{ data: IAssignmentHistoryEntry[] }>({
      url: Endpoints.clientAdmin.assignments.history.employee_employeeGroup
        .replace(':employeeId', employeeId)
        .replace(':employeeGroupId', employeeGroupId),
    });
  },
  assignGroupsToEmployee: ({
    action,
    employeeId,
    data,
  }: AssignGroupsToEmployee) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employees.employeeGroups.bulk.replace(
        ':employeeId',
        employeeId,
      ),
      data,
      method: action === 'create' ? 'post' : 'patch',
    });
  },
  assignBenefitsToEmployee: ({
    action,
    employeeId,
    data,
  }: AssignBenefitsToEmployee) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employees.benefits.bulk.replace(
        ':employeeId',
        employeeId,
      ),
      data,
      method: action === 'create' ? 'post' : 'patch',
    });
  },
  assignGroupsToBenefit: ({
    action,
    benefitId,
    data,
  }: AssignGroupsToBenefit) => {
    return api({
      url: Endpoints.clientAdmin.assignments.benefits.employeeGroups.bulk.replace(
        ':benefitId',
        benefitId,
      ),
      data,
      method: action === 'create' ? 'post' : 'patch',
    });
  },
  assignEmployeesToBenefit: ({
    action,
    benefitId,
    data,
  }: AssignEmployeesToBenefit) => {
    return api({
      url: Endpoints.clientAdmin.assignments.benefits.employees.bulk.replace(
        ':benefitId',
        benefitId,
      ),
      data,
      method: action === 'create' ? 'post' : 'patch',
    });
  },
  assignBenefitsToGroup: ({
    action,
    employeeGroupId,
    data,
  }: AssignBenefitsToGroup) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employeeGroups.benefits.bulk.replace(
        ':employeeGroupId',
        employeeGroupId,
      ),
      data,
      method: action === 'create' ? 'post' : 'patch',
    });
  },
  assignEmployeesToGroup: ({
    action,
    employeeGroupId,
    data,
  }: AssignEmployeesToGroup) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employeeGroups.employees.bulk.replace(
        ':employeeGroupId',
        employeeGroupId,
      ),
      data,
      method: action === 'create' ? 'post' : 'patch',
    });
  },
  updateAssignment: ({ assignmentId, relation, data }: UpdateAssignment) => {
    return api({
      url:
        relation === 'benefit/employee'
          ? Endpoints.clientAdmin.assignments.employees.benefits.byAssignmentId.replace(
              ':assignmentId',
              assignmentId,
            )
          : relation === 'benefit/employeeGroup'
          ? Endpoints.clientAdmin.assignments.benefits.employeeGroups.byAssignmentId.replace(
              ':assignmentId',
              assignmentId,
            )
          : relation === 'employee/employeeGroup'
          ? Endpoints.clientAdmin.assignments.employees.employeeGroups.byAssignmentId.replace(
              ':assignmentId',
              assignmentId,
            )
          : undefined,
      data,
      method: 'patch',
    });
  },
  deleteEmployeeBenefitAssignmentHistoryEntry: ({
    assignmentId,
  }: DeleteEmployeeBenefitAssignmentHistoryEntry) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employees.benefits.byAssignmentId.replace(
        ':assignmentId',
        assignmentId,
      ),
      method: 'delete',
    });
  },
  deleteBenefitEmployeeGroupAssignmentHistoryEntry: ({
    assignmentId,
  }: DeleteBenefitEmployeeGroupAssignmentHistoryEntry) => {
    return api({
      url: Endpoints.clientAdmin.assignments.benefits.employeeGroups.byAssignmentId.replace(
        ':assignmentId',
        assignmentId,
      ),
      method: 'delete',
    });
  },
  deleteEmployeeEmployeeGroupAssignmentHistoryEntry: ({
    assignmentId,
  }: DeleteEmployeeEmployeeGroupAssignmentHistoryEntry) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employees.employeeGroups.byAssignmentId.replace(
        ':assignmentId',
        assignmentId,
      ),
      method: 'delete',
    });
  },
};
