import {
  Button,
  Link,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { APP, ROUTES } from 'config';
import {
  BenefitsFilter,
  CompaniesFilter,
  DateRangeFilter,
  DepartmentsFilter,
  FilterButton,
  Filters,
  SelectFilter,
} from 'components/filters';
import {
  COLOR_BY_BENEFIT_STATUS,
  benefitCategories,
  benefitPublishStatuses,
  benefitStatuses,
  benefitsFiltersSchema,
  useBenefits,
  useExportToXls,
} from 'store/benefits';
import { formatCurrency } from 'utils/format';
import MultipleBenefitsPDFDownloadDialog from 'components/benefits/BenefitsPDF/MultipleBenefitsPDFDownloadDialog';
import AddBenefits from 'components/common/AddBenefits/AddBenefits';
import AddBenefitDialog from 'components/dialogs/AddBenefitDialog/AddBenefitDialog';
import TemplateDialog, {
  TranslatedBenefitTemplate,
} from 'components/dialogs/TemplateDialog/TemplateDialog';
import NumberRangeFilter from 'components/filters/NumberRangeFilter';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { useMe } from 'state/Administrators';
import { useBenefitsMutations } from 'state/Benefits';
import { usePredefinedToasts } from 'utils/Toast';
import { useDialog } from 'utils/hooks/useDialog';
import { useSearchParams } from 'utils/navigation';

export const BenefitsList: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const predefinedToasts = usePredefinedToasts();
  const { me, canEditBenefits } = useMe();
  const benefitsMutations = useBenefitsMutations();
  const templatesDialog = useDialog();
  const [selectedTemplate, setSelectedTemplate] =
    useState<TranslatedBenefitTemplate>();
  const benefitCreationDialog = useDialog();
  const downloadPdfDialog = useDialog();
  const currency = me?.parentCompany.defaultCurrency;
  const { selectedRowIds, setSelectedRowIds } = Table.useSelectedRows();

  const { searchParams: filters, navigate } = useSearchParams(
    benefitsFiltersSchema,
  );

  const { data: benefits, isLoading } = useBenefits(filters);

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();

  return (
    <>
      <TableWrapper>
        <TableToolbar>
          <Stack gap={1}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={2}
            >
              <Button
                variant="secondary"
                label={t('benefits.templates')}
                onClick={templatesDialog.openDialog}
                disabled={!canEditBenefits}
              />

              <AddBenefits label={t('buttons.new_benefit')} />
            </Stack>

            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <ListTotal total={benefits?.total} />

              <Stack direction="row" alignItems="center" gap="10px">
                <Filters
                  value={filters}
                  initialValues={benefitsFiltersSchema.parse({})}
                  onChange={(v) => navigate({ params: v })}
                  onChangeCallback={() => setSelectedRowIds({})}
                >
                  {({ value, initialValues, setFilter }) => (
                    <>
                      <FilterButton
                        label={t('common.plan_name')}
                        value={value.benefits}
                        initialValue={initialValues.benefits}
                        onChange={(v) => setFilter('benefits', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <BenefitsFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('benefits.benefit_type')}
                        value={value.category}
                        initialValue={initialValues.category}
                        onChange={(v) => setFilter('category', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <SelectFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            multiple
                            options={benefitCategories.map((category) => ({
                              key: category,
                              label: t(`menu.${category}`),
                            }))}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('common.ends')}
                        value={value.endsAt}
                        initialValue={initialValues.endsAt}
                        onChange={(v) => setFilter('endsAt', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <DateRangeFilter
                            value={value}
                            onApply={applyFilter}
                            onClear={clearFilter}
                            onCancel={close}
                            suggestions={['week', 'month', 'year']}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('exportFileName.employees')}
                        value={value.employeeCount}
                        initialValue={initialValues.employeeCount}
                        onChange={(v) => setFilter('employeeCount', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <NumberRangeFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('employees.company')}
                        value={value.companies}
                        initialValue={initialValues.companies}
                        onChange={(v) => setFilter('companies', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <CompaniesFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            multiple
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('employees.department')}
                        value={value.departments}
                        initialValue={initialValues.departments}
                        onChange={(v) => setFilter('departments', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <DepartmentsFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            multiple
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('benefits.publish_status')}
                        value={value.publishStatus}
                        initialValue={initialValues.publishStatus}
                        onChange={(v) => setFilter('publishStatus', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <SelectFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            multiple
                            options={benefitPublishStatuses.map((status) => ({
                              key: status,
                              label: t(`status.${status}`),
                            }))}
                            searchable={false}
                          />
                        )}
                      </FilterButton>

                      <FilterButton
                        label={t('common.current_status')}
                        value={value.status}
                        initialValue={initialValues.status}
                        onChange={(v) => setFilter('status', v)}
                      >
                        {({ value, applyFilter, clearFilter, close }) => (
                          <SelectFilter
                            value={value}
                            onApplyFilter={applyFilter}
                            onClearFilter={clearFilter}
                            onCancel={close}
                            multiple
                            options={benefitStatuses.map((status) => ({
                              key: status,
                              label: t(`status.${status}`),
                            }))}
                            searchable={false}
                          />
                        )}
                      </FilterButton>
                    </>
                  )}
                </Filters>

                <Divider orientation="vertical" variant="middle" flexItem />

                <DownloadExcelButton
                  listLength={benefits?.total ?? 0}
                  exportToExcel={() => exportToXls({ filters })}
                  loading={isExportingToXls}
                />
              </Stack>
            </Stack>
          </Stack>
        </TableToolbar>

        <Table
          data={benefits?.items ?? []}
          isLoading={isLoading}
          columns={[
            {
              id: 'name',
              header: t('common.plan_name'),
              cell: ({ row: { original: benefit } }) => (
                <Link
                  label={benefit.name}
                  to={ROUTES.benefits.details.replace(':id', benefit.id)}
                />
              ),
              meta: { sort: true },
            },
            {
              id: 'type',
              header: t('benefits.benefit_type'),
              cell: ({ row: { original: benefit } }) => {
                return t(`menu.${benefit.type}`);
              },
              meta: { sort: true },
            },
            {
              id: 'trackBalance',
              header: t('benefitExpenses.remaining'),
              cell: ({ row: { original: benefit } }) => {
                return benefit.trackBalance ? t('status.on') : null;
              },
            },
            {
              id: 'allowClaim',
              header: t('benefits.claim_expense'),
              cell: ({ row: { original: benefit } }) => {
                return benefit.allowClaimReimbursement ? t('status.on') : null;
              },
            },
            {
              id: 'value',
              header: t('benefits.value'),
              cell: ({ row: { original: benefit } }) =>
                Number.isNaN(benefit.value)
                  ? null
                  : formatCurrency(i18n.language, benefit.value, currency),
              meta: { align: 'right' },
            },
            {
              id: 'deactivationDate',
              header: t('common.ends'),
              cell: ({ row: { original: benefit } }) =>
                benefit.deactivationDate
                  ? moment(benefit.deactivationDate).format('l')
                  : null,
            },
            {
              id: 'employeeCount',
              header: t('exportFileName.employees'),
              cell: ({ row: { original: benefit } }) => benefit.employeeCount,
              meta: { align: 'right' },
            },
            {
              id: 'publishStatus',
              header: t('benefits.publish_status'),
              cell: ({ row: { original: benefit } }) => {
                return t(`status.${benefit.publishStatus}`);
              },
            },
            {
              id: 'status',
              header: t('common.current_status'),
              meta: { align: 'center' },
              cell: ({ row: { original: benefit } }) => (
                <Typography color={COLOR_BY_BENEFIT_STATUS[benefit.status]}>
                  {benefit.status === 'expiresSoon' && benefit.deactivationDate
                    ? t('status.valid_for', {
                        days: moment(benefit.deactivationDate).diff(
                          moment(),
                          'days',
                        ),
                      })
                    : t(`status.${benefit.status}`)}
                </Typography>
              ),
            },
          ]}
          rows={{
            inactive: ({ original: benefit }) => benefit.status === 'inactive',
          }}
          sort={{
            value: filters.sort,
            onChange: (v) => navigate({ params: { sort: v } }),
          }}
          selection={{
            value: selectedRowIds,
            onChange: setSelectedRowIds,
            actions: [
              {
                label: t('common.downloadPDF'),
                onClick: downloadPdfDialog.openDialog,
              },
            ],
          }}
          pagination={
            benefits && {
              count: benefits.total,
              page: benefits.page,
              pageSize: benefits.pageSize,
              pageSizeOptions: APP.pagination.defaults.pageSize,
              onChange: (page, pageSize) => {
                navigate({ params: { page, pageSize } });
              },
            }
          }
        />
      </TableWrapper>

      <MultipleBenefitsPDFDownloadDialog
        open={downloadPdfDialog.open}
        onCancel={downloadPdfDialog.closeDialog}
        benefitIds={Object.keys(selectedRowIds)}
      />
      <TemplateDialog
        open={templatesDialog.open}
        onClose={templatesDialog.closeDialog}
        onCreate={(template) => {
          setSelectedTemplate(template);
          templatesDialog.closeDialog();
          benefitCreationDialog.openDialog();
        }}
      />
      <AddBenefitDialog
        open={benefitCreationDialog.open}
        onCancelClick={() => {
          setSelectedTemplate(undefined);
          benefitCreationDialog.closeDialog();
        }}
        initialValues={{
          type: selectedTemplate?.type,
          name: selectedTemplate?.title,
          templateId: selectedTemplate?.id,
        }}
        onActionClick={async (data) => {
          const response = await benefitsMutations.create.execute(data);
          setSelectedTemplate(undefined);
          benefitCreationDialog.closeDialog();
          predefinedToasts.success.added();
          history.push(
            `${ROUTES.benefits.details.replace(
              ':id',
              response.id,
            )}?creation=true`,
          );
        }}
      />
    </>
  );
};
