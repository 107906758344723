import { ContentLanguage } from 'types/general';
import {
  BenefitContent,
  BenefitContentUpdateRequest,
} from '../../../../types/Benefits';
import { ContentFormData, ValidatedContentFormData } from './types';

export const toFormData = (
  serverData: BenefitContent,
  defaultLanguage: Uppercase<ContentLanguage>,
  benefitName?: string,
): ContentFormData => {
  const translationProps = serverData.translations.reduce(
    (acc, { language, ...translation }) => {
      acc.supportedLanguages.push(language);
      acc.title[language] = translation.title;
      acc.description[language] = translation.description;

      translation.links.forEach((translationLink, index) => {
        if (!acc.links[index]) {
          acc.links[index] = { name: {}, url: {} };
        }
        const link = acc.links[index];
        link.name[language] = translationLink.name;
        link.url[language] = translationLink.url;
      });

      translation.faqs.forEach((translationFaq, index) => {
        if (!acc.faqs[index]) {
          acc.faqs[index] = { question: {}, answer: {} };
        }
        const faq = acc.faqs[index];
        faq.question[language] = translationFaq.question;
        faq.answer[language] = translationFaq.answer;
      });

      translation.attachments.forEach((translationAttachment) => {
        const arrayItemIndex = translationAttachment.index - 1;
        if (!acc.attachments[arrayItemIndex]) {
          acc.attachments[arrayItemIndex] = { name: {}, fileId: {} };
        }
        const attachment = acc.attachments[arrayItemIndex];
        attachment.name[language] = translationAttachment.file?.fileName;
        attachment.fileId[language] = translationAttachment.file?.id;
      });

      return acc;
    },
    {
      supportedLanguages: [],
      title: {},
      description: {},
      links: [],
      faqs: [],
      attachments: [],
    } as Required<Omit<ValidatedContentFormData, 'imageId'>>,
  );

  if (!translationProps.supportedLanguages.includes(defaultLanguage)) {
    translationProps.supportedLanguages.push(defaultLanguage);
  }

  if (!translationProps.title[defaultLanguage]) {
    translationProps.title[defaultLanguage] = benefitName ?? '';
  }

  return {
    ...translationProps,
    imageId: serverData.image?.id,
  };
};

export const toServerData = ({
  imageId,
  supportedLanguages = [],
  links = [],
  faqs = [],
  attachments = [],
  ...formData
}: ValidatedContentFormData): BenefitContentUpdateRequest => {
  return {
    imageId,
    translations: supportedLanguages.map((language) => ({
      language,
      title: formData.title[language] ?? '',
      description: formData.description[language] ?? '',
      links: links.map((link, index) => ({
        index: index + 1,
        name: link.name[language] ?? '',
        url: link.url[language] ?? '',
      })),
      faqs: faqs.map((faq, index) => ({
        index: index + 1,
        question: faq.question[language] ?? '',
        answer: faq.answer[language] ?? '',
      })),
      attachments: attachments
        .map((attachment, index) => ({
          index: index + 1,
          fileId: attachment.fileId[language] ?? '',
          name: attachment.name[language] ?? '',
        }))
        .filter((attachment) => !!attachment.fileId),
    })),
  };
};
