import { FormField, Typography } from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import { ComponentProps, useEffect } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type FieldProps = Pick<
  ComponentProps<typeof FormField>,
  'name' | 'label' | 'rules'
>;

interface Props {
  from: FieldProps;
  to: FieldProps;
}

const DateRange = (props: Props) => {
  const { t } = useTranslation();
  const { watch, trigger, errors } = useFormContext();

  const rangeStartName = props.from.name;
  const rangeEndName = props.to.name;

  const rangeStartString: string | null = watch(rangeStartName) ?? null;
  const rangeEndString: string | null = watch(rangeEndName) ?? null;
  const rangeStart = moment(rangeStartString);
  const rangeEnd = moment(rangeEndString);

  useEffect(() => {
    if (get(errors, rangeEndName)) {
      trigger(rangeEndName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeStartString]);

  useEffect(() => {
    if (get(errors, rangeStartName)) {
      trigger(rangeStartName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeEndString]);

  return (
    <Stack direction="row" gap="5px">
      <FormField
        defaultValue={null}
        {...props.from}
        render={{
          type: 'dateTime',
          props: {
            maxDateTime: rangeEnd.isValid() ? rangeEnd : undefined,
            showTimeOffset: true,
          },
        }}
      />
      <Box display="flex" alignItems="center" height="50px">
        <Typography variant="p1">-</Typography>
      </Box>
      <FormField
        defaultValue={null}
        {...props.to}
        rules={{
          ...props.to.rules,
          validate: (value: string | null) => {
            if (
              value &&
              rangeStart.isValid() &&
              moment(value).isBefore(rangeStart)
            ) {
              return t('common.endDateBeforeStartDate');
            }
          },
        }}
        render={{
          type: 'dateTime',
          props: {
            minDateTime: rangeStart.isValid() ? rangeStart : undefined,
            showTimeOffset: true,
          },
        }}
      />
    </Stack>
  );
};

export default DateRange;
