import moment from 'moment';
import { Benefit } from '../../../../types/Benefits';

type BenefitPublishingStatus = 'published' | 'unpublished';

/**
 * Resolves the publishing status of a benefit.
 * @param benefit - The benefit object to check publishing status for.
 * @returns The publishing status of the benefit.
 */
export const resolveBenefitPublishingStatus = (
  benefit: Benefit,
): BenefitPublishingStatus => {
  if (benefit.isDraft) {
    return 'unpublished';
  }

  const currentDate = moment();

  if (
    currentDate.isBefore(benefit.publishedAt) ||
    currentDate.isSameOrAfter(benefit.deactivatedAt)
  ) {
    return 'unpublished';
  }

  return 'published';
};
