import { FC } from 'react';
import {
  Link,
  ListTotal,
  TableToolbar,
  TableWrapper,
  Table,
  Typography,
  Button,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { useIdParam } from 'utils/useIdParam';
import { APP, ROUTES } from 'config';
import { useModalContext } from 'store/modal';
import { useSearchParams } from 'utils/navigation';
import {
  COLOR_BY_STATUS,
  assignmentStatuses,
  employeeGroupsByBenefitFiltersSchema,
  useAssignGroupsToBenefit,
  useEmployeeGroupsByBenefitAssignments,
} from 'store/assignments';
import { Endpoints } from 'api/constants';
import {
  EmployeesGroupsFilter,
  FilterButton,
  FiltersInModal,
  SelectFilter,
} from 'components/filters';
import BenefitDetailsFooter from 'containers/BenefitsOld/BenefitDetails/common/BenefitDetailsFooter';
import {
  AssignmentDateSelectModal,
  BenefitEmployeeGroupAssignmentHistory,
} from 'containers/assignments';

export const BenefitEmployeesGroupsAssignments: FC = () => {
  const { t } = useTranslation();
  const benefitId = useIdParam();
  const { openModal } = useModalContext();
  const { selectedRowIds, setSelectedRowIds } = Table.useSelectedRows();

  const { searchParams: filters, navigate } = useSearchParams(
    employeeGroupsByBenefitFiltersSchema,
  );

  const { data: assignments, isLoading } =
    useEmployeeGroupsByBenefitAssignments(benefitId, filters);

  const { mutate: assignGroupsToBenefit, isLoading: isAssigning } =
    useAssignGroupsToBenefit();

  return (
    <>
      <TableWrapper>
        <TableToolbar sticky>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={assignments?.total} />

            <Stack direction="row" alignItems="center" gap="10px">
              <FiltersInModal
                value={filters}
                initialValues={employeeGroupsByBenefitFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
                onChangeCallback={() => setSelectedRowIds({})}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('entity_type.employeeGroup')}
                      value={value.employeeGroups}
                      initialValue={initialValues.employeeGroups}
                      onChange={(v) => setFilter('employeeGroups', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <EmployeesGroupsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.current_status')}
                      value={value.assignmentStatus}
                      initialValue={initialValues.assignmentStatus}
                      onChange={(v) => setFilter('assignmentStatus', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          options={assignmentStatuses.map((status) => ({
                            key: status,
                            label: t(`status.${status}`),
                          }))}
                          searchable={false}
                          multiple={true}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </FiltersInModal>
            </Stack>
          </Stack>
        </TableToolbar>

        <Table
          data={assignments?.items ?? []}
          isLoading={isLoading}
          columns={[
            {
              id: 'name',
              header: t('groups.group_name'),
              cell: ({ row: { original: assignment } }) => {
                return (
                  <Link
                    label={assignment.name}
                    to={ROUTES.employeeGroups.details.replace(
                      ':id',
                      assignment.id,
                    )}
                  />
                );
              },
            },
            {
              id: 'assignmentStatus',
              header: t('alternatives.assignmentStatus'),
              meta: { align: 'center' },
              cell: ({ row: { original: assignment } }) => {
                return (
                  <Stack
                    flexDirection="row"
                    gap={1.5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color={COLOR_BY_STATUS[assignment.assignmentStatus]}
                    >
                      {t(`status.${assignment.assignmentStatus}`)}
                    </Typography>

                    {assignment.assignmentCount ? (
                      <Button
                        variant="neutral-outline"
                        size="sm"
                        icon={AccessTime}
                        onClick={() => {
                          openModal({
                            component: BenefitEmployeeGroupAssignmentHistory,
                            props: {
                              benefitId,
                              employeeGroupId: assignment.id,
                            },
                            size: 'lg',
                          });
                        }}
                        tooltip={t('permissions.historyModule')}
                      />
                    ) : null}
                  </Stack>
                );
              },
            },
          ]}
          pagination={
            assignments && {
              count: assignments.total,
              page: assignments.page,
              pageSize: assignments.pageSize,
              pageSizeOptions: APP.pagination.defaults.pageSize,
              onChange: (page, pageSize) => {
                navigate({ params: { page, pageSize } });
              },
            }
          }
          rows={{
            inactive: ({ original: assignment }) =>
              assignment.assignmentStatus === 'off',
          }}
          selection={{
            value: selectedRowIds,
            onChange: setSelectedRowIds,
            actions: assignmentStatuses.map((status) => ({
              label: t(`status.${status}`),
              onClick: async () => {
                const response = await openModal({
                  component: AssignmentDateSelectModal,
                });

                if (response.action === 'CONFIRM') {
                  assignGroupsToBenefit(
                    {
                      benefitId,
                      ...(status === 'on'
                        ? {
                            action: 'create',
                            data: {
                              employeeGroupIds: Object.keys(selectedRowIds),
                              ...(response.isNow
                                ? { fromNow: true }
                                : { from: response.date }),
                            },
                          }
                        : {
                            action: 'update',
                            data: {
                              employeeGroupIds: Object.keys(selectedRowIds),
                              ...(response.isNow
                                ? { toNow: true }
                                : { to: response.date }),
                            },
                          }),
                    },
                    {
                      onSuccess: () => {
                        // TODO: replace this revalidation when benefit details is refactored with react-query
                        mutate(
                          Endpoints.clientAdmin.benefitPlans.byId(benefitId)
                            .root,
                        );
                        setSelectedRowIds({});
                      },
                    },
                  );
                }
              },
            })),
            isLoading: isAssigning,
          }}
        />
      </TableWrapper>

      <BenefitDetailsFooter saving={isAssigning} />
    </>
  );
};
