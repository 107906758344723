import {
  DeleteIconButton,
  FieldsList,
  FileLink,
  FormField,
  OrderChangeButton,
  Panel,
  Typography,
} from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSortedLanguages } from '../../../../utils/useSortedLanguages';
import FileUploadButton from './FileUploadButton';
import { ContentFormData, MultilingualAttachment } from './types';

const Attachments = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<ContentFormData>();

  const supportedLanguages = watch('supportedLanguages') ?? [];
  const multilingual = supportedLanguages.length > 1;
  const sortedLanguages = useSortedLanguages(supportedLanguages);

  return (
    <Panel title={t('form.attachments')}>
      <FieldsList<MultilingualAttachment>
        name="attachments"
        renderAddButton={(buttonProps, listUtils, renderDefault) => {
          if (multilingual) {
            return renderDefault();
          }

          return (
            <FileUploadButton
              onUploadCompleted={(uploadedFile) => {
                listUtils.append({
                  name: { [supportedLanguages[0]]: uploadedFile.name },
                  fileId: { [supportedLanguages[0]]: uploadedFile.id },
                });
              }}
              limitPosition={listUtils.itemsCount ? 'side' : 'bottom'}
              alignment="center"
            >
              {buttonProps.children}
            </FileUploadButton>
          );
        }}
      >
        {(item) => {
          const itemActions = (
            <Stack direction="row" gap="10px">
              <OrderChangeButton
                currentIndex={item.index}
                totalCount={item.itemsCount}
                move={item.move}
              />
              <DeleteIconButton onClick={item.remove} />
            </Stack>
          );

          return (
            <Stack gap="20px">
              {multilingual && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h3">
                    {t('benefits.attachmentIndex', { index: item.index + 1 })}
                  </Typography>
                  {itemActions}
                </Stack>
              )}
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: multilingual
                    ? 'auto auto 1fr'
                    : 'auto 1fr',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                {sortedLanguages.map((lang) => {
                  const path = `attachments.${item.index}`;
                  const fileIdPath = `${path}.fileId.${lang}`;
                  const namePath = `${path}.name.${lang}`;

                  const fileId: string | undefined = watch(fileIdPath);
                  const fileName: string | undefined = watch(namePath);

                  const langItemActions = multilingual ? (
                    <DeleteIconButton
                      onClick={() => {
                        setValue(fileIdPath, '', { shouldDirty: true });
                        setValue(namePath, '', { shouldDirty: true });
                      }}
                    />
                  ) : (
                    itemActions
                  );

                  return (
                    <Fragment key={lang}>
                      {multilingual && (
                        <Typography variant="p2" color="textSecondary">
                          {t(`language.${lang}`)}
                        </Typography>
                      )}
                      <Box display="none">
                        <FormField
                          name={fileIdPath}
                          render={{ type: 'text', props: { disabled: true } }}
                          defaultValue={item.field.fileId?.[lang]}
                        />
                        <FormField
                          name={namePath}
                          render={{ type: 'text', props: { disabled: true } }}
                          defaultValue={item.field.name?.[lang]}
                        />
                      </Box>
                      {!!fileName && !!fileId && <FileLink id={fileId} />}
                      {!fileName && (
                        <FileUploadButton
                          size="small"
                          limitPosition="side"
                          onUploadCompleted={(uploadedFile) => {
                            setValue(fileIdPath, uploadedFile.id, {
                              shouldDirty: true,
                            });
                            setValue(namePath, uploadedFile.name, {
                              shouldDirty: true,
                            });
                          }}
                        />
                      )}
                      <Box
                        visibility={fileName ? 'visible' : 'hidden'}
                        justifySelf={multilingual ? 'start' : 'end'}
                      >
                        {langItemActions}
                      </Box>
                    </Fragment>
                  );
                })}
              </Box>
            </Stack>
          );
        }}
      </FieldsList>
    </Panel>
  );
};

export default Attachments;
