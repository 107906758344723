import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import {
  DetailsPageContent,
  DetailsPageHeader,
  SideMenu,
  Typography,
} from 'melp-design/components';
import { ROUTES } from 'config';
import { useRouteMatch } from 'react-router-dom';
import { useRouteParams } from 'utils/useIdParam';
import { useAdminBrand } from 'store/admin-brands';

export const AdminBrandLayout: FC = ({ children }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { id } = useRouteParams(ROUTES.admin.brands.settings);

  const { data: brand } = useAdminBrand(id);

  return (
    <Stack>
      <DetailsPageHeader
        title={brand?.name ?? ''}
        // side={
        //   brand ? (
        //     <AdminAdminsActions id={brand.id} name={brand.name} />
        //   ) : null
        // }
        backTo={ROUTES.admin.brands.list}
        under={
          brand ? (
            <Typography color="textSecondary">
              {t('marketplace.employeeID', { id: brand.sequence })}
            </Typography>
          ) : null
        }
      />

      <DetailsPageContent>
        <SideMenu
          tabs={[
            {
              key: 'settings',
              label: t('common.settings'),
              url: ROUTES.admin.brands.settings.replace(':id', id),
              isActive: path === ROUTES.admin.brands.settings,
              disabled: !brand,
            },
            {
              key: 'content',
              label: t('benefitTemplates.templateContent'),
              url: ROUTES.admin.brands.content.replace(':id', id),
              isActive: path === ROUTES.admin.brands.content,
              disabled: !brand,
            },
            {
              key: 'cards',
              label: t('cards.title'),
              url: ROUTES.admin.brands.cards.replace(':id', id),
              isActive: path === ROUTES.admin.brands.cards,
              disabled: !brand,
            },
          ]}
        />
        {children}
      </DetailsPageContent>
    </Stack>
  );
};
