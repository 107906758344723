import {
  ButtonOld,
  DialogActions,
  DialogContent,
  FormDialog,
  FormDialogProps,
  SuccessDialog,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'config';
import AddBenefits from '../../../../components/common/AddBenefits/AddBenefits';
import { useBenefit, useBenefitMutations } from '../../../../state/Benefits';
import { BenefitPublishRequest } from '../../../../types/Benefits';
import { useDialog } from '../../../../utils/Dialog';
import { useIdParam } from '../../../../utils/useIdParam';
import PublishDateField from './PublishDateField';

interface Props extends Pick<FormDialogProps, 'open' | 'onCancel'> {
  /**
   * Callback to execute when benefit publishing is completed
   */
  onComplete?: () => void;
}

const PublishDialog = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const id = useIdParam();
  const benefit = useBenefit(id);
  const benefitMutations = useBenefitMutations(id);

  const completionSuccessDialog = useDialog();

  const handleSubmit = async (data: BenefitPublishRequest) => {
    await benefitMutations.publish.execute(data);
    completionSuccessDialog.openDialog();
    props.onCancel();
    props.onComplete?.();
  };

  return (
    <>
      <FormDialog
        {...props}
        onSubmit={handleSubmit}
        loading={benefitMutations.publish.loading}
        submitLabel={t('benefits.setPublishDate')}
        width={480}
      >
        <PublishDateField
          deactivationDate={benefit.data?.deactivatedAt}
          required
          defaultValue={moment().toISOString(true)}
        />
      </FormDialog>
      <SuccessDialog
        open={completionSuccessDialog.open}
        onClose={completionSuccessDialog.closeDialog}
      >
        <DialogContent>
          <Typography variant="h2" align="center">
            {t('benefits.benefitPublishedSuccessfully')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Stack width="100%" gap="10px">
            <AddBenefits label={t('benefits.createAnotherBenefit')} />
            <ButtonOld onClick={() => history.push(ROUTES.benefits.list)}>
              {t('benefits.goToBenefitsList')}
            </ButtonOld>
          </Stack>
        </DialogActions>
      </SuccessDialog>
    </>
  );
};

export default PublishDialog;
