import { FC, ReactNode, useState } from 'react';
import {
  Box,
  ButtonBase,
  Collapse,
  Paper,
  Stack,
  SxProps,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { SystemColors } from 'melp-design/style';
import { Typography } from 'melp-design/components';

interface Props {
  title?: ReactNode;
  subtitle?: string;
  sx?: SxProps;
  collapsible?: boolean;
  isInitialExpanded?: boolean;
  side?: ReactNode;
}

export const Panel: FC<Props> = ({
  title,
  subtitle,
  collapsible = false,
  isInitialExpanded = true,
  side,
  sx,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(isInitialExpanded);

  const header = (
    <Stack
      direction="row"
      gap={1}
      alignItems="start"
      justifyContent="space-between"
      flexGrow={1}
    >
      <Stack gap={1} sx={{ textAlign: 'left' }}>
        {title ? <Typography variant="h2">{title}</Typography> : null}
        {subtitle ? (
          <Typography variant="p1" color="textSecondary">
            {subtitle}
          </Typography>
        ) : null}
      </Stack>
      <Box>
        {side}
        {collapsible && (
          <ExpandMore
            sx={{
              marginTop: 1,
              transition: 'transform 0.2s',
              transform: `rotate(${isExpanded ? 180 : 0}deg)`,
              color: SystemColors.grey[55],
            }}
          />
        )}
      </Box>
    </Stack>
  );

  return (
    <Paper variant="outlined" sx={{ padding: 5, ...sx }}>
      {collapsible ? (
        <ButtonBase
          sx={{ width: '100%', justifyContent: 'start' }}
          disableRipple
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          {header}
        </ButtonBase>
      ) : (
        header
      )}

      <Collapse in={isExpanded}>
        <Box mt={!!title || !!subtitle ? '30px' : 0}>{children}</Box>
      </Collapse>
    </Paper>
  );
};
