import { ChangeEvent, useCallback, useState, useEffect, useMemo } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { NumberInput } from '@mp-react/form';
import { useCurrency } from '../../../utils/useCurrency';

const isDefined = (v?: number): v is number => typeof v === 'number';

interface Props {
  value?: number | null;
  currency?: string;
  onChange?: (value: number | null) => void;
  canBeNegative?: boolean;
  max?: number;
}

const CurrencyInput = ({
  value,
  onChange,
  max,
  canBeNegative,
  currency,
  ...rest
}: Props & Pick<TextFieldProps, 'placeholder' | 'fullWidth' | 'size'>) => {
  const { getDefaultCurrencyNumber } = useCurrency();
  const inputProps = useMemo(
    () => ({
      negative: canBeNegative,
      decimalPoint: 2,
    }),
    [canBeNegative],
  );
  const InputProps = useMemo(
    () => ({
      inputComponent: NumberInput,
      endAdornment: currency,
    }),
    [currency],
  );

  const toInputValue = useCallback(
    (stateValue?: number | null) => {
      if (stateValue === null || stateValue === undefined) {
        return '';
      }
      if (stateValue === 0) {
        return '0';
      }
      return getDefaultCurrencyNumber(stateValue);
    },
    [getDefaultCurrencyNumber],
  );

  const formatInputValue = (valueToFormat: string) => {
    return getDefaultCurrencyNumber(valueToFormat.replace(',', '.'));
  };

  const [inputValue, setInputValue] = useState(toInputValue(value));

  useEffect(() => {
    const formattedValue = toInputValue(value);
    if (formattedValue !== inputValue) {
      setInputValue(formattedValue);
    }
    // ignore inputValue changes to perform only one-way synchronization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toInputValue, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let valueToSet = e.target.value;
    const isMinus = valueToSet === '-';
    if (isMinus && !canBeNegative) {
      return;
    }
    const endsWithDecimalSeparator = ['.', ','].includes(valueToSet.slice(-1));
    // Value can be parsed to number
    if (!endsWithDecimalSeparator && !isMinus) {
      const valueAsNumber = parseFloat(valueToSet);
      if (isDefined(max) && valueAsNumber > max) {
        valueToSet = String(max);
      }
      onChange?.(valueToSet === '' ? null : parseFloat(valueToSet));
    }
    const newInputValue = formatInputValue(valueToSet);
    if (inputValue !== newInputValue) {
      setInputValue(newInputValue);
    }
  };

  const handleBlur = () => {
    if (inputValue === '-') {
      setInputValue('');
    }
    if (inputValue.slice(-1) === '.') {
      setInputValue(inputValue.slice(0, -1));
    }
  };

  return (
    <TextField
      // fullWidth={true}
      {...rest}
      InputLabelProps={{ shrink: true }}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={inputProps}
      InputProps={InputProps as any}
      variant="outlined"
    />
  );
};

export default CurrencyInput;
