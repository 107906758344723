import { Svg, Path } from '@react-pdf/renderer';

interface Props {
  color: string;
}

const DonePDFIcon = ({ color }: Props) => (
  <Svg width="14" height="14" viewBox="0 0 14 14">
    <Path
      d="M7.00008 12.8346C10.2217 12.8346 12.8334 10.223 12.8334 7.0013C12.8334 3.77964 10.2217 1.16797 7.00008 1.16797C3.77842 1.16797 1.16675 3.77964 1.16675 7.0013C1.16675 10.223 3.77842 12.8346 7.00008 12.8346Z"
      stroke={color}
      strokeLineCap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M4.66675 7.1843L6.66675 9.04036L9.33342 4.95703"
      stroke={color}
      strokeLineCap="round"
      stroke-linejoin="round"
    />
  </Svg>
);

export default DonePDFIcon;
