import { IAdminBaseBrandCard, IAdminBrandCard } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { loaders } from './admin-brand-cards.loaders';
import { AdminBaseBrandCard, AdminBrandCard } from './admin-brand-cards.types';

const convertBaseCard = (card: IAdminBaseBrandCard): AdminBaseBrandCard => {
  return {
    id: card.id,
    sequence: card.sequence,
    externalId: card.externalId,
    status: card.status,
    brand: card.brandId ? { id: card.brandId, name: card.brandName } : null,
    countries: card.countries,
    provider: card.providerId,
    ordersCount: card.orderCount,
    priceType: card.faceValueType,
    language: card.language ?? 'EN',
    faceValues: card.faceValues,
    faceValuesLabels: card.faceValuesLabels ?? [],
    faceValueMin: card.faceValueMin,
    faceValueMax: card.faceValueMax,
    faceValueCurrency: card.faceValueCurrency,
    purchasePriceDiscountPercentage: card.purchasePriceDiscountPercentage / 100,
    purchasePriceDiscountFee: card.purchasePriceDiscountFee,
    purchasePriceCurrency: card.purchasePriceCurrency,
    melpFeeEuro: card.melpFeeEuro,
    melpFeePercentage: card.melpFeePercentage / 100,
    createdAt: card.createdAt,
    updatedAt: card.updatedAt ?? null,
  };
};

const convertCard = (card: IAdminBrandCard): AdminBrandCard => {
  const supportedLanguages = card.content.length
    ? card.content.map((trans) => trans.language)
    : [card.language ?? 'EN'];

  return {
    ...convertBaseCard(card),
    supportedLanguages,
    content: card.content.length
      ? card.content
      : [
          {
            language: card.language ?? 'EN',
            label: card.label ?? '',
            description: card.description ?? '',
            terms: card.terms ?? '',
            redeemInstructions: card.redeemInstructions ?? '',
          },
        ],
    prices: card.faceValues.map((value, i) => ({
      faceValue: value,
      content: supportedLanguages.map((language) => {
        const label = card.content.length
          ? card.content.find((c) => c.language === language)?.faceValuesLabels[
              i
            ] ?? ''
          : card.faceValuesLabels?.[i] ?? '';

        return {
          language,
          faceValuesLabel: label,
        };
      }),
    })),
    raw: card.raw || '{}',
  };
};

const convertCards = (cards: IAdminBaseBrandCard[]): AdminBaseBrandCard[] => {
  return cards.map(convertBaseCard);
};

export const converters = {
  getCards: ({ data }: Awaited<ReturnType<typeof loaders.getCards>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertCards(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getCard: ({ data }: Awaited<ReturnType<typeof loaders.getCard>>) => {
    return convertCard(data);
  },
};
