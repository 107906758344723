import { FormField } from 'melp-design/components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Benefit } from '../../../../types/Benefits';

interface Props {
  deactivationDate?: Benefit['deactivatedAt'];
  required: boolean;
  defaultValue?: string;
}

const PublishDateField = ({ deactivationDate, required, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <FormField
      name="publishedAt"
      render={{
        type: 'dateTime',
        props: {
          maxDateTime: deactivationDate ? moment(deactivationDate) : undefined,
          showTimeOffset: true,
        },
      }}
      label={t('benefits.publishDate')}
      rules={{
        required,
        validate: (value: string | null) => {
          if (
            value &&
            deactivationDate &&
            moment(value).isAfter(deactivationDate)
          ) {
            return t('benefits.publishDateAfterDeactivationDate');
          }
        },
      }}
      defaultValue={props.defaultValue ?? null}
    />
  );
};

export default PublishDateField;
