import { DateRange } from 'types/Common';

export const administratorStatuses = ['active', 'inactive'] as const;
export type AdministratorStatus = (typeof administratorStatuses)[number];

export const administratorRoles = [
  'hrAdmin',
  'melpAdmin',
  'hrProgrammatic',
] as const;
export type AdministratorRole = (typeof administratorRoles)[number];

export interface BaseAdministrator {
  id: string;
  status: AdministratorStatus;
  email: string;
  fullName: string;
  phone: string;
  parentCompanyId: string;
}

export interface Administrator {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  status: AdministratorStatus;
  role: AdministratorRole;
  root: boolean;
  parentCompanyId: string;
  employeeId: string;
  apiKeyAbbreviated: string;
  clientIntegrationId: string;
}

export interface AdministratorsFilters {
  date: DateRange | undefined;
}
