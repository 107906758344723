import { FormDialogProps } from 'melp-design/components';
import { Document, pdf } from '@react-pdf/renderer';
import fileDownloader from 'js-file-download';
import { IFile } from 'types/income';
import { resolveTranslation } from 'utils/general';
import { useCompanyLanguages } from 'state/Administrators';
import { makeRequest } from '../../../api/api';
import { Endpoints } from '../../../api/constants';
import { useBenefit, useBenefitContent } from '../../../state/Benefits';
import { BenefitMarketplaceItemsResponse } from '../../../types/MarketplaceItems';
import { useLoading } from '../../../utils/Loading';
import { useCurrency } from '../../../utils/useCurrency';
import { useIdParam } from '../../../utils/useIdParam';
import BenefitPDFDownloadDialog from './BenefitPDFDownloadDialog';
import BenefitPDF from './pages/benefit/BenefitPDF';

interface Props extends Pick<FormDialogProps, 'open' | 'onCancel'> {}

const SingleBenefitPDFDownloader = (props: Props) => {
  const id = useIdParam();
  const benefit = useBenefit(id);
  const benefitContent = useBenefitContent(id);
  const { currency } = useCurrency();
  const generationState = useLoading();
  const { defaultLanguage } = useCompanyLanguages();

  // Not ideal but currently this component is used only when benefit is
  // already loaded. So this is just to avoid compilation errors.
  if (benefit.loading || benefitContent.loading) return null;
  if (!benefit.data || !benefitContent.data) return null;

  const languages =
    benefitContent.data.translations.map(
      (translation) => translation.language,
    ) ?? [];

  const fetchMarketplaceData = async (language: string) => {
    if (!benefit.data) {
      throw new Error('Cannot render PDF with no benefit data.');
    }
    const assignedItemsResponse =
      await makeRequest<BenefitMarketplaceItemsResponse>(
        'get',
        Endpoints.clientAdmin.benefitPlans.byId(benefit.data.id)
          .marketplaceItems.root,
      );
    return {
      assignedItemsCount: assignedItemsResponse.data.count,
      firstItems: assignedItemsResponse.data.data
        .slice(0, 3)
        .map(
          (item) =>
            resolveTranslation(item.translations, language, defaultLanguage)
              ?.title ?? '',
        ),
    };
  };

  const handleSelection = async (language: string) => {
    if (!benefit.data || !benefitContent.data) {
      throw new Error('Cannot render PDF with no benefit data.');
    }
    generationState.startLoading();
    try {
      let image: IFile | undefined = undefined;
      if (benefitContent.data.image?.id) {
        const imageResponse = await makeRequest<IFile>(
          'get',
          Endpoints.files.byId.replace(':fileId', benefitContent.data.image.id),
        );
        image = imageResponse.data;
      }
      const marketplaceData = await fetchMarketplaceData(language);
      const blob = await pdf(
        <Document>
          <BenefitPDF
            language={language}
            currency={currency}
            data={{
              ...benefit.data,
              ...benefitContent.data,
              image,
            }}
            marketplaceData={marketplaceData}
          />
        </Document>,
      ).toBlob();
      const translation = resolveTranslation(
        benefitContent.data.translations,
        language,
        defaultLanguage,
      );
      const fileName = translation?.title ?? benefit.data.name;
      fileDownloader(blob, `${fileName}.pdf`);
      props.onCancel();
    } finally {
      generationState.stopLoading();
    }
  };

  return (
    <BenefitPDFDownloadDialog
      {...props}
      onSubmit={(data) => handleSelection(data.language)}
      languages={languages}
    />
  );
};

export default SingleBenefitPDFDownloader;
