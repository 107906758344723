import { z } from 'zod';
import {
  dateRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { binaries } from 'types/Common';
import { employmentStatuses } from './employees.types';

export const employeesFiltersSchema = z
  .object({
    name: z.string().catch(''),
    email: z.string().catch(''),
    phone: z.string().catch(''),
    employees: z.array(z.string()).catch([]),
    employeeGroups: z.array(z.string()).catch([]),
    status: z.array(z.enum(employmentStatuses)).catch([]),
    hiredAt: dateRangeSchema,
    customCategory: z.string().catch(''),
    customCategoryValues: z.array(z.string()).catch([]),
    isAppUser: z.enum(binaries).nullable().catch(null),
    companies: z.array(z.string()).catch([]),
    departments: z.array(z.string()).catch([]),
    birthdaySoon: z.enum(binaries).nullable().catch(null),
    workAnniversarySoon: z.enum(binaries).nullable().catch(null),
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);

export const employeeJobTitlesFiltersSchema = z
  .object({
    search: z.string().catch(''),
  })
  .merge(paginationFiltersSchema);
