import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from 'melp-design/components';
import { Settings } from 'melp-design/icons';
import { CircularProgress, IconButton, Stack, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FilterButton, Filters } from 'components/filters';
import { DialogTitle } from '../../../../melp-design/components/Dialog/Dialog';
import {
  useAssignmentFilter,
  useLazyBenefitMarketplaceItems,
} from '../../../../state/MarketplaceItems.clientAdmin';
import { MarketplaceItemsParams } from '../../../../types/MarketplaceItems';
import { useDialog } from '../../../../utils/Dialog';
import { convertToFilterQueryParams } from '../../../../utils/Filters';
import { useLoading } from '../../../../utils/Loading';
import ItemsFilters from './ItemsFilters';
import { MarketplaceItemsFilters } from './Types';
import { filtersToParams, paramsToFilters } from './Utils';

interface MarketplaceSettingsFilters extends MarketplaceItemsFilters {
  filterAll?: boolean;
}

const toSettingsFilters = (
  params?: MarketplaceItemsParams,
): MarketplaceSettingsFilters | undefined => {
  if (!params) {
    return undefined;
  }
  if (Object.keys(params).length === 0) {
    return { filterAll: true };
  }
  return paramsToFilters(params);
};

interface Props {
  benefitId: string;
  onSubmitCompleted: () => void;
  onFilterCreated: () => void;
  disabled?: boolean;
}

const INITIAL_FILTERS: MarketplaceItemsFilters = {
  benefitPlanTypes: [],
  categories: [],
  countries: [],
  cities: [],
  itemIds: [],
  supplierIds: [],
  price: null,
};

const MarketplaceSettings = (props: Props) => {
  const { t } = useTranslation();
  const settingsDialog = useDialog();

  const assignmentFilter = useAssignmentFilter(props.benefitId);

  const paramsFromServer = assignmentFilter.data?.assignmentFilter;
  const filtersFromServer = toSettingsFilters(paramsFromServer);

  const [localFilters, setLocalFilters] = useState<MarketplaceSettingsFilters>(
    {},
  );
  const [hasChanges, setHasChanges] = useState(false);
  const { fetchItems } = useLazyBenefitMarketplaceItems(props.benefitId);
  const [filteredItemsCount, setFilteredItemsCount] = useState<number>();

  useEffect(() => {
    setLocalFilters(filtersFromServer ?? {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFromServer]);

  const resetState = () => {
    setHasChanges(false);
    setFilteredItemsCount(undefined);
  };

  const itemsRequestState = useLoading();
  const handleFiltersChange = async (
    newFilters: MarketplaceSettingsFilters,
  ) => {
    // filters were cleared
    if (Object.keys(newFilters).length === 0) {
      setLocalFilters({});
      setHasChanges(!!filtersFromServer);
      setFilteredItemsCount(undefined);
    } else {
      const { filterAll, ...otherFilters } = newFilters;
      let filtersToSet: MarketplaceSettingsFilters;
      if (filterAll !== localFilters.filterAll && filterAll) {
        filtersToSet = { filterAll };
      } else {
        filtersToSet = otherFilters;
      }
      setLocalFilters(filtersToSet);
      setHasChanges(true);
      try {
        itemsRequestState.startLoading();
        const params = filtersToParams(
          filtersToSet.filterAll ? {} : otherFilters,
        );
        const response = await fetchItems(params);
        setFilteredItemsCount(response.count);
      } finally {
        itemsRequestState.stopLoading();
      }
    }
  };

  const handleCancel = () => {
    settingsDialog.closeDialog();
    setLocalFilters(filtersFromServer ?? {});
    resetState();
  };

  const handleSubmit = async () => {
    if (!filtersFromServer && Object.keys(localFilters).length) {
      // create
      const { filterAll, ...otherFilters } = localFilters;
      const paramsToSave = filtersToParams(filterAll ? {} : otherFilters);
      const query = convertToFilterQueryParams(paramsToSave);
      await assignmentFilter.create.execute(undefined, query);
      props.onFilterCreated();
      toast.success(t('marketplace.configCreateSuccess'));
    } else if (filtersFromServer && Object.keys(localFilters).length) {
      // update
      const { filterAll, ...otherFilters } = localFilters;
      const paramsToSave = filtersToParams(filterAll ? {} : otherFilters);
      const query = convertToFilterQueryParams(paramsToSave);
      await assignmentFilter.update.execute(undefined, query);
      toast.success(t('marketplace.configChangeSuccess'));
    } else if (filtersFromServer && !Object.keys(localFilters).length) {
      // remove
      await assignmentFilter.remove.execute();
      toast.success(t('marketplace.configRemoveSuccess'));
    } else {
      throw Error('Unhandled state');
    }
    settingsDialog.closeDialog();
    resetState();
    props.onSubmitCompleted();
  };

  return (
    <>
      <Tooltip title={t('marketplace.configuration')}>
        <span>
          <IconButton
            sx={{ marginRight: '-5px' }}
            onClick={settingsDialog.openDialog}
            disabled={assignmentFilter.loading || props.disabled}
          >
            <Badge
              variant="dot"
              overlap="circular"
              invisible={!filtersFromServer}
            >
              {assignmentFilter.loading ? (
                <CircularProgress size={20} />
              ) : (
                <Settings />
              )}
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        width={650}
        open={settingsDialog.open}
        onClose={handleCancel}
        PaperProps={{
          sx: {
            transform: 'translateY(-100px)',
          },
        }}
        disableEnforceFocus
      >
        <DialogTitle>{t('marketplace.autoAssignment')}</DialogTitle>
        <DialogContent>
          <Stack gap="20px">
            <Typography variant="p1" color="textSecondary">
              {t('marketplace.autoAssignmentDescription')}
            </Typography>
            <Filters
              value={localFilters}
              initialValues={INITIAL_FILTERS}
              onChange={handleFiltersChange}
            >
              {({ value, initialValues, setFilter }) => (
                <>
                  <FilterButton
                    label={t('marketplace.allItemsFilter')}
                    value={value.filterAll}
                    onChange={(newValue) => setFilter('filterAll', newValue)}
                  />
                  <ItemsFilters
                    filters={localFilters}
                    initialValues={initialValues}
                    onFilterChange={setFilter}
                  />
                </>
              )}
            </Filters>
            {filteredItemsCount === 0 && !itemsRequestState.loading && (
              <Alert severity="warning">
                {t('marketplace.noItemsFoundForCombination')}
              </Alert>
            )}
          </Stack>
        </DialogContent>

        <DialogActions>
          {filtersFromServer ? (
            <Button
              label={t('recognitions.rewards.actions.remove_config')}
              variant="danger-outline"
              onClick={async () => {
                await assignmentFilter.remove.execute();
                toast.success(t('marketplace.configRemoveSuccess'));
              }}
            />
          ) : null}

          <Button
            label={t('marketplace.discardSettings')}
            variant="neutral-outline"
            onClick={handleCancel}
          />

          {hasChanges ? (
            <Button
              label={
                filteredItemsCount
                  ? t('marketplace.confirmSettings', {
                      count: filteredItemsCount,
                    })
                  : t('common.confirm')
              }
              onClick={handleSubmit}
              disabled={
                itemsRequestState.loading || assignmentFilter.actionInProgress
              }
            />
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MarketplaceSettings;
