import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Button } from 'melp-design/components';
import { CurrencyInput } from '../inputs';
import { isDefined } from '../../utils/isDefined';
import { NumberRange } from '../../types/Common';
import SortButton from './SortButton';
import { SortOrder } from './Types';

interface Props {
  value?: NumberRange;
  onApplyFilter: (value?: NumberRange) => void;
  onClearFilter: () => void;
  onCancel: () => void;
  sortValue?: SortOrder;
  onSort?: (order: SortOrder) => void;
  currency?: string;
}

const CurrencyRangeFilter = ({
  value,
  onApplyFilter,
  onClearFilter,
  currency,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const [localFrom, setLocalFrom] = useState(value?.from);
  const [localTo, setLocalTo] = useState(value?.to);

  const valuesUndefined = !isDefined(localFrom) && !isDefined(localTo);

  const handleApplyFilter = () => {
    if (valuesUndefined) {
      onApplyFilter(undefined);
    } else {
      onApplyFilter({ from: localFrom, to: localTo });
    }
  };

  return (
    <Box display="flex" flexDirection="column" p={1} style={{ gap: '8px' }}>
      <CurrencyInput
        value={localFrom}
        onChange={(newValue) => setLocalFrom(newValue ?? undefined)}
        placeholder={t('table.from')}
        fullWidth={true}
        size="small"
        currency={currency}
      />
      <CurrencyInput
        value={localTo}
        onChange={(newValue) => setLocalTo(newValue ?? undefined)}
        placeholder={t('table.to')}
        fullWidth={true}
        size="small"
        currency={currency}
      />
      <Box display="flex" justifyContent="flex-end" style={{ gap: '8px' }}>
        {props.onSort && (
          <SortButton
            type="numeric"
            value={props.sortValue}
            onChange={props.onSort}
          />
        )}
        {value && (
          <Button
            label={t('table.clear')}
            variant="neutral-outline"
            onClick={onClearFilter}
          />
        )}
        <Button
          label={t('common.cancel')}
          variant="neutral-outline"
          onClick={props.onCancel}
        />
        <Button
          label={t('common.applyFilter')}
          variant="primary"
          disabled={valuesUndefined}
          onClick={handleApplyFilter}
        />
      </Box>
    </Box>
  );
};

export default CurrencyRangeFilter;
