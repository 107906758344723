import { useEffect } from 'react';
import { useUnsavedChangesContext } from './UnsavedChangesContext';

interface Props {
  hasChanges: boolean;
}

const UnsavedChangesTracker = ({ hasChanges }: Props) => {
  const { unsavedChangesExist, setUnsavedChangesExist } =
    useUnsavedChangesContext();

  useEffect(() => {
    if (hasChanges !== unsavedChangesExist) {
      setUnsavedChangesExist(hasChanges);
    }
  }, [hasChanges, setUnsavedChangesExist, unsavedChangesExist]);

  return null;
};

export default UnsavedChangesTracker;
