import { Colors } from 'melp-design/style';
import { Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from 'types/general';
import { resolveTranslation } from 'utils/general';
import { useCompanyLanguages } from 'state/Administrators';
import { currencyFormatter } from '../../../../../utils/Currency';
import { styles as globalStyles } from '../../Styles';
import { useInvestmentPeriodLabels } from '../../Utils';
import AppStorePDFIcon from '../../icons/AppStorePDFIcon';
import CalendarPDFIcon from '../../icons/CalendarPDFIcon';
import CoinsPDFIcon from '../../icons/CoinsPDFIcon';
import CoinsPercentPDFIcon from '../../icons/CoinsPercentPDFIcon';
import LogoPDFIcon from '../../icons/LogoPDFIcon';
import GooglePlay from '../../images/google-play.png';
import { CombinedBenefitData } from '../../types';
import { richTextAsPlainText } from '../../../../../utils/richTextAsPlainText';

const styles = StyleSheet.create({
  page: {
    paddingBottom: '2cm',
  },
  title: {
    fontSize: 26,
    fontWeight: 500,
    marginBottom: 7,
  },
  subtitle: {
    fontSize: 10,
    marginBottom: 15,
  },
  list: {
    flexDirection: 'column',
  },
  panel: {
    border: `1pt solid ${Colors.elementsGrey}`,
    borderRadius: 10,
    padding: 5,
    marginBottom: 7,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  imageContainer: {
    border: `1pt solid ${Colors.elementsGrey}`,
    borderRadius: 6,
    width: 90,
    height: 90,
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
    objectFit: 'cover',
  },
  contentContainer: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexGrow: 1,
    // Defined the width explicitly to fix text overflow issue.
    // For more details see https://github.com/diegomura/react-pdf/issues/2182#issuecomment-1466062814
    width: '100%',
  },
  panelTopSection: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  panelTitle: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 5,
    maxWidth: '50%',
  },
  moneySection: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  value: {
    marginLeft: 10,
  },
  moneyIconContainer: {
    height: 20,
    width: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: Colors.primaryLight,
    marginRight: 6,
    marginLeft: 10,
  },
  term: {
    paddingTop: 1,
    marginLeft: 3,
  },
  description: {
    marginTop: 6,
    fontSize: 7,
    whiteSpace: 'pre-line',
  },
  pageReference: {
    marginTop: 6,
    textDecoration: 'none',
  },
  footer: {
    alignItems: 'center',
  },
  melpLogo: {
    marginHorizontal: 2,
  },
  appLogos: {
    marginLeft: 20,
  },
  googlePlayLogo: {
    marginLeft: 6,
  },
});

interface Props {
  benefits: CombinedBenefitData[];
  /**
   * Data for table of contents.
   * Map with benefit id and page number pairs.
   */
  pageReferences?: Map<string, number>;
  language: string;
  currency: Currency;
}

const SummaryPage = (props: Props) => {
  const { t } = useTranslation();
  const { defaultLanguage } = useCompanyLanguages();

  const PageHeader = () => (
    <View
      fixed
      style={[
        globalStyles.header,
        globalStyles.smallText,
        globalStyles.secondaryText,
      ]}
    >
      <Text>{moment().format('ll')}</Text>
    </View>
  );

  const PageFooter = () => (
    <View fixed style={[globalStyles.footer, styles.footer]}>
      <View
        style={[
          globalStyles.row,
          globalStyles.smallText,
          globalStyles.secondaryText,
        ]}
      >
        <Text>{t('benefits.pdfForMoreInfo')}</Text>
        <View style={styles.melpLogo}>
          <LogoPDFIcon color={Colors.grey} />
        </View>
        <Text>{t('benefits.pdfApp')}</Text>
        <View style={[globalStyles.row, styles.appLogos]}>
          <AppStorePDFIcon />
          <Image
            src={GooglePlay}
            style={[globalStyles.googlePlayLogo, styles.googlePlayLogo]}
          />
        </View>
      </View>
    </View>
  );

  const renderBenefitMoneyInfoItem = (
    Icon: ComponentType<{ color: string; fillColor: string }>,
    label: string,
    amount: number,
  ) => {
    if (!amount) {
      return null;
    }

    return (
      <View style={globalStyles.row}>
        <View style={styles.moneyIconContainer}>
          <Icon color={Colors.primary} fillColor={Colors.primaryLight} />
        </View>
        <View>
          <Text style={[globalStyles.smallText, globalStyles.secondaryText]}>
            {label}
          </Text>
          <Text>
            {currencyFormatter.formatFractionalUnitAmount(
              amount,
              props.currency,
            )}
          </Text>
        </View>
      </View>
    );
  };

  const investmentPeriodLabels = useInvestmentPeriodLabels();

  const renderBenefitPanel = (benefit: CombinedBenefitData) => {
    const translation = resolveTranslation(
      benefit.translations,
      props.language,
      defaultLanguage,
    );
    const imageUrl = benefit.image?.signedUrl;
    const pageRef = props.pageReferences?.get(benefit.id);

    const renderDescription = () => {
      if (!translation?.description.length) {
        return null;
      }

      const plainText = richTextAsPlainText(translation.description);

      return <Text style={styles.description}>{plainText}</Text>;
    };

    return (
      <View key={benefit.id} style={styles.panel} wrap={false}>
        {!!imageUrl?.length && (
          <View style={styles.imageContainer}>
            <Image src={imageUrl} style={styles.image} />
          </View>
        )}
        <View style={styles.contentContainer}>
          <View style={styles.panelTopSection}>
            <Text style={styles.panelTitle}>
              {translation?.title ?? benefit.name}
            </Text>
            <View style={styles.moneySection}>
              {benefit.isVisibleForEmployees &&
                renderBenefitMoneyInfoItem(
                  CoinsPercentPDFIcon,
                  t('benefits.benefitPDFSummaryInvestment', {
                    period: investmentPeriodLabels.resolveLabel(benefit),
                  }),
                  benefit.investmentAmount ?? 0,
                )}
              {renderBenefitMoneyInfoItem(
                CoinsPDFIcon,
                t('benefits.value'),
                benefit.value ?? 0,
              )}
            </View>
          </View>
          <View style={globalStyles.row}>
            <CalendarPDFIcon color={Colors.primary} />
            <Text style={[styles.term, globalStyles.smallText]}>
              {t('common.rangeFormat', {
                from: benefit.activatedAt
                  ? moment(benefit.activatedAt).format('ll')
                  : '',
                to: benefit.deactivatedAt
                  ? moment(benefit.deactivatedAt).format('ll')
                  : '',
              })}
            </Text>
          </View>
          {renderDescription()}
          <Link
            style={[
              globalStyles.smallText,
              globalStyles.secondaryText,
              styles.pageReference,
            ]}
            src={`#${pageRef}`}
          >
            {t('benefits.learnMoreOnPage', {
              pageNumber: pageRef,
            })}
          </Link>
        </View>
      </View>
    );
  };

  // New property is not defined in types yet.
  // Should be removed after resolving:
  //    https://github.com/diegomura/react-pdf/issues/1888
  const untypedProps: any = {
    bookmark: {
      title: t('benefits.pdfSummaryPage'),
      fit: true,
    },
  };

  return (
    <Page size="A4" style={[globalStyles.page, styles.page]} wrap>
      <PageHeader />
      <Text style={styles.title} {...untypedProps}>
        {t('benefits.benefitsPDFSummaryPageTitle')}
      </Text>
      <Text style={styles.subtitle}>
        {t('benefits.benefitsPDFSummaryPageSubtitle')}
      </Text>
      <View style={styles.list}>{props.benefits.map(renderBenefitPanel)}</View>
      <PageFooter />
    </Page>
  );
};

export default SummaryPage;
