import { Modal, SelectInput } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';

interface Values {
  category: string;
}

interface Props extends ModalProps {
  closeModal: (
    param?: { action: 'CLOSE' } | { action: 'CONFIRM'; category: string },
  ) => void;
  options: Array<{ id: string; name: string }>;
  selected: string;
}

export const CustomCategorySelectModal: FC<Props> = ({
  closeModal,
  options,
  selected,
}) => {
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm<Values>({
    defaultValues: {
      category: selected,
    },
  });

  const onSubmit: SubmitHandler<Values> = ({ category }) => {
    closeModal({ action: 'CONFIRM', category });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('employees.choose_custom_column')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
          },
        ]}
      >
        <Controller
          name="category"
          control={control}
          render={({ field: { name, value, onChange, ref } }) => {
            return (
              <SelectInput
                label={t('employees.customCategories')}
                name={name}
                value={value}
                onChange={onChange}
                options={options.map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
                required
                ref={ref}
              />
            );
          }}
        />
      </Modal.Content>
    </form>
  );
};
