import { FormField, OptionsItem } from 'melp-design/components';
import { useFormContext } from 'react-hook-form';
import { BenefitInvestmentFrequency } from '../../../../types/Benefits';
import { BenefitSettingsFormData } from './types';
import { useInvestmentFrequencyLabels } from './useInvestmentFrequencyLabels';

const InvestmentFrequencyField = () => {
  const investmentFrequencyLabels = useInvestmentFrequencyLabels();
  const { setValue } = useFormContext<BenefitSettingsFormData>();

  return (
    <FormField
      name="investmentPeriod"
      render="select"
      defaultValue="once"
      onChange={(newValue) => {
        if (newValue === 'once') {
          setValue('isAccumulating', false);
        }
      }}
    >
      {(['once', 'monthly'] as BenefitInvestmentFrequency[]).map((key) => (
        <OptionsItem key={key} value={key}>
          {investmentFrequencyLabels[key]}
        </OptionsItem>
      ))}
    </FormField>
  );
};

export default InvestmentFrequencyField;
