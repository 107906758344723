import { Colors } from 'melp-design/style';
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { Currency } from 'types/general';
import { resolveTranslation } from 'utils/general';
import { useCompanyLanguages } from 'state/Administrators';
import { currencyFormatter } from '../../../../../utils/Currency';
import { isDefined } from '../../../../../utils/isDefined';
import { richTextAsPlainText } from '../../../../../utils/richTextAsPlainText';
import { styles } from '../../Styles';
import { useInvestmentPeriodLabels } from '../../Utils';
import AppStorePDFIcon from '../../icons/AppStorePDFIcon';
import CalendarPDFIcon from '../../icons/CalendarPDFIcon';
import CategoriesPDFIcon from '../../icons/CategoriesPDFIcon';
import DonePDFIcon from '../../icons/DonePDFIcon';
import DownloadPDFIcon from '../../icons/DownloadPDFIcon';
import LinkPDFIcon from '../../icons/LinkPDFIcon';
import LogoPDFIcon from '../../icons/LogoPDFIcon';
import GooglePlay from '../../images/google-play.png';
import MELPAppQRCode from '../../images/melp-app-qr-code.png';
import { CombinedBenefitData } from '../../types';

const EMPTY_CELL_VALUE = '-';

interface BenefitMarketplaceData {
  assignedItemsCount: number;
  firstItems: string[];
}

interface Props {
  data: CombinedBenefitData;
  marketplaceData?: BenefitMarketplaceData;
  language: string;
  currency: Currency;
  onRenderPage?: (pageNumber: number) => void;
}

/**
 * Benefit PDF document
 *
 * @param props
 * @returns
 */
const BenefitPDF = ({ data, marketplaceData, ...props }: Props) => {
  const { t } = useTranslation();
  const { defaultLanguage } = useCompanyLanguages();

  const translation = resolveTranslation(
    data.translations,
    props.language,
    defaultLanguage,
  );
  const benefitTitle = translation?.title ?? data.name;

  const PageHeader = () => (
    <View fixed style={[styles.header, styles.smallText, styles.secondaryText]}>
      <Text
        render={({ subPageNumber, subPageTotalPages }) =>
          `${subPageNumber}/${subPageTotalPages} ${benefitTitle}`
        }
      />
      {/* Page anchor to enable internal document links */}
      <View render={({ pageNumber }) => <View id={String(pageNumber)} />} />
      <Text
        render={({ pageNumber }) => {
          props.onRenderPage?.(pageNumber);
          return t('benefits.pdfPageCounter', { pageNumber });
        }}
      />
    </View>
  );

  const Timeline = () => {
    const activationDatePresent = isDefined(data.activatedAt);
    const deactivationDatePresent = isDefined(data.deactivatedAt);

    if (!activationDatePresent && !deactivationDatePresent) {
      return null;
    }

    return (
      <View style={styles.timeline}>
        {activationDatePresent && (
          <View style={styles.timelinePoint}>
            <CalendarPDFIcon color={Colors.primary} />
          </View>
        )}
        {activationDatePresent && deactivationDatePresent && (
          <View style={styles.timelineLine} />
        )}
        {deactivationDatePresent && (
          <View style={styles.timelinePoint}>
            <CalendarPDFIcon color={Colors.primary} />
          </View>
        )}
      </View>
    );
  };

  const formatTermDate = (date: string) => moment(date).format('l');
  const imageUrl = data.image?.signedUrl;
  const termRangeLimitValueStyle = { minWidth: imageUrl ? '30%' : '21%' };

  const TopSection = () => (
    <View style={[styles.topSection]}>
      <View
        style={[
          styles.panel,
          imageUrl ? { width: '60%', minHeight: 125 } : { width: '100%' },
        ]}
      >
        <Text style={styles.title}>{benefitTitle}</Text>
        <View style={styles.termDetails}>
          <Timeline />
          <View style={styles.termRange}>
            {!!data.activatedAt && (
              <View style={styles.termRangeLimit}>
                <Text style={styles.termRangeLimitLabel}>
                  {t('benefits.activationDate')}
                </Text>
                <Text
                  style={[styles.termRangeLimitValue, termRangeLimitValueStyle]}
                >
                  {formatTermDate(data.activatedAt)}
                </Text>
              </View>
            )}
            {!!data.activatedAt && !!data.deactivatedAt && (
              <View style={styles.divider} />
            )}
            {!!data.deactivatedAt && (
              <View style={styles.termRangeLimit}>
                <Text style={styles.termRangeLimitLabel}>
                  {t('benefits.deactivationDate')}
                </Text>
                <Text
                  style={[styles.termRangeLimitValue, termRangeLimitValueStyle]}
                >
                  {formatTermDate(data.deactivatedAt)}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
      {!!imageUrl && (
        <View style={styles.imagePanel}>
          <Image src={imageUrl} style={styles.image} />
        </View>
      )}
    </View>
  );

  const formatMoney = (amount: number | null) =>
    amount
      ? currencyFormatter.formatFractionalUnitAmount(amount, props.currency)
      : EMPTY_CELL_VALUE;

  const investmentPeriodLabels = useInvestmentPeriodLabels();

  const ValueAndInvestmentSection = () => {
    const showInvestment =
      data.isVisibleForEmployees && !!data.investmentAmount;

    if (!data.value && !showInvestment) {
      return null;
    }

    return (
      <View style={[styles.panel, styles.mediumText]}>
        {!!data.value && (
          <View style={styles.row}>
            <Text style={styles.labelColumn}>{t('benefits.value')}</Text>
            <Text style={styles.valueColumn}>{formatMoney(data.value)}</Text>
          </View>
        )}
        {!!data.value && showInvestment && (
          <View style={[styles.divider, styles.valueAndInvestmentDivider]} />
        )}
        {showInvestment && (
          <>
            <View style={styles.row}>
              <View style={[styles.labelColumn, styles.row]}>
                <Text>
                  {isDefined(data.employeeContribution)
                    ? t('benefits.pdfTotalInvestment')
                    : t('common.investment')}
                </Text>
                <View style={styles.tag}>
                  <Text style={[styles.regularText]}>
                    {investmentPeriodLabels.resolveLabel(data)}
                  </Text>
                </View>
              </View>
              <Text style={styles.valueColumn}>
                {formatMoney(data.investmentAmount)}
              </Text>
            </View>
            {!!data.employeeContribution && !!data.investmentAmount && (
              <View style={[styles.splitInvestment, styles.regularText]}>
                <View style={[styles.row, styles.employerInvestment]}>
                  <Text style={styles.labelColumn}>
                    {t('benefits.employer_investment')}
                  </Text>
                  <Text style={styles.valueColumn}>
                    {formatMoney(
                      data.investmentAmount - data.employeeContribution,
                    )}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.labelColumn}>
                    {t('benefits.employee_investment')}
                  </Text>
                  <Text style={styles.valueColumn}>
                    {formatMoney(data.employeeContribution)}
                  </Text>
                </View>
              </View>
            )}
          </>
        )}
      </View>
    );
  };

  const DescriptionSection = () => {
    if (!translation?.description) {
      return null;
    }

    const plainText = richTextAsPlainText(translation.description);

    return (
      <View style={styles.panel}>
        <Text style={[styles.panelHeader, styles.mediumText]}>
          {t('form.description')}
        </Text>
        <Text>{plainText}</Text>
      </View>
    );
  };

  const LinksSection = () =>
    translation?.links.length ? (
      <View style={styles.panel}>
        <Text style={[styles.panelHeader, styles.mediumText]}>
          {t('benefits.pdfLinks')}
        </Text>
        {translation.links.map((link, index, arr) => (
          <View
            key={link.url}
            style={{ marginBottom: index + 1 === arr.length ? 0 : 10 }}
          >
            <Text style={styles.linkName}>{link.name}</Text>
            <View style={styles.row}>
              <LinkPDFIcon color={Colors.grey} />
              <Link src={link.url} style={[styles.linkUrl, styles.primaryText]}>
                {link.url}
              </Link>
            </View>
          </View>
        ))}
      </View>
    ) : null;

  const AttachmentsSection = () => {
    const attachmentsExist = !!translation?.attachments.length;

    if (!attachmentsExist) {
      return null;
    }

    return (
      <View style={styles.panel}>
        <Text style={[styles.panelHeader, styles.mediumText]}>
          {t('form.attachments')}
        </Text>
        {translation?.attachments
          .filter((attachment) => !!attachment.file?.id)
          .map((attachment) => (
            <Text key={attachment.file?.id} style={styles.primaryText}>
              {attachment.file?.fileName}
            </Text>
          ))}
        <Text
          style={[
            styles.smallText,
            styles.secondaryText,
            styles.attachmentsHint,
          ]}
        >
          {t('benefits.pdfAttachmentsHint')}
        </Text>
      </View>
    );
  };

  const FAQSection = () =>
    translation?.faqs.length ? (
      <View style={styles.panel}>
        <Text style={[styles.panelHeader, styles.mediumText]}>
          {t('form.faq')}
        </Text>
        {translation.faqs.map((faq, index, arr) => (
          <View
            key={index}
            style={{ marginBottom: index + 1 === arr.length ? 0 : 10 }}
          >
            <Text style={styles.faqQuestion}>{faq.question}</Text>
            <Text>{faq.answer}</Text>
          </View>
        ))}
      </View>
    ) : null;

  const MarketplaceSection = () => {
    if (!marketplaceData?.assignedItemsCount) {
      return null;
    }

    const { assignedItemsCount, firstItems } = marketplaceData;

    const itemsCountText = (
      <Trans
        i18nKey="benefits.pdfMarketplaceItemsCount"
        count={assignedItemsCount}
        components={{ b: <Text style={{ fontWeight: 700 }} /> }}
      />
    );

    const itemsListText = ` ${firstItems.join(', ')}${
      assignedItemsCount > 2 ? ` ${t('benefits.pdfMarketplaceOtherItems')}` : ''
    }.`;

    const steps = [
      {
        Icon: DownloadPDFIcon,
        textKey: 'pdfStepDownloadApp',
      },
      {
        Icon: CategoriesPDFIcon,
        textKey: 'pdfStepChooseBenefit',
      },
      {
        Icon: DonePDFIcon,
        textKey: 'pdfStepSelectItem',
      },
    ];

    return (
      <View style={[styles.panel, styles.marketplaceSection]}>
        <View style={styles.itemsInfo}>
          <Text style={[styles.panelHeader, styles.mediumText]}>
            {t('benefits.pdfMarketplace')}
          </Text>
          <Text>
            {itemsCountText}
            {itemsListText}
          </Text>
        </View>
        <View style={styles.qrInfo}>
          <Image src={MELPAppQRCode} style={styles.appQRCode} />
          <Text style={styles.qrHint}>{t('benefits.pdfScanQR')}</Text>
        </View>
        <View style={styles.row}>
          {steps.map(({ Icon, textKey }, index, arr) => (
            <View
              key={index}
              style={[
                styles.step,
                { marginRight: index + 1 === arr.length ? 0 : 25 },
              ]}
            >
              <View style={styles.stepIconWrapper}>
                <Icon color={Colors.primary} />
              </View>
              <Text>{`${index + 1}. ${t(`benefits.${textKey}`)}`}</Text>
            </View>
          ))}
        </View>
      </View>
    );
  };

  const PageFooter = () => (
    <View fixed style={styles.footer}>
      <View
        style={[
          styles.melpFooterAppInfo,
          styles.smallText,
          styles.secondaryText,
        ]}
      >
        <View style={[styles.row, styles.moreInfo]}>
          <Text>{t('benefits.pdfForMoreInfo')}</Text>
          <View style={styles.melpLogo}>
            <LogoPDFIcon color={Colors.grey} />
          </View>
          <Text>{t('benefits.pdfApp')}</Text>
        </View>
        <View style={[styles.row, { justifyContent: 'space-between' }]}>
          <AppStorePDFIcon />
          <Image src={GooglePlay} style={styles.googlePlayLogo} />
        </View>
      </View>
    </View>
  );

  // New property is not defined in types yet.
  // Should be removed after resolving:
  //    https://github.com/diegomura/react-pdf/issues/1888
  const untypedProps: any = {
    bookmark: {
      title: benefitTitle,
      fit: true,
    },
  };

  return (
    <Page size="A4" style={styles.page} wrap {...untypedProps}>
      <PageHeader />
      <TopSection />
      <ValueAndInvestmentSection />
      <DescriptionSection />
      <MarketplaceSection />
      <LinksSection />
      <AttachmentsSection />
      <FAQSection />
      <PageFooter />
    </Page>
  );
};

export type BenefitPDFProps = Props;
export default BenefitPDF;
