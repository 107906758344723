import { FC } from 'react';
import { BenefitsList } from 'containers/benefits';
import { BenefitsLayout } from 'pages/atoms';

const BenefitsPage: FC = () => {
  return (
    <BenefitsLayout>
      <BenefitsList />
    </BenefitsLayout>
  );
};

export default BenefitsPage;
