import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import { Loader, Modal } from 'melp-design/components';
import { ModalProps } from 'store/modal';
import { useBenefitEmployeeAssignmentTimeline } from 'store/assignments';
import { SystemColors } from 'melp-design/style';

interface Props extends ModalProps {
  employeeId: string;
  benefitId: string;
}

const BAR_HEIGHT = 20;
const META_INFORMATION_MULTIPLIER = 5;

const getTime = (date: string | null = null) =>
  date ? new Date(date).getTime() : new Date().getTime();

export const EmployeeBenefitAssignmentTimeline: FC<Props> = ({
  employeeId,
  benefitId,
  closeModal,
}) => {
  const { t } = useTranslation();

  const { data: timeline, isLoading } = useBenefitEmployeeAssignmentTimeline(
    benefitId,
    employeeId,
  );

  if (isLoading || !timeline) return <Loader />;

  const series = [
    {
      name: t('benefits.plan'),
      data: [
        {
          x: timeline.benefit.name,
          y: [
            getTime(timeline.benefit.activationDate),
            getTime(timeline.benefit.deactivationDate),
          ],
        },
      ],
    },
    {
      name: t('assignments.employee_assignment.label'),
      data: timeline.benefitEmployeeAssignments.map((assignment) => ({
        x: timeline.benefit.name,
        y: [getTime(assignment.from), getTime(assignment.to)],
      })),
    },
    {
      name: t('analytics.employeeGroup'),
      data: timeline.employeeGroups.map((group) => ({
        x: group.name,
        y: [getTime(group.creationDate), getTime(null)],
      })),
    },
    {
      name: t('assignments.employee_assignment.label'),
      data: timeline.employeeToEmployeeGroupAssignments.map((assignment) => ({
        x:
          timeline.employeeGroups.find(
            (g) => g.id === assignment.employeeGroupId,
          )?.name ?? assignment.employeeGroupId,
        y: [getTime(assignment.from), getTime(assignment.to)],
      })),
    },
    {
      name: t('assignments.benefit_assignment.label'),
      data: timeline.benefitEmployeeGroupAssignments.map((assignment) => ({
        x:
          timeline.employeeGroups.find(
            (g) => g.id === assignment.employeeGroupId,
          )?.name ?? assignment.employeeGroupId,
        y: [getTime(assignment.from), getTime(assignment.to)],
      })),
    },
  ] satisfies ComponentProps<typeof Chart>['series'];

  return (
    <Modal.Content
      title={`${t('permissions.historyModule')} (${timeline.benefit.name})`}
      actions={[
        {
          variant: 'neutral-outline',
          onClick: () => closeModal({ action: 'CLOSE' }),
          label: t('common.close'),
        },
      ]}
    >
      <Chart
        options={{
          annotations: {
            xaxis: [
              {
                x: getTime(),
                borderColor: SystemColors.danger.base,
              },
            ],
          },
          chart: {
            zoom: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: BAR_HEIGHT,
            },
          },
          xaxis: {
            type: 'datetime',
          },
          stroke: {
            width: 1,
          },
          fill: {
            type: 'solid',
            opacity: 0.3,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            showForNullSeries: false,
          },
        }}
        series={series}
        type="rangeBar"
        height={
          BAR_HEIGHT *
          (series.length * (timeline.employeeGroups.length + 1) +
            META_INFORMATION_MULTIPLIER)
        }
      />
    </Modal.Content>
  );
};
