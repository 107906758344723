import { Svg, Path, Circle, Ellipse } from '@react-pdf/renderer';

interface Props {
  color: string;
  fillColor: string;
}

const CoinsPercentPDFIcon = ({ color, fillColor }: Props) => {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12">
      <Path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M4 1C5.65685 1 7 1.89543 7 3V7C7 8.10457 5.65685 9 4 9C2.34315 9 1 8.10457 1 7V3C1 1.89543 2.34315 1 4 1Z"
      />
      <Path
        d="M7.25 3C7.25 2.33812 6.84758 1.7695 6.26 1.37777C5.67066 0.984883 4.8709 0.75 4 0.75V1.25C4.78595 1.25 5.4862 1.46283 5.98265 1.7938C6.48084 2.12593 6.75 2.55731 6.75 3H7.25ZM7.25 7V3H6.75V7H7.25ZM6.75 7C6.75 7.44269 6.48084 7.87407 5.98265 8.2062C5.4862 8.53717 4.78595 8.75 4 8.75V9.25C4.8709 9.25 5.67066 9.01512 6.26 8.62223C6.84758 8.2305 7.25 7.66188 7.25 7H6.75ZM4 8.75C3.21405 8.75 2.5138 8.53717 2.01735 8.2062C1.51916 7.87407 1.25 7.44269 1.25 7H0.75C0.75 7.66188 1.15242 8.2305 1.74 8.62223C2.32934 9.01512 3.1291 9.25 4 9.25V8.75ZM0.75 3V7H1.25V3H0.75ZM4 0.75C3.1291 0.75 2.32934 0.984883 1.74 1.37777C1.15242 1.7695 0.75 2.33812 0.75 3H1.25C1.25 2.55731 1.51916 2.12593 2.01735 1.7938C2.5138 1.46283 3.21405 1.25 4 1.25V0.75Z"
        fill={color}
      />
      <Ellipse
        cx="4"
        cy="3"
        rx="3"
        ry="2"
        stroke={color}
        fill={fillColor}
        strokeWidth={0.5}
      />
      <Path
        d="M7 5C7 6.10457 5.65685 7 4 7C2.34315 7 1 6.10457 1 5"
        stroke={color}
        strokeWidth={0.5}
      />
      <Circle
        cx="7.78582"
        cy="8.14022"
        r="3.21444"
        stroke={color}
        fill={fillColor}
        strokeWidth={0.5}
      />
      <Path
        d="M6.71417 9.21436L8.98712 6.94141"
        stroke={color}
        strokeWidth={0.5}
      />
      <Circle cx="6.89264" cy="7.24668" r="0.53574" fill={color} />
      <Circle cx="8.6782" cy="9.03574" r="0.53574" fill={color} />
    </Svg>
  );
};

export default CoinsPercentPDFIcon;
