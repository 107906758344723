import { Svg, Rect } from '@react-pdf/renderer';

interface Props {
  color: string;
}

const CategoriesPDFIcon = ({ color }: Props) => (
  <Svg width="14" height="14" viewBox="0 0 14 14">
    <Rect
      x="2.25"
      y="7.85156"
      width="3.9"
      height="3.9"
      rx="1.5"
      ry="1.5"
      stroke={color}
    />
    <Rect
      x="2.25"
      y="2.25"
      width="3.9"
      height="3.9"
      rx="1.5"
      ry="1.5"
      stroke={color}
    />
    <Rect
      x="7.8501"
      y="2.25"
      width="3.9"
      height="3.9"
      rx="1.5"
      ry="1.5"
      stroke={color}
    />
    <Rect
      x="7.8501"
      y="7.85156"
      width="3.9"
      height="3.9"
      rx="1.95"
      ry="1.95"
      stroke={color}
    />
  </Svg>
);

export default CategoriesPDFIcon;
