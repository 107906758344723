import { DropdownMenu, Typography } from 'melp-design/components';
import { Colors } from 'melp-design/style';
import { ButtonBase, Tooltip } from '@mui/material';
import { ComponentProps, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getBorder } from '../../../../constants/Style';
import { Dropdown } from '../../../../melp-design/icons';
import { KeyLabelPair } from '../../../../types/Common';

interface StatusMenuOption extends KeyLabelPair {
  onClick: () => void;
  visible?: boolean;
}

interface StatusConfig {
  label: string;
  color?: ComponentProps<typeof Typography>['color'];
}

interface Props<S> {
  value: keyof S;
  statuses: S;
  readOnly?: boolean;
  menuOptions: StatusMenuOption[];
}

const StatusDropdown = <S extends Record<string, StatusConfig>>(
  props: Props<S>,
) => {
  const { t } = useTranslation();

  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const visibleOptions = props.menuOptions.filter((option) => option.visible);

  const isReadonly = props.readOnly || !visibleOptions.length;

  const statusConfig = props.statuses[props.value];

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  return (
    <>
      <Tooltip
        title={isReadonly ? t('common.noStatusTransitionsAvailable') : ''}
      >
        <span>
          <ButtonBase
            ref={anchorRef}
            onClick={toggleMenu}
            disabled={isReadonly}
            disableRipple
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: getBorder(Colors.elementsGrey),
              borderRadius: '6px',
              padding: '12px 15px 13px 13px',
              cursor: 'pointer',
              transition: 'borderColor 100ms ease-in-out',

              ':hover': {
                borderColor: Colors.greyHover,
              },
            }}
          >
            <Typography color={statusConfig.color}>
              {statusConfig.label}
            </Typography>
            <Dropdown
              style={{
                transition: 'transform 200ms ease-in-out',
                transform: menuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </ButtonBase>
        </span>
      </Tooltip>
      <DropdownMenu
        anchorEl={anchorRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        menuItems={visibleOptions}
        onItemClick={(option) => {
          option.onClick();
          setMenuOpen(false);
        }}
        fitToAnchorWidth
        stickToAnchor
      />
    </>
  );
};

export default StatusDropdown;
