import { FormField, FormFieldProps, OptionsItem } from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ContentLanguage } from 'types/general';
import { useMe } from '../../../state/Administrators';
import { useSortedLanguages } from '../../../utils/useSortedLanguages';

type PickedFormFieldProps<T> = Pick<FormFieldProps<T, T>, 'label'>;

interface CommonProps {
  /**
   * Property name to store value. By default it is set to "supportedLanguages".
   */
  name?: string;
  /**
   * Disables default company language option. It is set to true by default.
   */
  disableDefaultLanguageOption?: boolean;
}

interface MultipleLanguagesProps
  extends PickedFormFieldProps<Uppercase<ContentLanguage>[]>,
    CommonProps {
  single?: false;
}

interface SingleLanguageProps
  extends PickedFormFieldProps<Uppercase<ContentLanguage>>,
    CommonProps {
  single: true;
}

type Props = MultipleLanguagesProps | SingleLanguageProps;

/**
 * Shortcut component for content languages selector. By default it allows
 * a selection of multiple languages.
 */
const ContentLanguageFormField = ({
  single = false,
  disableDefaultLanguageOption = true,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const { me } = useMe();
  const languages = me?.parentCompany.supportedLanguages ?? [];
  const defaultLanguage = me?.parentCompany.settings.defaultLanguage;
  const sortedLanguages = useSortedLanguages(languages);

  return (
    <FormField
      name={props.name ?? 'supportedLanguages'}
      label={props.label ?? t('common.supportedLanguagesInputLabel')}
      render={{
        type: 'select',
        props: {
          SelectProps: { multiple: !single },
        },
      }}
      defaultValue={[defaultLanguage]}
    >
      {sortedLanguages.map((language) => (
        <OptionsItem
          key={language}
          value={language}
          disabled={
            disableDefaultLanguageOption && language === defaultLanguage
          }
        >
          {t(`language.${language}`)}
        </OptionsItem>
      ))}
    </FormField>
  );
};

export default ContentLanguageFormField;
