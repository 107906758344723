import { z } from 'zod';
import { createContext } from 'react';
import { useTabsState } from './Tabs';

export const createTabsContext = <
  const Key extends string,
  Schema extends z.ZodType<{ tab: Key }, any, any>,
>() => {
  return {
    TabsContext: createContext<ReturnType<
      typeof useTabsState<Key, Schema>
    > | null>(null),
  };
};
