import { APP } from 'config';
import i18n from 'i18n';
import { Translations } from 'types/Common';

export const resolveTranslation = <T extends Translations<Record<string, any>>>(
  translations: T,
  userLanguage: string = i18n.language,
  defaultLanguage: string = APP.locales.default,
): T[number] | null => {
  return (
    translations.find(
      (trans) => trans.language === userLanguage.toUpperCase(),
    ) ||
    translations.find(
      (trans) => trans.language === defaultLanguage.toUpperCase(),
    ) ||
    translations[0] ||
    null
  );
};
