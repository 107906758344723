import { Form, FormField, Modal, OptionsItem } from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { benefitCategories } from 'store/benefits';
import { BenefitCreationRequest } from '../../../types/Benefits';

interface Props {
  open: boolean;
  onCancelClick: () => void;
  onActionClick: (data: BenefitCreationRequest) => void | Promise<any>;
  initialValues?: Partial<BenefitCreationRequest>;
  loading?: boolean;
}

const AddBenefitDialog = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      id="add-benefit-dialog"
      isOpen={props.open}
      onClose={props.onCancelClick}
    >
      <Modal.Content
        title={t('buttons.new_benefit')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: props.onCancelClick,
            type: 'reset',
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            type: 'submit',
            form: 'add-benefit',
            disabled: props.loading,
            label: t('common.create'),
          },
        ]}
      >
        <Form<BenefitCreationRequest>
          onSubmit={props.onActionClick}
          defaultValues={props.initialValues}
          id="add-benefit"
        >
          <Stack gap="20px">
            <FormField
              name="type"
              label={t('benefits.benefitCategory')}
              render="select"
              rules={{ required: true }}
            >
              {benefitCategories.map((category) => (
                <OptionsItem key={category} value={category}>
                  {t(`menu.${category}`)}
                </OptionsItem>
              ))}
            </FormField>
            <FormField
              name="name"
              label={t('common.systemName')}
              render="text"
              rules={{ required: true }}
            />
            {/* This input is just to pass through a template id */}
            <Box display="none">
              <FormField name="templateId" render="text" />
            </Box>
          </Stack>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AddBenefitDialog;
