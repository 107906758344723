import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './administrators.loaders';
import { converters } from './administrators.converters';
import { AdministratorsFilters } from './administrators.types';

export const administratorsKeys = queryKeyFactory('administrators');

export const useAdministrators = (
  filters: Partial<AdministratorsFilters> = {},
) => {
  return useQuery({
    queryKey: administratorsKeys.list(filters),
    queryFn: () => loaders.getAdministrators({ filters }),
    select: converters.getAdministrators,
  });
};

export const useAdministrator = (administratorId: string) => {
  return useQuery({
    queryKey: administratorsKeys.detail(administratorId),
    queryFn: () => loaders.getAdministrator({ administratorId }),
    select: (data) => converters.getAdministrator(data),
    enabled: !!administratorId,
  });
};
