import { Loader, Panel, Typography } from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMe } from '../../../../state/Administrators';
import { useBenefit } from '../../../../state/Benefits';
import { useDialog } from '../../../../utils/Dialog';
import { useIdParam } from '../../../../utils/useIdParam';
import PublishDialog from './PublishDialog';
import StatusDropdown from './StatusDropdown';
import UnpublishDialog from './UnpublishDialog';
import { resolveBenefitPublishingStatus } from './resolveBenefitPublishingStatus';
import { resolveBenefitValidityStatus } from './resolveBenefitValidityStatus';
import { useUnsavedChangesContext } from './unsaved-changes/UnsavedChangesContext';
import UnsavedChangesDialog from './unsaved-changes/UnsavedChangesDialog';

const BenefitInfoPanel = () => {
  const { t } = useTranslation();
  const { canEditBenefits } = useMe();
  const id = useIdParam();
  const benefit = useBenefit(id);
  const publishDialog = useDialog();
  const unpublishDialog = useDialog();
  const unsavedChangesContext = useUnsavedChangesContext();
  const unsavedChangesDialog = useDialog();
  const actionHandlerRef = useRef<() => void>();

  if (benefit.loading) return <Loader />;
  if (!benefit.data) return null;

  const publishingStatus = resolveBenefitPublishingStatus(benefit.data);
  const validityStatus = resolveBenefitValidityStatus(benefit.data);

  const getStatusForDropdown = () => {
    if (validityStatus === 'draft') {
      return validityStatus;
    }

    return publishingStatus;
  };

  const renderCounter = (labelKey: string, count: number) => (
    <Stack spacing="5px" width="90px">
      <Typography variant="p2" color="textSecondary" align="center">
        {t(`benefits.${labelKey}`)}
      </Typography>
      <Typography
        color="primary"
        align="center"
        style={{ fontSize: 26, fontWeight: 500 }}
      >
        {count}
      </Typography>
    </Stack>
  );

  const withUnsavedChangesPrompt = (func: () => void) => () => {
    if (unsavedChangesContext.unsavedChangesExist) {
      unsavedChangesDialog.openDialog();
      actionHandlerRef.current = func;
    } else {
      func();
    }
  };

  const handleConfirmUnsavedChanges = () => {
    actionHandlerRef.current?.();
    unsavedChangesDialog.closeDialog();
  };

  return (
    <Panel sx={{ padding: '25px 20px' }}>
      <StatusDropdown
        value={getStatusForDropdown()}
        menuOptions={[
          {
            key: 'publish',
            label: t('buttons.publish'),
            onClick: withUnsavedChangesPrompt(publishDialog.openDialog),
            visible: validityStatus === 'draft',
          },
          {
            key: 'unpublish',
            label: t('benefits.unpublish'),
            onClick: withUnsavedChangesPrompt(unpublishDialog.openDialog),
            visible: publishingStatus === 'published',
          },
        ]}
        statuses={{
          draft: { label: t('status.draft'), color: 'textSecondary' },
          published: { label: t('status.published'), color: 'success' },
          unpublished: {
            label: t('status.unpublished'),
            color: 'textSecondary',
          },
        }}
        readOnly={!canEditBenefits}
      />
      <PublishDialog
        open={publishDialog.open}
        onCancel={publishDialog.closeDialog}
      />
      <UnpublishDialog
        open={unpublishDialog.open}
        onCancel={unpublishDialog.closeDialog}
      />
      <UnsavedChangesDialog
        open={unsavedChangesDialog.open}
        onCancel={unsavedChangesDialog.closeDialog}
        onConfirm={handleConfirmUnsavedChanges}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        mt="20px"
        px="10px"
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{ height: '40px', alignSelf: 'center' }}
          />
        }
      >
        {renderCounter('assignedEmployees', benefit.data.employeeCount)}
        {renderCounter('assignedGroups', benefit.data.groupCount)}
      </Stack>
    </Panel>
  );
};

export default BenefitInfoPanel;
