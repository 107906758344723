import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './benefits.loaders';
import { benefitsKeys } from './benefits.queries';

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};

export const useUpdateCategoryContent = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateCategoryContent, {
    onSuccess: () => {
      return queryClient.invalidateQueries(benefitsKeys.all);
    },
  });
};
