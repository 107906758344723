import { PropsWithChildren, createContext, useContext, useState } from 'react';

const useUnsavedChangesState = () => {
  const [unsavedChangesExist, setUnsavedChangesExist] = useState(false);
  return { unsavedChangesExist, setUnsavedChangesExist };
};

const UnsavedChangesContext = createContext<ReturnType<
  typeof useUnsavedChangesState
> | null>(null);

export const UnsavedChangesContextProvider = (props: PropsWithChildren<{}>) => {
  const state = useUnsavedChangesState();
  return (
    <UnsavedChangesContext.Provider value={state}>
      {props.children}
    </UnsavedChangesContext.Provider>
  );
};

export const useUnsavedChangesContext = () => {
  const context = useContext(UnsavedChangesContext);

  if (!context) {
    throw new Error(
      'Unsaved changes context is missing. Use UnsavedChangesContextProvider to fix this issue.',
    );
  }

  return context;
};
