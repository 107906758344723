import { z } from 'zod';
import { Translations } from 'types/Common';
import { benefitsFiltersSchema } from './benefits.schemas';

export const benefitCategories = [
  'financial',
  'insurances',
  'wellness',
  'perks',
  'pensions',
  'timeOff',
  'education',
  'generalEmployeeWelfare',
  'companyDiscounts',
  'partnerDiscounts',
] as const;
export type BenefitCategory = (typeof benefitCategories)[number];

export const benefitPublishStatuses = ['published', 'unpublished'] as const;
export type BenefitPublishStatus = (typeof benefitPublishStatuses)[number];

export const benefitStatuses = [
  'draft',
  'inactive',
  'active',
  'expiresSoon',
  'expired',
] as const;
export type BenefitStatus = (typeof benefitStatuses)[number];

export interface BaseBenefit {
  id: string;
  name: string;
}

export interface Benefit extends BaseBenefit {
  type: BenefitCategory;
  deactivationDate: string | null;
  activationDate: string | null;
  employeeCount: number;
  investment: number;
  investmentAmount: number;
  value: number;
  publishStatus: BenefitPublishStatus;
  status: BenefitStatus;
  allowClaimReimbursement: boolean;
  trackBalance: boolean;
}

export type LiteBenefit = Pick<
  Benefit,
  | 'id'
  | 'name'
  | 'type'
  | 'status'
  | 'publishStatus'
  | 'activationDate'
  | 'deactivationDate'
  | 'value'
  | 'allowClaimReimbursement'
  | 'trackBalance'
>;

export interface BenefitCategoryContent {
  id: string;
  category: BenefitCategory;
  label: string;
  translations: Translations<{ label: string }>;
}

export interface EmployeeBenefitsFilters {
  trackBalance: boolean;
}

export type BenefitsFilters = z.infer<typeof benefitsFiltersSchema>;

export type LiteBenefitsFilters = Pick<BenefitsFilters, 'name'>;
