import { MarketplaceItemsParams } from '../../../../types/MarketplaceItems';
import { currencyConverters } from '../../../../utils/Currency';
import { isDefined } from '../../../../utils/isDefined';
import { MarketplaceItemsFilters } from './Types';

const covertToFractionUnit = (value?: number | null) => {
  if (!isDefined(value)) {
    return undefined;
  }
  return currencyConverters.toFractionalUnitAmount(value);
};

export const filtersToParams = (
  filters: MarketplaceItemsFilters,
): MarketplaceItemsParams => {
  const { price, ...rest } = filters;
  return {
    ...rest,
    priceFrom: covertToFractionUnit(price?.from),
    priceTo: covertToFractionUnit(price?.to),
  };
};

const covertToMainUnit = (value?: number | null) => {
  if (!isDefined(value)) {
    return undefined;
  }
  return currencyConverters.toMainUnitAmount(value);
};

export const paramsToFilters = (
  params: MarketplaceItemsParams,
): MarketplaceItemsFilters => {
  const { priceFrom, priceTo, ...rest } = params;
  return {
    ...rest,
    price: {
      from: covertToMainUnit(priceFrom),
      to: covertToMainUnit(priceTo),
    },
  };
};
