import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import {
  IAdminBaseBrandCard,
  IAdminBrandCard,
  IFileImport,
} from 'types/income';
import { exportXlsxConfig, getFormData, getSorting } from 'utils/general';
import { Currency } from 'types/general';
import { Translations } from 'types/Common';
import {
  AdminBrandCard,
  AdminBrandCardPriceType,
  AdminBrandCardsFilters,
} from './admin-brand-cards.types';

const getFilters = (filters: Partial<AdminBrandCardsFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  ...getSorting(filters.sort),
  search: filters.search || undefined,
  filter: {
    status: filters.status,
    brandIds: filters.brands,
    priceType: filters.priceType,
    provider: filters.providers,
    country: filters.countries,
    language: filters.languages,
    currency: filters.currency,
  },
});

interface GetCards {
  filters: Partial<AdminBrandCardsFilters>;
}

interface GetCard {
  cardId: string;
}

interface CreateCard {
  brandId: string;
  prices: Array<{ value: number; label: string }>;
  currency: Currency;
  countries: string[];
}

interface UpdateCard {
  cardId: string;
  data: Partial<
    Pick<CreateCard, 'brandId' | 'countries'> &
      Pick<
        AdminBrandCard,
        'status' | 'faceValueCurrency' | 'melpFeeEuro' | 'melpFeePercentage'
      >
  >;
}

interface UpdateCardContent {
  cardId: string;
  data: {
    translations: Translations<{
      description: string;
      terms: string;
      redeemInstructions: string;
    }>;
    prices: Array<{
      faceValue: number;
      content: Translations<{ faceValuesLabel: string }>;
    }>;
  };
}

interface UpdateCardPrices {
  cardId: string;
  data: {
    priceType: AdminBrandCardPriceType;
    priceCurrency: Currency;
    prices: Array<{
      faceValue: number;
      content: Translations<{ faceValuesLabel: string }>;
    }>;
  };
}

interface DeleteCard {
  cardId: string;
}

interface ImportBulkUpdate {
  file: File;
}

interface ExportXls {
  filters: Partial<AdminBrandCardsFilters>;
}

export const loaders = {
  getCards: ({ filters }: GetCards) => {
    return api<TableDataResponse<IAdminBaseBrandCard>>({
      url: Endpoints.melpAdmin.cards.root,
      params: {
        ...getFilters(filters),
      },
    });
  },
  getCard: ({ cardId }: GetCard) => {
    return api<IAdminBrandCard>({
      url: Endpoints.melpAdmin.cards.byId.root.replace(':cardId', cardId),
    });
  },
  createCard: (values: CreateCard) => {
    return api<{ cardId: string }>({
      url: Endpoints.melpAdmin.brands.byId.cards.replace(
        ':brandId',
        values.brandId,
      ),
      data: {
        faceValues: values.prices.map((price) => price.value),
        faceValuesLabels: values.prices.map((price) => price.label),
        faceValueCurrency: values.currency,
        countries: values.countries,
      },
      method: 'post',
    });
  },
  updateCard: ({ cardId, data }: UpdateCard) => {
    return api({
      url: Endpoints.melpAdmin.cards.byId.update.replace(':cardId', cardId),
      data,
      method: 'patch',
    });
  },
  updateCardContent: ({ cardId, data }: UpdateCardContent) => {
    return api({
      url: Endpoints.melpAdmin.cards.byId.content.replace(':cardId', cardId),
      data: {
        content: data.translations.map((trans) => ({
          language: trans.language,
          description: trans.description,
          terms: trans.terms,
          redeemInstructions: trans.redeemInstructions,
          faceValuesLabels: data.prices
            .map((price) => price.content)
            .flat()
            .filter((content) => content.language === trans.language)
            .map((content) => content.faceValuesLabel),
        })),
        faceValues: data.prices.map((price) => price.faceValue),
      },
      method: 'put',
    });
  },
  updateCardPrices: ({ cardId, data }: UpdateCardPrices) => {
    const languages = data.prices[0]?.content.map((c) => c.language) ?? [];

    return api({
      url: Endpoints.melpAdmin.cards.byId.prices.replace(':cardId', cardId),
      data: {
        faceValueType: data.priceType,
        faceValues: data.prices.map((price) => price.faceValue),
        faceValueCurrency: data.priceCurrency,
        content: languages.map((language) => ({
          language,
          faceValuesLabels: data.prices
            .map((price) => price.content)
            .flat()
            .filter((content) => content.language === language)
            .map((content) => content.faceValuesLabel),
        })),
      },
      method: 'put',
    });
  },
  deleteCard: ({ cardId }: DeleteCard) => {
    return api({
      url: Endpoints.melpAdmin.cards.byId.root.replace(':cardId', cardId),
      method: 'delete',
    });
  },
  downloadBulkUpdateTemplate: () => {
    return api({
      url: Endpoints.melpAdmin.cards.bulk.xls,
      ...exportXlsxConfig,
    });
  },
  importBulkUpdate: ({ file }: ImportBulkUpdate) => {
    return api<IFileImport>({
      url: Endpoints.melpAdmin.cards.bulk.xls,
      method: 'post',
      data: getFormData([{ field: 'file', value: file }]),
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    const { page, pageSize, ...filtersWithoutPagination } = getFilters(filters);
    return api({
      url: Endpoints.melpAdmin.cards.export.xls,
      params: filtersWithoutPagination,
      ...exportXlsxConfig,
    });
  },
};
