import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IAdministrator, IBaseAdministrator } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { AppLanguage } from 'types/general';
import {
  AdministratorsFilters,
  AdministratorStatus,
} from './administrators.types';

interface GetAdministrators {
  filters: Partial<AdministratorsFilters>;
}

interface GetAdministrator {
  administratorId: string;
}

interface UpdateAdministrator {
  administratorId: string;
  data: Partial<{
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    status: AdministratorStatus;
    language: Uppercase<AppLanguage>;
    employeeId: string | null;
  }>;
}

interface SendInvite {
  administratorId: string;
}

interface DeleteAdministrator {
  administratorId: string;
}

export const loaders = {
  getAdministrators: ({ filters }: GetAdministrators) => {
    return api<TableDataResponse<IBaseAdministrator>>({
      url: Endpoints.clientAdmin.administrators.root,
      params: {
        filter: {
          dateFrom: filters.date?.from,
          dateTo: filters.date?.to,
        },
      },
    });
  },
  getAdministrator: ({ administratorId }: GetAdministrator) => {
    return api<IAdministrator>({
      url: Endpoints.clientAdmin.administrators.byId.replace(
        ':administratorId',
        administratorId,
      ),
    });
  },
  updateAdministrator: ({ administratorId, data }: UpdateAdministrator) => {
    return api<TableDataResponse<IBaseAdministrator>>({
      url: Endpoints.clientAdmin.administrators.byId.replace(
        ':administratorId',
        administratorId,
      ),
      data: {
        ...data,
        phone: data.phone
          ? data.phone.charAt(0) === '+'
            ? data.phone
            : `+${data.phone}`
          : undefined,
        email: data.email ? data.email.toLowerCase() : undefined,
      },
      method: 'patch',
    });
  },
  deleteAdministrator: ({ administratorId }: DeleteAdministrator) => {
    return api({
      url: Endpoints.clientAdmin.administrators.delete.replace(
        ':administratorId',
        administratorId,
      ),
      method: 'delete',
    });
  },
  sendInvite: ({ administratorId }: SendInvite) => {
    return api({
      url: Endpoints.clientAdmin.administrators.invite.replace(
        ':administratorId',
        administratorId,
      ),
      method: 'post',
    });
  },
};
