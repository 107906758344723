import { z } from 'zod';
import { paginationFiltersSchema, sortFiltersSchema } from 'utils/schemas';
import { shopItemCategories } from 'types/MarketplaceItems';
import { uppercaseContentLanguages } from 'types/general';
import {
  adminBrandProviders,
  adminBrandStatuses,
  adminBrandVariants,
} from './admin-brands.types';

export const adminBrandsFiltersSchema = z
  .object({
    search: z.string().catch(''),
    status: z.array(z.enum(adminBrandStatuses)).catch([]),
    variant: z.array(z.enum(adminBrandVariants)).catch([]),
    brands: z.array(z.string()).catch([]),
    providers: z.array(z.enum(adminBrandProviders)).catch([]),
    countries: z.array(z.string()).catch([]),
    primaryCategories: z.array(z.enum(shopItemCategories)).catch([]),
    languages: z.array(z.enum(uppercaseContentLanguages)).catch([]),
  })
  .merge(sortFiltersSchema)
  .merge(paginationFiltersSchema);
