import { FC, PropsWithChildren } from 'react';
import {
  DetailsPageContent,
  DetailsPageHeader,
  SideMenu,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'config';
import { BenefitsDisabledFeatureAlert } from 'containers/benefits';

export const BenefitsLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  return (
    <Stack gap={3}>
      <BenefitsDisabledFeatureAlert />

      <Stack>
        <DetailsPageHeader title={t('menu.benefits')} />

        <DetailsPageContent>
          <SideMenu
            tabs={[
              {
                key: 'benefits',
                label: t('menu.benefits'),
                url: ROUTES.benefits.list,
                isActive: path === ROUTES.benefits.list,
              },
              {
                key: 'categories',
                label: t('benefits.benefit_types'),
                url: ROUTES.benefits.categories.list,
                isActive: path === ROUTES.benefits.categories.list,
              },
            ]}
          />

          {children}
        </DetailsPageContent>
      </Stack>
    </Stack>
  );
};
