import {
  FormField,
  MultilingualFormField,
  Panel,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const GeneralInfo = () => {
  const { t } = useTranslation();

  return (
    <Panel title={t('benefits.generalInfoSection')}>
      <Stack gap="20px">
        <MultilingualFormField
          name="title"
          label={t('benefits.title')}
          render={{ type: 'text', props: { emoji: true } }}
          rules={{ required: true }}
        />
        <FormField
          name="imageId"
          label={t('form.image')}
          render={{
            type: 'imageUpload',
            props: { hint: t('benefits.contentImageHint') },
          }}
        />
        <MultilingualFormField
          name="description"
          label={t('form.description')}
          render="richText"
        />
      </Stack>
    </Panel>
  );
};

export default GeneralInfo;
