import { IAdministrator, IBaseAdministrator } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { loaders } from './administrators.loaders';
import { Administrator, BaseAdministrator } from './administrators.types';

const convertAdministrator = (administrator: IAdministrator): Administrator => {
  return {
    id: administrator.id,
    firstName: administrator.firstName,
    lastName: administrator.lastName,
    phone: administrator.phone,
    email: administrator.email,
    status: administrator.status,
    role: administrator.role,
    root: administrator.root,
    parentCompanyId: administrator.parentCompanyId,
    employeeId: administrator.employeeId ?? '',
    apiKeyAbbreviated: administrator.apiKeyAbbreviated ?? '',
    clientIntegrationId: administrator.clientIntegrationId ?? '',
  };
};

const convertBaseAdministrator = (
  administrator: IBaseAdministrator,
): BaseAdministrator => {
  return {
    id: administrator.id,
    status: administrator.status,
    email: administrator.email,
    phone: administrator.phone,
    fullName: administrator.fullName,
    parentCompanyId: administrator.parentCompanyId,
  };
};

const convertAdministrators = (
  administrators: IBaseAdministrator[],
): BaseAdministrator[] => {
  return administrators.map(convertBaseAdministrator);
};

export const converters = {
  getAdministrators: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAdministrators>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertAdministrators(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getAdministrator: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAdministrator>>) => {
    return convertAdministrator(data);
  },
};
