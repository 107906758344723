import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBenefit } from '../../../../state/Benefits';
import { isDefined } from '../../../../utils/isDefined';
import { useIdParam } from '../../../../utils/useIdParam';
import PublishDateField from '../common/PublishDateField';
import DateRange from './DateRange';
import { BenefitSettingsFormData } from './types';

const BenefitTermFields = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<BenefitSettingsFormData>();

  const investmentAmount = watch('investmentAmount');
  const investmentFrequency = watch('investmentPeriod');
  const value = watch('value');
  const valueOrInvestmentDefined =
    isDefined(investmentAmount) || isDefined(value);

  const deactivationDate = watch('deactivatedAt');

  const id = useIdParam();
  const benefit = useBenefit(id);
  const draft = benefit.data?.isDraft ?? false;

  return (
    <Stack gap="20px">
      <DateRange
        from={{
          name: 'activatedAt',
          label: t('benefits.activationDate'),
          rules: { required: valueOrInvestmentDefined && !deactivationDate },
        }}
        to={{
          name: 'deactivatedAt',
          label: t('benefits.deactivationDate'),
          rules: {
            required:
              valueOrInvestmentDefined && investmentFrequency === 'monthly',
          },
        }}
      />
      <Box width="calc(50% - 8px)" display={draft ? 'none' : 'block'}>
        <PublishDateField
          deactivationDate={deactivationDate}
          required={!draft}
        />
      </Box>
    </Stack>
  );
};

export default BenefitTermFields;
