import { FC } from 'react';
import {
  Modal,
  MultiLanguageInputContainer,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form-latest';
import { ContentLanguage } from 'types/general';
import { formatLanguage } from 'utils/format';
import { Translations } from 'types/Common';
import { BenefitCategory } from 'store/benefits';
import { useCompanyLanguages } from 'state/Administrators';
import { Stack } from '@mui/material';

interface Values {
  languages: Array<Uppercase<ContentLanguage>>;
  translations: Translations<{ label: string }>;
}

interface Props extends ModalProps {
  closeModal: (
    param?:
      | { action: 'CLOSE' }
      | { action: 'CONFIRM'; translations: Translations<{ label: string }> },
  ) => void;
  category: BenefitCategory;
  initialTranslations: Translations<{ label: string }>;
}

export const CategoryContentModal: FC<Props> = ({
  closeModal,
  category,
  initialTranslations,
}) => {
  const { t, i18n } = useTranslation();
  const { defaultLanguage, supportedLanguages } = useCompanyLanguages();

  const { handleSubmit, watch, control } = useForm<Values>({
    defaultValues: {
      ...(initialTranslations.length
        ? {
            languages: initialTranslations.map(
              (translation) => translation.language,
            ),
            translations: initialTranslations,
          }
        : {
            languages: [defaultLanguage],
            translations: [{ language: defaultLanguage, label: '' }],
          }),
    },
  });
  const { fields, replace } = useFieldArray({ name: 'translations', control });

  const onSubmit: SubmitHandler<Values> = (values) => {
    closeModal({ action: 'CONFIRM', translations: values.translations });
  };

  const [translations] = watch(['translations']);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t(`menu.${category}`)}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
          },
        ]}
      >
        <Stack spacing={4}>
          <Controller
            name="languages"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <SelectInput
                  label={t('companies.supported_languages')}
                  name={name}
                  value={value}
                  onChange={(value) => {
                    const selectedLanguages = value as Array<
                      Uppercase<ContentLanguage>
                    >;
                    replace(
                      selectedLanguages.map(
                        (language) =>
                          translations.find((t) => t.language === language) ?? {
                            language,
                            label: '',
                          },
                      ),
                    );
                    onChange(selectedLanguages);
                  }}
                  options={supportedLanguages.map((language) => ({
                    value: language,
                    label: formatLanguage(i18n.language, language),
                    disabled: language === defaultLanguage,
                  }))}
                  multiple
                  required
                  ref={ref}
                />
              );
            }}
          />

          <MultiLanguageInputContainer
            label={t('common.name')}
            required
            fields={fields.map((field, i) => ({
              id: field.id,
              language: field.language,
              component: (
                <Controller
                  name={`translations.${i}.label`}
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => {
                    return (
                      <TextField
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        ref={ref}
                      />
                    );
                  }}
                />
              ),
            }))}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
