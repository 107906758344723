import { FormField } from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BenefitSettingsFormData } from './types';
import { useInvestmentFrequencyLabels } from './useInvestmentFrequencyLabels';

const BenefitValueFields = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<BenefitSettingsFormData>();

  const investmentFrequencyLabels = useInvestmentFrequencyLabels();
  const investmentFrequency = watch('investmentPeriod');

  const getValueLabel = () => {
    if (!investmentFrequency) {
      return t('benefits.value');
    }

    return `${t('benefits.value')} (${
      investmentFrequencyLabels[investmentFrequency]
    })`;
  };

  return (
    <Stack direction="row" alignItems="center" gap="20px">
      <Box width="calc(33% - 10px)">
        <FormField name="value" render="currency" label={getValueLabel()} />
      </Box>
    </Stack>
  );
};

export default BenefitValueFields;
