import { ButtonOld, Typography } from 'melp-design/components';
import { ChangeEvent, ComponentProps, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useUploadFile } from 'store/files';

interface UploadedFile {
  id: string;
  name: string;
}

interface Props
  extends Omit<ComponentProps<typeof ButtonOld>, 'onClick' | 'loading'> {
  onUploadCompleted: (file: UploadedFile) => void;
  /**
   * Defines where size limit message is displayed. It is displayed at the bottom by default
   */
  limitPosition?: 'side' | 'bottom';
  /**
   * Controls how button and limit messages are aligned horizontally. Default: start.
   */
  alignment?: 'center' | 'start' | 'end';
}

const FileUploadButton = ({
  children,
  onUploadCompleted,
  limitPosition,
  alignment,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { mutate: uploadFile, isLoading } = useUploadFile();

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      uploadFile(
        { file },
        {
          onSuccess: ({ data }) => {
            onUploadCompleted({
              id: data.id,
              name: data.fileName,
            });
          },
        },
      );
    }
  };

  return (
    <label>
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        onChange={handleUploadFile}
      />
      <Stack
        display="inline-flex"
        alignItems={limitPosition === 'side' ? 'center' : alignment ?? 'start'}
        direction={limitPosition === 'side' ? 'row' : 'column'}
        gap="10px"
      >
        <ButtonOld
          {...props}
          onClick={() => {
            fileInputRef.current?.click();
          }}
          loading={isLoading}
        >
          {children ?? t('common.upload')}
        </ButtonOld>
        <Typography variant="p2" color="textSecondary">
          {t('common.max_upload_size')} 25Mb
        </Typography>
      </Stack>
    </label>
  );
};

export default FileUploadButton;
