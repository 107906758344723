import { z } from 'zod';
import { paginationFiltersSchema } from 'utils/schemas';
import { benefitCategories, benefitStatuses } from 'store/benefits';
import { assignmentStatuses, assignmentTypes } from './assignments.types';

export const employeesByBenefitFiltersSchema = z
  .object({
    employees: z.array(z.string()).catch([]),
    employeeGroups: z.array(z.string()).catch([]),
    companies: z.array(z.string()).catch([]),
    departments: z.array(z.string()).catch([]),
    assignmentStatus: z.array(z.enum(assignmentStatuses)).catch([]),
    customCategory: z.string().catch(''),
    customCategoryValue: z.array(z.string()).catch([]),
  })
  .merge(paginationFiltersSchema);

export const employeeGroupsByBenefitFiltersSchema = z
  .object({
    employeeGroups: z.array(z.string()).catch([]),
    assignmentStatus: z.array(z.enum(assignmentStatuses)).catch([]),
  })
  .merge(paginationFiltersSchema);

export const benefitsByEmployeeFiltersSchema = z
  .object({
    benefits: z.array(z.string()).catch([]),
    categories: z.array(z.enum(benefitCategories)).catch([]),
    assignmentStatus: z.array(z.enum(assignmentStatuses)).catch([]),
    search: z.string().catch(''),
  })
  .merge(paginationFiltersSchema);

export const benefitsByEmployeePreviewFiltersSchema = z.object({
  benefits: z.array(z.string()).catch([]),
  benefitStatus: z.array(z.enum(benefitStatuses)).catch([]),
  assignmentStatus: z.array(z.enum(assignmentStatuses)).catch([]),
  assignmentType: z.array(z.enum(assignmentTypes)).catch([]),
});

export const employeeGroupsByEmployeeFiltersSchema = z
  .object({
    employeeGroups: z.array(z.string()).catch([]),
    assignmentStatus: z.array(z.enum(assignmentStatuses)).catch([]),
  })
  .merge(paginationFiltersSchema);

export const benefitByEmployeeGroupFiltersSchema = z
  .object({
    employeeGroups: z.array(z.string()).catch([]),
    benefits: z.array(z.string()).catch([]),
    categories: z.array(z.enum(benefitCategories)).catch([]),
    assignmentStatus: z.array(z.enum(assignmentStatuses)).catch([]),
  })
  .merge(paginationFiltersSchema);

export const employeesByEmployeeGroupFiltersSchema = z
  .object({
    employees: z.array(z.string()).catch([]),
    companies: z.array(z.string()).catch([]),
    departments: z.array(z.string()).catch([]),
    assignmentStatus: z.array(z.enum(assignmentStatuses)).catch([]),
    jobTitle: z.array(z.string()).catch([]),
    customCategory: z.string().catch(''),
    customCategoryValue: z.array(z.string()).catch([]),
  })
  .merge(paginationFiltersSchema);
