import { ComponentProps } from 'react';
import { useMe } from '../../../state/Administrators';
import BenefitPDFDownloadDialog from './BenefitPDFDownloadDialog';
import { useMultipleBenefitsPDFDownload } from './hooks/useMultipleBenefitsPDFDownload';

interface Props
  extends Pick<
    ComponentProps<typeof BenefitPDFDownloadDialog>,
    'open' | 'onCancel'
  > {
  /**
   * IDs of benefits to generate PDF for.
   */
  benefitIds: string[];
  /**
   * Name of the pdf file
   */
  fileName?: string;
}

const MultipleBenefitsPDFDownloadDialog = ({ fileName, ...props }: Props) => {
  const { me } = useMe();
  const languages = me?.parentCompany?.supportedLanguages ?? [];

  const multipleBenefitsPDFDownload = useMultipleBenefitsPDFDownload({
    fileName,
  });

  return (
    <BenefitPDFDownloadDialog
      {...props}
      onSubmit={async (data) => {
        await multipleBenefitsPDFDownload.downloadPdf(
          props.benefitIds,
          data.language,
        );
        props.onCancel();
      }}
      loading={multipleBenefitsPDFDownload.loading}
      languages={languages}
    />
  );
};

export default MultipleBenefitsPDFDownloadDialog;
