import { Modal, SelectInput } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'store/modal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { ContentLanguage, uppercaseContentLanguages } from 'types/general';
import { formatLanguage } from 'utils/format';
import { upper } from 'utils/general';
import { APP } from 'config';

interface Values {
  languages: Uppercase<ContentLanguage>[];
}

interface Props extends ModalProps {
  closeModal: (
    param?:
      | { action: 'CLOSE' }
      | { action: 'CONFIRM'; languages: Uppercase<ContentLanguage>[] },
  ) => void;
  languages?: Array<Uppercase<ContentLanguage>>;
  initialLanguages?: Uppercase<ContentLanguage>[];
}

export const LanguagesSelectModal: FC<Props> = ({
  closeModal,
  initialLanguages,
  languages = uppercaseContentLanguages,
}) => {
  const { t, i18n } = useTranslation();

  const { handleSubmit, control } = useForm<Values>({
    defaultValues: {
      languages: initialLanguages ?? [upper(APP.locales.default)],
    },
  });

  const onSubmit: SubmitHandler<Values> = (values) => {
    closeModal({ action: 'CONFIRM', languages: values.languages });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('marketplaceItems.translationLanguage')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
          },
        ]}
      >
        <Controller
          name="languages"
          control={control}
          render={({ field: { name, value, onChange, ref } }) => (
            <SelectInput
              label={t('benefitTemplates.supportedLanguages')}
              name={name}
              value={value}
              onChange={onChange}
              multiple={true}
              options={languages.map((language) => ({
                value: language,
                label: formatLanguage(i18n.language, language),
              }))}
              ref={ref}
            />
          )}
        />
      </Modal.Content>
    </form>
  );
};
