import { converters as tableConverters } from 'store/table';
import {
  IAssignmentHistoryEntry,
  IBenefitByEmployee,
  IBenefitByEmployeeGroup,
  IBenefitByEmployeePreview,
  IEmployeeByBenefit,
  IEmployeeByEmployeeGroup,
  IEmployeeGroupByBenefit,
  IEmployeeGroupByEmployee,
} from 'types/income';
import {
  convertCustomCategories,
  convertEmbeddedCustomCategories,
} from 'store/custom-categories';
import { loaders } from './assignments.loaders';
import {
  AssignmentHistoryEntry,
  BenefitByEmployee,
  BenefitByEmployeeGroup,
  BenefitByEmployeePreview,
  BenefitsByEmployeePreviewFilters,
  EmployeeByBenefit,
  EmployeeByEmployeeGroup,
  EmployeeGroupByBenefit,
  EmployeeGroupByEmployee,
} from './assignments.types';

const convertEmployeeByBenefit = (
  employee: IEmployeeByBenefit,
): EmployeeByBenefit => {
  return {
    id: employee.id,
    firstName: employee.firstName,
    lastName: employee.lastName,
    fullName: [employee.firstName, employee.lastName].filter(Boolean).join(' '),
    assignmentStatus: employee.assignmentStatus,
    assignmentCount: employee.assignmentCount,
    customCategories: convertEmbeddedCustomCategories(
      employee.customCategories ?? [],
    ),
  };
};

const convertEmployeesByBenefit = (
  employees: IEmployeeByBenefit[],
): EmployeeByBenefit[] => {
  return employees.map(convertEmployeeByBenefit);
};

const convertEmployeeGroupByBenefit = (
  employeeGroup: IEmployeeGroupByBenefit,
): EmployeeGroupByBenefit => {
  return {
    id: employeeGroup.id,
    name: employeeGroup.name,
    assignmentStatus: employeeGroup.assignmentStatus,
    assignmentCount: employeeGroup.assignmentCount,
  };
};

const convertEmployeeGroupsByBenefit = (
  employeeGroups: IEmployeeGroupByBenefit[],
): EmployeeGroupByBenefit[] => {
  return employeeGroups.map(convertEmployeeGroupByBenefit);
};

const convertBenefitByEmployee = (
  benefit: IBenefitByEmployee,
): BenefitByEmployee => {
  return {
    id: benefit.id,
    name: benefit.name,
    benefitStatus: benefit.benefitStatus,
    category: benefit.category,
    investment: benefit.investment,
    assignmentStatus: benefit.assignmentStatus,
    assignmentCount: benefit.assignmentCount,
  };
};

const convertBenefitsByEmployee = (
  benefits: IBenefitByEmployee[],
): BenefitByEmployee[] => {
  return benefits.map(convertBenefitByEmployee);
};

const convertBenefitByEmployeePreview = (
  benefit: IBenefitByEmployeePreview,
): BenefitByEmployeePreview => {
  return {
    id: benefit.benefitId,
    name: benefit.benefitName,
    status: benefit.benefitStatus,
    assignmentType: benefit.assignmentType,
    assignmentStatus: benefit.assignmentStatus,
  };
};

const convertBenefitsByEmployeePreviews = (
  benefits: IBenefitByEmployeePreview[],
  filters: Partial<BenefitsByEmployeePreviewFilters>,
): BenefitByEmployeePreview[] => {
  const filtered = benefits
    .filter((benefit) =>
      filters.benefits?.length
        ? filters.benefits.includes(benefit.benefitId)
        : benefit,
    )
    .filter((benefit) =>
      filters.benefitStatus?.length
        ? filters.benefitStatus.includes(benefit.benefitStatus)
        : benefit,
    )
    .filter((benefit) =>
      filters.assignmentStatus?.length
        ? filters.assignmentStatus.includes(benefit.assignmentStatus)
        : benefit,
    )
    .filter((benefit) =>
      filters.assignmentType?.length
        ? filters.assignmentType.includes(benefit.assignmentType)
        : benefit,
    );

  return filtered.map(convertBenefitByEmployeePreview);
};

const convertEmployeeGroupByEmployee = (
  employeeGroup: IEmployeeGroupByEmployee,
): EmployeeGroupByEmployee => {
  return {
    id: employeeGroup.id,
    name: employeeGroup.name,
    assignmentStatus: employeeGroup.included ? 'on' : 'off',
    assignmentCount: employeeGroup.assignmentCount,
  };
};

const convertEmployeeGroupsByEmployee = (
  employeeGroups: IEmployeeGroupByEmployee[],
): EmployeeGroupByEmployee[] => {
  return employeeGroups.map(convertEmployeeGroupByEmployee);
};

const convertBenefitByEmployeeGroup = (
  benefit: IBenefitByEmployeeGroup,
): BenefitByEmployeeGroup => {
  return {
    id: benefit.id,
    name: benefit.name,
    benefitStatus: benefit.benefitStatus,
    category: benefit.benefitCategory,
    investment: benefit.investment,
    assignmentStatus: benefit.assignmentStatus,
    assignmentCount: benefit.assignmentCount,
  };
};

const convertBenefitsByEmployeeGroup = (
  benefits: IBenefitByEmployeeGroup[],
): BenefitByEmployeeGroup[] => {
  return benefits.map(convertBenefitByEmployeeGroup);
};

const convertEmployeeByEmployeeGroup = (
  employee: IEmployeeByEmployeeGroup,
): EmployeeByEmployeeGroup => {
  return {
    id: employee.id,
    firstName: employee.firstName,
    lastName: employee.lastName,
    fullName: [employee.firstName, employee.lastName].filter(Boolean).join(' '),
    jobTitle: employee.jobTitle,
    assignmentStatus: employee.assignmentStatus,
    customCategories: convertEmbeddedCustomCategories(
      employee.customCategories ?? [],
    ),
    assignmentCount: employee.assignmentCount,
  };
};

const convertEmployeesByEmployeeGroup = (
  employees: IEmployeeByEmployeeGroup[],
): EmployeeByEmployeeGroup[] => {
  return employees.map(convertEmployeeByEmployeeGroup);
};

const convertAssignmentHistoryEntry = (
  entry: IAssignmentHistoryEntry,
): AssignmentHistoryEntry => {
  return {
    id: entry.id,
    from: entry.from,
    to: entry.to ?? '',
  };
};

const convertAssignmentHistoryEntries = (
  entries: IAssignmentHistoryEntry[],
): AssignmentHistoryEntry[] => {
  return entries.map(convertAssignmentHistoryEntry);
};

export const converters = {
  getEmployeesByBenefit: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeesByBenefit>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeesByBenefit(data.data),
      total,
      page,
      pageSize,
      pages,
      customCategories: convertCustomCategories(data.customCategories ?? []),
    };
  },
  getEmployeeGroupsByBenefit: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeeGroupsByBenefit>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeeGroupsByBenefit(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getBenefitsByEmployee: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getBenefitsByEmployee>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertBenefitsByEmployee(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getBenefitsByEmployeePreview: (
    { data }: Awaited<ReturnType<typeof loaders.getBenefitsByEmployeePreview>>,
    filters: Partial<BenefitsByEmployeePreviewFilters>,
  ) => {
    const items = convertBenefitsByEmployeePreviews(data.data, filters);

    return {
      items,
      total: items.length,
    };
  },
  getEmployeeGroupsByEmployee: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeeGroupsByEmployee>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeeGroupsByEmployee(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getBenefitsByEmployeeGroup: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getBenefitsByEmployeeGroup>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertBenefitsByEmployeeGroup(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getEmployeesByEmployeeGroup: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeesByEmployeeGroup>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeesByEmployeeGroup(data.data),
      total,
      page,
      pageSize,
      pages,
      customCategories: convertCustomCategories(data.customCategories ?? []),
    };
  },
  getEmployeeBenefitAssignmentHistory: ({
    data,
  }: Awaited<
    ReturnType<typeof loaders.getEmployeeBenefitAssignmentHistory>
  >) => {
    return convertAssignmentHistoryEntries(data.data);
  },
  getBenefitEmployeeGroupAssignmentHistory: ({
    data,
  }: Awaited<
    ReturnType<typeof loaders.getBenefitEmployeeGroupAssignmentHistory>
  >) => {
    return convertAssignmentHistoryEntries(data.data);
  },
  getEmployeeEmployeeGroupAssignmentHistory: ({
    data,
  }: Awaited<
    ReturnType<typeof loaders.getEmployeeEmployeeGroupAssignmentHistory>
  >) => {
    return convertAssignmentHistoryEntries(data.data);
  },
  getBenefitsEmployeeAssignmentTimeline: ({
    data,
  }: Awaited<
    ReturnType<typeof loaders.getBenefitsEmployeeAssignmentTimeline>
  >) => {
    return data;
  },
};
