import { useMutation, useQueryClient } from '@tanstack/react-query';
import { employeeGroupsKeys } from 'store/employee-groups';
import { alternativesKeys } from 'store/alternatives';
import { employeesKeys } from 'store/employees';
import { loaders } from './assignments.loaders';
import { assignmentsKeys } from './assignments.queries';

export const useAssignGroupsToEmployee = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.assignGroupsToEmployee, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(employeesKeys.all),
        queryClient.invalidateQueries(employeeGroupsKeys.all),
        queryClient.invalidateQueries(assignmentsKeys.all),
        queryClient.invalidateQueries(alternativesKeys.lists),
      ]);
    },
  });
};
export const useAssignBenefitsToEmployee = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.assignBenefitsToEmployee, {
    onSuccess: () => {
      return queryClient.invalidateQueries(assignmentsKeys.all);
    },
  });
};
export const useAssignGroupsToBenefit = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.assignGroupsToBenefit, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(assignmentsKeys.all),
        queryClient.invalidateQueries(employeeGroupsKeys.lists),
      ]);
    },
  });
};
export const useAssignEmployeesToBenefit = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.assignEmployeesToBenefit, {
    onSuccess: () => {
      return queryClient.invalidateQueries(assignmentsKeys.all);
    },
  });
};
export const useAssignBenefitsToGroup = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.assignBenefitsToGroup, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(assignmentsKeys.all),
        queryClient.invalidateQueries(employeeGroupsKeys.lists),
      ]);
    },
  });
};
export const useAssignEmployeesToGroup = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.assignEmployeesToGroup, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(employeeGroupsKeys.all),
        queryClient.invalidateQueries(employeesKeys.all),
        queryClient.invalidateQueries(assignmentsKeys.all),
        queryClient.invalidateQueries(alternativesKeys.lists),
      ]);
    },
  });
};

export const useUpdateAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateAssignment, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(employeeGroupsKeys.all),
        queryClient.invalidateQueries(assignmentsKeys.all),
        queryClient.invalidateQueries(employeesKeys.all),
      ]);
    },
  });
};

export const useDeleteEmployeeBenefitAssignmentHistoryEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteEmployeeBenefitAssignmentHistoryEntry, {
    onSuccess: () => {
      return queryClient.invalidateQueries(assignmentsKeys.all);
    },
  });
};

export const useDeleteBenefitEmployeeGroupAssignmentHistoryEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteBenefitEmployeeGroupAssignmentHistoryEntry, {
    onSuccess: () => {
      return queryClient.invalidateQueries(assignmentsKeys.all);
    },
  });
};

export const useDeleteEmployeeEmployeeGroupAssignmentHistoryEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(
    loaders.deleteEmployeeEmployeeGroupAssignmentHistoryEntry,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(assignmentsKeys.all);
      },
    },
  );
};
