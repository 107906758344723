import { ComponentProps, FC } from 'react';
import {
  ImageList as MuiImageList,
  ImageListItem,
  Skeleton,
  ImageListItemBar,
  Stack,
} from '@mui/material';
import { useFiles } from 'store/files';
import { SystemColors } from 'melp-design/style';
import { Button } from 'melp-design/components';

interface BaseImageListProps {
  items: Array<{
    src: string;
    name?: string;
    actions?: Array<ComponentProps<typeof Button>>;
  }>;
  isLoading?: boolean;
  imageFit?: 'cover' | 'contain';
}

export const BaseImageList: FC<BaseImageListProps> = ({
  items,
  imageFit = 'contain',
  isLoading = false,
}) => {
  return (
    <MuiImageList cols={2} gap={10}>
      {isLoading
        ? Array.from({ length: items.length }).map((_, i) => (
            <ImageListItem key={i}>
              <Skeleton
                sx={{ aspectRatio: '16 / 9' }}
                height="100%"
                variant="rectangular"
              />
            </ImageListItem>
          ))
        : items.map((item) => {
            return (
              <ImageListItem key={item.src}>
                {item.actions ? (
                  <ImageListItemBar
                    position="top"
                    actionIcon={
                      <Stack flexDirection="row" flexWrap="wrap" gap={1}>
                        {item.actions.map((action, i) => (
                          <Button {...action} size="sm" key={i} />
                        ))}
                      </Stack>
                    }
                    sx={{
                      p: 1,
                      m: '1px',
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      background: 'rgba(0, 0, 0, 0.25)',
                    }}
                  />
                ) : null}
                <img
                  src={item.src}
                  alt={item.name ?? ''}
                  style={{
                    aspectRatio: '16 / 9',
                    objectFit: imageFit,
                    border: `1px dashed ${SystemColors.grey[55]}`,
                    borderRadius: 6,
                  }}
                />
              </ImageListItem>
            );
          })}
    </MuiImageList>
  );
};

interface Props {
  items: Array<{
    imageId: string;
    actions?: Array<ComponentProps<typeof Button>>;
  }>;
  imageFit?: 'cover' | 'contain';
}

export const ImageList: FC<Props> = ({ items, imageFit = 'contain' }) => {
  const { data: images = [], isLoading } = useFiles(
    items.map((item) => item.imageId),
  );

  return (
    <BaseImageList
      items={items
        .map((item) => {
          const image = images.find((image) => image.id === item.imageId);

          if (!image) return null;

          return {
            src: image.url,
            name: image.name,
            actions: item.actions,
          };
        })
        .filter((item) => item !== null)}
      imageFit={imageFit}
      isLoading={isLoading}
    />
  );
};
