import { ReactNode } from 'react';
import { Stack } from '@mui/material';
import { FiltersInModal } from 'components/filters';
import { MarketplaceItemsSort } from 'types/MarketplaceItems';
import { MarketplaceItemsFilters } from './Types';
import ItemsFilters from './ItemsFilters';

interface Props {
  filters: MarketplaceItemsFilters;
  onFiltersChange: (newFilters: MarketplaceItemsFilters) => void;
  sort?: MarketplaceItemsSort;
  onSort: (sort: MarketplaceItemsSort) => void;
  hideBenefitCategories?: boolean;
  renderAdditionalActions: () => ReactNode;
}

const INITIAL_FILTERS: MarketplaceItemsFilters = {
  benefitPlanTypes: [],
  categories: [],
  countries: [],
  cities: [],
  itemIds: [],
  supplierIds: [],
  price: null,
};

const ItemsFiltersPanel = ({
  renderAdditionalActions,
  filters,
  onFiltersChange,
  ...rest
}: Props) => {
  return (
    <Stack flexDirection="row" gap={1}>
      <FiltersInModal
        value={filters}
        initialValues={INITIAL_FILTERS}
        onChange={onFiltersChange}
      >
        {({ value, initialValues, setFilter }) => (
          <ItemsFilters
            filters={value}
            initialValues={initialValues}
            onFilterChange={setFilter}
            {...rest}
          />
        )}
      </FiltersInModal>
      {renderAdditionalActions()}
    </Stack>
  );
};

export default ItemsFiltersPanel;
