import { Svg, Path } from '@react-pdf/renderer';

interface Props {
  color: string;
}

const CalendarPDFIcon = ({ color }: Props) => (
  <Svg width="8" height="8" viewBox="0 0 8 8">
    <Path
      d="M6.33333 1.33203H1.66667C1.29848 1.33203 1 1.63051 1 1.9987V6.66536C1 7.03355 1.29848 7.33203 1.66667 7.33203H6.33333C6.70152 7.33203 7 7.03355 7 6.66536V1.9987C7 1.63051 6.70152 1.33203 6.33333 1.33203Z"
      stroke={color}
      strokeWidth={0.5}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M5.33331 0.667969V2.0013"
      stroke={color}
      strokeWidth={0.5}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M2.66669 0.667969V2.0013"
      stroke={color}
      strokeWidth={0.5}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M1 3.33203H7"
      stroke={color}
      strokeWidth={0.5}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
);

export default CalendarPDFIcon;
