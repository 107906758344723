import { FormField, Typography } from 'melp-design/components';
import { Box, Collapse, FormControlLabel, Stack, Switch } from '@mui/material';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Currency } from 'types/general';
import { ReadonlyFormContext } from '../../../../melp-design/components/Form/ReadonlyContext';
import { useParentCompany } from '../../../../state/ParentCompany';
import { currencyFormatter } from '../../../../utils/Currency';
import { BenefitSettingsFormData } from './types';

const EmployeeContributionField = () => {
  const { t } = useTranslation();

  const readOnly = useContext(ReadonlyFormContext);

  const { parentCompany } = useParentCompany();
  const accountCurrency = parentCompany?.defaultCurrency as Currency;

  const { watch, setValue } =
    useFormContext<Partial<BenefitSettingsFormData>>();
  const investmentAmount = watch('investmentAmount') ?? 0;
  const employeeContributionAmount = watch('employeeContribution') ?? 0;
  const employeeContributionFraction =
    employeeContributionAmount / investmentAmount;
  const employerContributionAmount =
    investmentAmount - employeeContributionAmount;
  const employerContributionFraction = 1 - employeeContributionFraction;

  const getContributionAmountText = (amount: number) => {
    return currencyFormatter.formatFractionalUnitAmount(
      amount,
      accountCurrency,
    );
  };

  const getContributionPercentageText = (fraction: number) => {
    const percentage = new Intl.NumberFormat(undefined, {
      style: 'percent',
    }).format(fraction);
    return `(${percentage})`;
  };

  const [employeeContributionRequired, setEmployeeContributionRequired] =
    useState(!!employeeContributionAmount);

  return (
    <Stack>
      <FormControlLabel
        control={
          <Switch
            checked={employeeContributionRequired}
            onChange={(e) => {
              const { checked } = e.target;
              setEmployeeContributionRequired(checked);
              if (!checked) {
                setValue('employeeContribution', null, { shouldDirty: true });
              }
            }}
            disabled={readOnly}
          />
        }
        label={t('benefits.employeeContribution')}
        sx={{ width: 'fit-content' }}
      />
      <Collapse in={employeeContributionRequired}>
        <Box width="calc(33% - 10px)" mt="2px" mb="5px">
          <FormField
            name="employeeContribution"
            render={{
              type: 'currency',
              props: { disabled: !investmentAmount },
            }}
            label={t('benefits.employeeContribution')}
            rules={{
              validate: (value: number) => {
                if (value > investmentAmount) {
                  return t(
                    'benefits.employeeContributionCannotExceedInvestment',
                  );
                }
              },
            }}
          />
        </Box>
        <Collapse
          in={
            !!employeeContributionAmount &&
            !!investmentAmount &&
            employeeContributionAmount <= investmentAmount
          }
        >
          <Box
            display="inline-grid"
            gridTemplateColumns="repeat(3, auto)"
            gap="5px"
            py="5px"
            pl="5px"
            sx={{
              '& > *:nth-of-type(3n - 2)::after': {
                content: '":"',
              },
              '& > *:nth-of-type(3n - 1)': {
                justifySelf: 'end',
              },
              '& > *:nth-of-type(3n)': {
                justifySelf: 'end',
              },
            }}
          >
            <Typography variant="p2" color="textSecondary">
              {t('benefits.employerInvests')}
            </Typography>
            <Typography variant="p2" color="textSecondary">
              {getContributionAmountText(employerContributionAmount)}
            </Typography>
            <Typography variant="p2" color="textSecondary">
              {getContributionPercentageText(employerContributionFraction)}
            </Typography>
            <Typography variant="p2" color="textSecondary">
              {t('benefits.employeeInvests')}
            </Typography>
            <Typography variant="p2" color="textSecondary">
              {getContributionAmountText(employeeContributionAmount)}
            </Typography>
            <Typography variant="p2" color="textSecondary">
              {getContributionPercentageText(employeeContributionFraction)}
            </Typography>
          </Box>
        </Collapse>
      </Collapse>
    </Stack>
  );
};

export default EmployeeContributionField;
