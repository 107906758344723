import { useState, useContext } from 'react';
import { Divider, Tooltip, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Alert,
  ButtonOld,
  TableToolbar,
  TableWrapper,
  Tabs,
} from 'melp-design/components';
import {
  useLazyBenefitMarketplaceItems,
  useLazyBenefitSelectedMarketplaceItems,
  useBenefitBulkLink,
  useBenefitBulkUnlink,
} from '../../../../state/MarketplaceItems.clientAdmin';
import { isDefined } from '../../../../utils/isDefined';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../../utils/Dialog';
import { useLoading } from '../../../../utils/Loading';
import { useMe } from '../../../../state/Administrators';
import { BenefitPlanContext } from '../../common/BenefitPlanContext';
import MarketplaceSettings from './MarketplaceSettings';
import { MarketplaceItemsFilters } from './Types';
import ItemsFiltersPanel from './ItemsFiltersPanel';
import ItemCard from './ItemCard';
import { useLazyItems } from './State';
import ItemsList from './ItemsList';

const useStyles = makeStyles((theme) => ({
  tabs: {
    color: theme.palette.text.secondary,
    flexGrow: 1,
    paddingTop: theme.spacing(3),
  },
  tab: {
    fontSize: 14,
    minWidth: 'auto',
    padding: '0px 0px 25px',
    marginRight: 20,
    fontWeight: 400,
    textTransform: 'none',
    transition: 'filter .1s ease-in-out',
    '&:last-child': {
      marginRight: 0,
    },
  },
  activeTab: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  activeTabIndicator: {
    backgroundColor: theme.palette.primary.main,
    height: 1,
  },
  tabsDivider: {
    marginTop: -1,
  },
  filterPanelButton: {
    height: 30,
    fontWeight: 400,
    color: theme.palette.text.primary,
    borderRadius: 6,
  },
  filterPanelButtonTooltip: {
    textAlign: 'center',
  },
}));

interface FilterPanelButtonProps {
  buttonLabelKey: string;
  count?: number;
  confirmationTextKey: string;
  onConfirm: () => Promise<any>;
  disabled?: boolean;
  hint?: string;
}

const FilterPanelButton = ({ count, ...rest }: FilterPanelButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const bulkActionConfirmationDialog = useDialog();
  const selectionState = useLoading();
  if (!count) {
    return null;
  }
  return (
    <>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Tooltip
        title={rest.hint ?? ''}
        classes={{ tooltip: classes.filterPanelButtonTooltip }}
      >
        <span>
          <ButtonOld
            variant="outlined"
            size="small"
            className={classes.filterPanelButton}
            onClick={bulkActionConfirmationDialog.openDialog}
            disabled={rest.disabled}
          >
            {t(rest.buttonLabelKey, { count })}
          </ButtonOld>
        </span>
      </Tooltip>
      <ConfirmationDialog
        id="bulkActionConfirmationDialog"
        content={t(rest.confirmationTextKey, { count })}
        open={bulkActionConfirmationDialog.open}
        onCancel={bulkActionConfirmationDialog.closeDialog}
        onConfirm={async (close) => {
          selectionState.startLoading();
          try {
            await rest.onConfirm();
            close();
          } finally {
            selectionState.stopLoading();
          }
        }}
        loading={selectionState.loading}
      />
    </>
  );
};

enum MarketplaceTabs {
  selected = 'selected',
  recommended = 'recommended',
  all = 'all',
}

const MarketplaceWithinBenefit = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(MarketplaceTabs.all);
  const { canEditBenefits } = useMe();

  const { id, type } = useContext(BenefitPlanContext);

  const { fetchItems } = useLazyBenefitMarketplaceItems(id);
  const bulkLink = useBenefitBulkLink(id ?? '');
  const allItems = useLazyItems({ fetchItems, bulkLink: bulkLink.execute });
  const predefinedBenefitPlanTypes = type ? [type] : [];
  const recommendedItems = useLazyItems({
    fetchItems: (params) =>
      fetchItems({
        ...params,
        benefitPlanTypes: predefinedBenefitPlanTypes,
      }),
    bulkLink: (params) =>
      bulkLink.execute({
        ...params,
        benefitPlanTypes: predefinedBenefitPlanTypes,
      }),
  });

  const bulkUnlink = useBenefitBulkUnlink(id ?? '');
  const { fetchItems: fetchSelectedItems } =
    useLazyBenefitSelectedMarketplaceItems(id);
  const selectedItems = useLazyItems({
    fetchItems: fetchSelectedItems,
    bulkUnlink: bulkUnlink.execute,
  });

  if (!id) {
    return <Alert severity="error">{t('errors.something_went_wrong')}</Alert>;
  }

  const updateSelectedItemsAfterRemoval = (itemId: string) => {
    const { filteredItemsCount, itemsPageSize } = selectedItems;
    if (
      filteredItemsCount !== undefined &&
      itemsPageSize !== undefined &&
      filteredItemsCount <= itemsPageSize
    ) {
      selectedItems.removeLocalItem(itemId);
    } else {
      selectedItems.goToFirstPage();
      selectedItems.updateCounter();
    }
  };

  const showRemovalSuccessMessage = (count?: number) => {
    if (count) {
      toast.success(t('marketplace.itemsRemovalSuccess', { count }));
    }
  };

  const handleItemRemovalCompletion = (itemId: string) => {
    updateSelectedItemsAfterRemoval(itemId);
    const newItemData = { assignment: undefined };
    allItems.updateLocalItem(itemId, newItemData);
    recommendedItems.updateLocalItem(itemId, newItemData);
    showRemovalSuccessMessage(1);
  };

  const showAdditionSuccessMessage = (count?: number) => {
    if (count) {
      toast.success(t('marketplace.itemsAdditionSuccess', { count }));
    }
  };

  const handleItemSelectionCompletion = (itemId: string) => {
    selectedItems.goToFirstPage();
    selectedItems.updateCounter();
    const newItemData = {
      assignment: { assignmentDate: new Date().toISOString() },
    };
    allItems.updateLocalItem(itemId, newItemData);
    recommendedItems.updateLocalItem(itemId, newItemData);
    showAdditionSuccessMessage(1);
  };

  const handleBulkActionCompletion = () => {
    selectedItems.goToFirstPage();
    selectedItems.updateCounter();
    allItems.goToFirstPage();
    recommendedItems.goToFirstPage();
  };

  const resolveNoDataMessage = (
    filters: MarketplaceItemsFilters,
    defaultMessage: string,
  ) => {
    const activeFilterExists = !!Object.keys(filters).some((key) =>
      isDefined(filters[key as keyof MarketplaceItemsFilters]),
    );
    if (activeFilterExists) {
      return t('marketplace.noDataForFilters');
    }
    return defaultMessage;
  };

  const commonSelectionButtonProps = {
    buttonLabelKey: 'marketplace.selectFilteredItems',
    confirmationTextKey: 'marketplace.selectFilteredItemsConfirmation',
  };

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <Tabs
              value={currentTab}
              items={[
                {
                  value: MarketplaceTabs.selected,
                  label: `${t(`marketplace.${MarketplaceTabs.selected}`)}${
                    selectedItems.itemsCount !== undefined
                      ? ` (${selectedItems.itemsCount})`
                      : ''
                  }`,
                },
                {
                  value: MarketplaceTabs.recommended,
                  label: `${t(`marketplace.${MarketplaceTabs.recommended}`)}${
                    recommendedItems.itemsCount !== undefined
                      ? ` (${recommendedItems.itemsCount})`
                      : ''
                  }`,
                },
                {
                  value: MarketplaceTabs.all,
                  label: `${t(`marketplace.${MarketplaceTabs.all}`)}${
                    allItems.itemsCount !== undefined
                      ? ` (${allItems.itemsCount})`
                      : ''
                  }`,
                },
              ]}
              onChange={(tab) => setCurrentTab(tab)}
            />
            <MarketplaceSettings
              benefitId={id}
              onSubmitCompleted={handleBulkActionCompletion}
              onFilterCreated={() => setCurrentTab(MarketplaceTabs.selected)}
              disabled={!canEditBenefits}
            />
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            {currentTab === MarketplaceTabs.selected && (
              <ItemsFiltersPanel
                filters={selectedItems.filters}
                onFiltersChange={selectedItems.setFilters}
                sort={selectedItems.sort}
                onSort={selectedItems.setSort}
                renderAdditionalActions={() => (
                  <FilterPanelButton
                    buttonLabelKey="marketplace.removeFilteredItems"
                    count={selectedItems.filteredItemsCount}
                    confirmationTextKey="marketplace.removeFilteredItemsConfirmation"
                    onConfirm={async () => {
                      const response = await selectedItems.removeFiltered();
                      handleBulkActionCompletion();
                      // Response is true if auto-assigned items exist
                      if (response) {
                        toast.warning(
                          t('marketplace.thereAreAutoAssignedItems'),
                          {
                            autoClose: 10000,
                          },
                        );
                      } else {
                        showRemovalSuccessMessage(
                          selectedItems.filteredItemsCount,
                        );
                      }
                    }}
                  />
                )}
              />
            )}
            {currentTab === MarketplaceTabs.recommended && (
              <ItemsFiltersPanel
                filters={recommendedItems.filters}
                onFiltersChange={recommendedItems.setFilters}
                sort={recommendedItems.sort}
                onSort={recommendedItems.setSort}
                hideBenefitCategories
                renderAdditionalActions={() => (
                  <FilterPanelButton
                    {...commonSelectionButtonProps}
                    count={recommendedItems.filteredItemsCount}
                    onConfirm={async () => {
                      await recommendedItems.selectFiltered();
                      handleBulkActionCompletion();
                      showAdditionSuccessMessage(
                        recommendedItems.filteredItemsCount,
                      );
                    }}
                  />
                )}
              />
            )}
            {currentTab === MarketplaceTabs.all && (
              <ItemsFiltersPanel
                filters={allItems.filters}
                onFiltersChange={allItems.setFilters}
                sort={allItems.sort}
                onSort={allItems.setSort}
                renderAdditionalActions={() => (
                  <FilterPanelButton
                    {...commonSelectionButtonProps}
                    count={allItems.filteredItemsCount}
                    onConfirm={async () => {
                      await allItems.selectFiltered();
                      handleBulkActionCompletion();
                      showAdditionSuccessMessage(allItems.filteredItemsCount);
                    }}
                  />
                )}
              />
            )}
          </Stack>
        </Stack>
      </TableToolbar>

      <TableWrapper.InfiniteListWrapper>
        {currentTab === MarketplaceTabs.selected && (
          <ItemsList
            data={selectedItems.items}
            onNext={selectedItems.fetchNextItems}
            count={selectedItems.filteredItemsCount}
            initialLoading={selectedItems.initialItemsLoading}
            noDataMessage={resolveNoDataMessage(
              selectedItems.filters,
              t('marketplace.noSelectedItems'),
            )}
            renderItem={(item) => (
              <ItemCard
                key={item.id}
                item={item}
                benefitId={id}
                isSelected
                onRemovalCompleted={handleItemRemovalCompletion}
                removeDisabled={item.autoAssigned}
                disabled={!canEditBenefits}
              />
            )}
          />
        )}
        {currentTab === MarketplaceTabs.recommended && (
          <ItemsList
            data={recommendedItems.items}
            onNext={recommendedItems.fetchNextItems}
            count={recommendedItems.filteredItemsCount}
            initialLoading={recommendedItems.initialItemsLoading}
            noDataMessage={resolveNoDataMessage(
              recommendedItems.filters,
              t('marketplace.noRecommendedItems'),
            )}
            renderItem={(item) => (
              <ItemCard
                key={item.id}
                item={item}
                benefitId={id}
                isSelected={!!item.assignment?.assignmentDate}
                onSelectionCompleted={handleItemSelectionCompletion}
                onRemovalCompleted={handleItemRemovalCompletion}
                removeDisabled={item.assignment?.autoAssigned}
                disabled={!canEditBenefits}
              />
            )}
          />
        )}
        {currentTab === MarketplaceTabs.all && (
          <ItemsList
            data={allItems.items}
            onNext={allItems.fetchNextItems}
            count={allItems.filteredItemsCount}
            initialLoading={allItems.initialItemsLoading}
            noDataMessage={resolveNoDataMessage(
              allItems.filters,
              t('marketplace.noAllItems'),
            )}
            renderItem={(item) => (
              <ItemCard
                key={item.id}
                item={item}
                benefitId={id}
                isSelected={!!item.assignment?.assignmentDate}
                onSelectionCompleted={handleItemSelectionCompletion}
                onRemovalCompleted={handleItemRemovalCompletion}
                removeDisabled={item.assignment?.autoAssigned}
                disabled={!canEditBenefits}
              />
            )}
          />
        )}
      </TableWrapper.InfiniteListWrapper>
    </TableWrapper>
  );
};

export default MarketplaceWithinBenefit;
