import { useMutation, useQueryClient } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './admin-brand-cards.loaders';
import { adminBrandCardsKeys } from './admin-brand-cards.queries';

export const useAdminCreateBrandCard = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createCard, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandCardsKeys.all);
    },
  });
};

export const useAdminUpdateBrandCard = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateCard, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandCardsKeys.all);
    },
  });
};

export const useAdminUpdateBrandCardContent = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateCardContent, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandCardsKeys.all);
    },
  });
};

export const useAdminUpdateBrandCardPrices = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateCardPrices, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandCardsKeys.all);
    },
  });
};

export const useAdminDeleteCard = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteCard, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandCardsKeys.lists);
    },
  });
};

export const useDownloadBulkUpdateTemplate = () => {
  return useMutation(loaders.downloadBulkUpdateTemplate, {
    onSuccess: filesConverters.export,
  });
};

export const useImportAdminBrandCardsBulkUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.importBulkUpdate, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminBrandCardsKeys.lists);
    },
  });
};

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};
